import { ApiFarcasterFrame } from 'farcaster-client-data';
import React, { useCallback, useMemo } from 'react';

import { CastActionIcon } from '~/components/icons/CastActionIcon';
import { DefaultModalContainer } from '~/components/modals/DefaultModalContainer';
import { Modal } from '~/components/modals/Modal';
import { FarcasterFrameAttachment } from '~/lazy/components';

export type FrameParams = {
  frameUrl: string;
  frame: ApiFarcasterFrame;
  castHash: string;
  castFid: number;
  title?: string;
  icon?: string;
};

type LaunchFrameContextValue = {
  launchFrame: (params: FrameParams) => void;
  dismiss: () => void;
};

const LaunchFrameContext = React.createContext<LaunchFrameContextValue>({
  launchFrame: () => {
    throw new Error('No LaunchFrameProvider');
  },
  dismiss: () => {
    throw new Error('No LaunchFrameProvider');
  },
});

type LaunchFrameProviderProps = {
  children: React.ReactNode;
};

const LaunchFrameProvider: React.FC<LaunchFrameProviderProps> = React.memo(
  ({ children }) => {
    const [params, setParams] = React.useState<FrameParams | null>(null);

    const launchFrame = useCallback((params: FrameParams) => {
      setParams(params);
    }, []);

    const dismiss = useCallback(() => {
      setParams(null);
    }, []);

    const contextValue = useMemo(
      () => ({
        launchFrame,
        dismiss,
      }),
      [launchFrame, dismiss],
    );

    return (
      <LaunchFrameContext.Provider value={contextValue}>
        {children}
        {params && <FrameModal {...params} />}
      </LaunchFrameContext.Provider>
    );
  },
);

function FrameModal({
  icon,
  title,
  frame,
  frameUrl,
  castFid,
  castHash,
}: FrameParams) {
  const { dismiss } = useLaunchFrame();

  const handleClose = () => {
    dismiss();
  };

  return (
    <Modal>
      <DefaultModalContainer onClose={handleClose}>
        <div className="flex h-full w-full flex-col items-center justify-center p-4">
          <div
            className="flex h-auto w-[500px] flex-col items-start justify-center rounded-lg border p-4 pb-0 bg-app border-default"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="mb-2 flex w-full flex-row items-center p-[1.5px] text-xl font-medium text-default">
              {icon && <CastActionIcon iconName={icon} size={18} />}
              {title && <div className="ml-2">{title}</div>}
            </div>
            <div className="flex w-full flex-col pb-6">
              {/* 
                On suspending the frame attachment component:
                We are lazy-loading the frame attachments as its really heavy on the page payloads.
                Wrapping with a suspense so we don't fall to a full screen flash.
              */}
              <React.Suspense>
                <FarcasterFrameAttachment
                  firstFrame={frame}
                  firstFrameUrl={frameUrl}
                  castFid={castFid}
                  castHash={castHash}
                />
              </React.Suspense>
            </div>
          </div>
        </div>
      </DefaultModalContainer>
    </Modal>
  );
}

LaunchFrameProvider.displayName = 'LaunchFrameProvider';

const useLaunchFrame = () => React.useContext(LaunchFrameContext);

export { LaunchFrameProvider, useLaunchFrame };
