import {
  useFarcasterApiClient,
  useMarkSyncChannelMessageRead,
} from 'farcaster-client-hooks';
import {
  completePasskeyRegistration,
  confirmKeyAgreement,
  createSyncChannel,
  getKeyTransport,
  initiatePasskeyRegistration,
} from 'farcaster-cryptography';
import {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { DebugLogger } from '~/components/debug/DebugLogger';
import { LoginError } from '~/components/login/LoginError';
import { LoginQRCodeWithInstructions } from '~/components/login/LoginQRCodeWithInstructions';
import { useAnalytics } from '~/contexts/AnalyticsProvider';
import { useAuth } from '~/contexts/AuthProvider';
import { usePollForLatestSyncChannelMessage } from '~/hooks/data/syncChannel/usePollForLatestSyncChannelMessage';
import { useSignAuthTokenOperation } from '~/hooks/signing/useSignAuthTokenOperation';
import { useCancelOnUnmountRef } from '~/hooks/useCancelOnUnmountedRef';
import { WebAmpEvent } from '~/types';
import { dataStore, keyStore } from '~/utils/cryptographyUtils';
import { trackError } from '~/utils/errorUtils';
import { logError } from '~/utils/logUtils';
import { createUUID } from '~/utils/uuidUtils';

const isSyncChannelSender = false;

type LoginWithMobilePasskeysProps = {
  onClose: () => void;
};

const LoginWithMobilePasskeys: FC<LoginWithMobilePasskeysProps> = memo(
  ({ onClose }) => {
    const [key, setKey] = useState(Date.now().toString());

    const restart = useCallback(() => {
      setKey(Date.now().toString());
    }, []);

    return (
      <LoginWithMobilePasskeysContent
        key={key}
        onClose={onClose}
        restart={restart}
      />
    );
  },
);

LoginWithMobilePasskeys.displayName = 'LoginWithMobilePasskeys';

type LoginWithMobilePasskeysContentProps = {
  onClose: () => void;
  restart: () => void;
};

const LoginWithMobilePasskeysContent: FC<LoginWithMobilePasskeysContentProps> =
  memo(({ onClose, restart }) => {
    const { trackEvent } = useAnalytics();
    const { apiClient } = useFarcasterApiClient();

    const hasStartedCreatingSyncChannelRef = useRef(false);

    const channelId = useRef(createUUID()).current;

    const pollForLatestSyncChannelMessage =
      usePollForLatestSyncChannelMessage();
    const markSyncChannelMessageRead = useMarkSyncChannelMessageRead();
    const { signIn } = useAuth();
    const signAuthTokenOperation = useSignAuthTokenOperation();

    const cancelControllerRef = useCancelOnUnmountRef();

    const [error, setError] = useState<string>();
    const [attemptingLogin, setAttemptingLogin] = useState<boolean>(false);

    const createChannelAndPollForAuthToken = useCallback(async () => {
      try {
        if (hasStartedCreatingSyncChannelRef.current) {
          return;
        }

        hasStartedCreatingSyncChannelRef.current = true;
        setError(undefined);

        const transport = await getKeyTransport({ keyStore, dataStore });
        await transport.resetKeyTransport();

        const agreement = await createSyncChannel({
          cancelController: cancelControllerRef.current,
          farcasterApiClient: apiClient,
          keyStore,
          dataStore,
          sender: isSyncChannelSender,
          syncChannelIdentifier: channelId,
        });

        if (!agreement) {
          setError('We were unable to establish a sync channel');
          return;
        }

        await confirmKeyAgreement({
          agreement: agreement,
          farcasterApiClient: apiClient,
          keyStore,
          dataStore,
          cancelController: cancelControllerRef.current,
          syncChannelIdentifier: channelId,
          sender: isSyncChannelSender,
        });

        // Wait for the mnemonic message
        const message = await pollForLatestSyncChannelMessage({
          cancelControllerRef,
          channelId,
        });

        setAttemptingLogin(true);

        if (!message) {
          setError('We were unable to sync your devices');
          return;
        }

        await markSyncChannelMessageRead(
          await transport.generateSetMessageReadParams(
            channelId,
            message.messageHash,
          ),
        );

        const mnemonic = Buffer.from(
          (await transport.handleSyncMessage({
            ...message,
            channelId,
          })) as string,
          'base64',
        ).toString('utf-8');

        const result = await signAuthTokenOperation({ mnemonic, apiClient });
        if (result.token !== undefined) {
          const credentialId = await initiatePasskeyRegistration({
            keyStore: keyStore,
            address: result.address,
            username: result.state.user!.username!,
            displayName: result.state.user!.username!,
            fid: result.state.user!.fid,
            pfpUrl: result.state.user?.pfp?.url || '',
          });
          // We have to wait because the browser doesn't immediately return focus on completion of the registration.
          await new Promise((resolve) => {
            setTimeout(() => resolve(undefined), 500);
          });
          await completePasskeyRegistration({
            keyStore,
            credentialId,
            mnemonic,
          });

          await signIn({ authToken: result.token! });
        } else {
          throw new Error('Failed to sync wallet');
        }

        trackEvent(WebAmpEvent.LoggedInToWebUsingCompanion, {});
        // Give half a second for the loading state animation before full reload
        setTimeout(() => window.location.reload(), 250);
      } catch (error) {
        logError(error);
        trackError(error);
        setError('We were unable to establish a sync channel');
      }
    }, [
      apiClient,
      cancelControllerRef,
      channelId,
      markSyncChannelMessageRead,
      pollForLatestSyncChannelMessage,
      signAuthTokenOperation,
      signIn,
      trackEvent,
    ]);

    useEffect(() => {
      trackEvent(WebAmpEvent.ViewLoginWithMobilePasskeysScreen, undefined);
    }, [trackEvent]);

    const body = useMemo(() => {
      if (error) {
        return <LoginError message={error} restart={restart} />;
      }

      return (
        <LoginQRCodeWithInstructions
          channelId={channelId}
          onClose={onClose}
          attemptingLogin={attemptingLogin}
          type="mobile"
        />
      );
    }, [attemptingLogin, channelId, error, onClose, restart]);

    useEffect(() => {
      createChannelAndPollForAuthToken();
    }, [createChannelAndPollForAuthToken]);

    return (
      <div className="min-h-[264px] w-full">
        <DebugLogger
          name="Sign In With Mobile"
          data={{
            channelId,
          }}
        />
        {body}
      </div>
    );
  });

LoginWithMobilePasskeysContent.displayName = 'LoginWithMobilePasskeysContent';

export { LoginWithMobilePasskeys };
