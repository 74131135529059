import { datadogRum } from '@datadog/browser-rum';

import { isProd } from '~/constants/env';
import { appVersion } from '~/constants/version';

type ResourceEvent = {
  resource?: {
    url: string;
  };
};

export const initDatadog = () => {
  let sessionSampleRate = 10;

  if (
    window.localStorage !== null &&
    typeof window.localStorage !== 'undefined'
  ) {
    const samplingRate = window.localStorage.getItem('dd-sampling-rate');
    sessionSampleRate = !!samplingRate ? Number(samplingRate) : 10;
  }

  datadogRum.init({
    applicationId: 'e4ff9f94-e247-4d0c-ac2d-4e513fad53e4',
    clientToken: 'pub36c7b4c5052ce1348050dd1bd4310926',
    site: 'datadoghq.com',
    proxy: 'https://warpcast.com/~/dd-proxy',
    service: 'warpcast-web',
    env: isProd ? 'prod' : 'dev',
    version: appVersion,
    sessionSampleRate: sessionSampleRate,
    sessionReplaySampleRate: 0,
    startSessionReplayRecordingManually: true, // disable session replay unless explicitly started
    compressIntakeRequests: true,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [
      (url: string) => {
        const { hostname, pathname } = new URL(url);
        if (hostname === 'client.warpcast.com') {
          // Include all API requests except the below endpoints
          return ['/v2/unseen', '/v2/amp/api'].every(
            (u) => !pathname.startsWith(u),
          );
        }
        return false;
      },
    ],
    excludedActivityUrls: [
      'https://client.warpcast.com/v2/unseen',
      'https://client.warpcast.com/v2/amp/api',
      'https://warpcast.com/~/dd-proxy',
      'https://cloudflare-eth.com',
    ],
    beforeSend(event, context) {
      const { resource } = event as ResourceEvent;
      if (resource?.url === 'string') {
        if (resource.url.includes('client.warpcast.com/v2/amp/api')) {
          return false;
        }
      }

      if ('error' in context) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const error = (context.error || {}) as any;
        if ('stack' in error && error.stack.includes('chrome-extension://')) {
          return false;
        }
      }

      return true;
    },
  });
};
