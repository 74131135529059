import { useQuery } from '@tanstack/react-query';

import { useFarcasterApiClient } from '../../../../providers/FarcasterApiClientProvider';
import { useBatchMergeIntoGloballyCachedChannels } from '../globallyCachedChannel';
import { useBatchMergeIntoGloballyCachedUsers } from '../globallyCachedUser';
import { buildSearchSummaryFetcher } from './buildSearchSummaryFetcher';
import { buildSearchSummaryKey } from './buildSearchSummaryKey';

const gcTime = 1000 * 60 * 1;

const useSearchSummary = ({
  q,
  maxUsers,
  maxChannels,
  addFollowersYouKnowContext = false,
}: {
  q: string;
  maxUsers: number;
  maxChannels: number;
  addFollowersYouKnowContext?: boolean;
}) => {
  const { apiClient } = useFarcasterApiClient();
  const batchMergeIntoGloballyCachedChannels =
    useBatchMergeIntoGloballyCachedChannels();
  const batchMergeIntoGloballyCachedUsers =
    useBatchMergeIntoGloballyCachedUsers();

  return useQuery({
    queryKey: buildSearchSummaryKey({
      q,
      maxChannels,
      maxUsers,
      addFollowersYouKnowContext,
    }),

    queryFn: buildSearchSummaryFetcher({
      q,
      maxChannels,
      maxUsers,
      addFollowersYouKnowContext,
      apiClient,
      batchMergeIntoGloballyCachedChannels,
      batchMergeIntoGloballyCachedUsers,
    }),

    gcTime,
    enabled: !!q,
  });
};

export { useSearchSummary };
