import amplitude from 'amplitude-js';
import {
  FarcasterApiClientMetaOptions,
  OnError,
  OnFetchStart,
} from 'farcaster-client-data';
import { FarcasterApiClientProvider } from 'farcaster-client-hooks';
import React, { FC, memo, ReactNode, useCallback, useMemo } from 'react';

import { baseApiUrl, wsUrl } from '~/constants/api';
import { isDev } from '~/constants/env';
import { useAuth } from '~/contexts/AuthProvider';
import { useRecentFetch } from '~/contexts/RecentFetchProvider';

const apiDebugEnabled = false;

type WebFarcasterApiClientProviderProps = {
  children: ReactNode;
};

const WebFarcasterApiClientProvider: FC<WebFarcasterApiClientProviderProps> =
  memo(({ children }) => {
    const { signOut } = useAuth();

    const { trackFetch } = useRecentFetch();

    const meta = useMemo((): FarcasterApiClientMetaOptions => ({}), []);

    const onFetchStart: OnFetchStart = useCallback(() => {
      trackFetch();
    }, [trackFetch]);

    const onError: OnError = useCallback(
      ({ responseStatus }) => {
        if (responseStatus === 401) {
          signOut();
        }
      },
      [signOut],
    );

    const signMessage = useCallback(async () => '', []);

    return (
      <FarcasterApiClientProvider
        address={undefined}
        baseUrl={baseApiUrl}
        wsUrl={wsUrl}
        debug={isDev && apiDebugEnabled}
        meta={meta}
        onError={onError}
        onFetchStart={onFetchStart}
        fetch={fetch}
        signMessage={signMessage}
        getAmplitudeDeviceId={() => amplitude.getInstance().getDeviceId()}
        getAmplitudeSessionId={() => amplitude.getInstance().getSessionId()}
      >
        {children}
      </FarcasterApiClientProvider>
    );
  });

WebFarcasterApiClientProvider.displayName = 'WebFarcasterApiClientProvider';

export { WebFarcasterApiClientProvider };
