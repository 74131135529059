import { useInfiniteQuery } from '@tanstack/react-query';
import { getNextPageCursor } from 'farcaster-client-data';

import { useFarcasterApiClient } from '../../../../providers/FarcasterApiClientProvider';
import { useBatchMergeIntoGloballyCachedChannels } from '../globallyCachedChannel';
import { buildSearchChannelsFetcher } from './buildSearchChannelsFetcher';
import { buildSearchChannelsKey } from './buildSearchChannelsKey';

const gcTime = 1000 * 60 * 1;

const useSearchChannels = ({
  limit = 20,
  q,
  prioritizeFollowed = false,
  forComposer = false,
}: {
  limit?: number;
  q: string;
  prioritizeFollowed?: boolean;
  forComposer?: boolean;
}) => {
  const { apiClient } = useFarcasterApiClient();
  const batchMergeIntoGloballyCachedChannels =
    useBatchMergeIntoGloballyCachedChannels();

  return useInfiniteQuery({
    initialPageParam: undefined,
    queryKey: buildSearchChannelsKey({ q, limit, prioritizeFollowed }),

    queryFn: buildSearchChannelsFetcher({
      q,
      prioritizeFollowed,
      forComposer,
      limit,
      apiClient,
      batchMergeIntoGloballyCachedChannels,
    }),

    gcTime,
    getNextPageParam: getNextPageCursor,
  });
};

export { useSearchChannels };
