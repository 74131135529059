import { useCallback } from 'react';

import { useFarcasterApiClient } from '../../../providers';
import { generateIdempotencyKey } from '../../../utils/AccountingUtils';
import { useOptimisticallyAddSelfInRole } from '../channelUsers';

export const useCreateChannel = () => {
  const { apiClient } = useFarcasterApiClient();
  const optimisticallyAddSelfInRole = useOptimisticallyAddSelfInRole();

  return useCallback(
    async ({ key, warpsCost }: { key: string; warpsCost: number }) => {
      const idempotencyKey = generateIdempotencyKey();
      return await optimisticallyAddSelfInRole({
        channelKey: key,
        role: 'owner',
        execute: async () => {
          const response = await apiClient.createChannel({
            key,
            warpsCost,
            idempotencyKey,
          });

          return response.data.result;
        },
      });
    },
    [apiClient, optimisticallyAddSelfInRole],
  );
};
