import { useMemo } from 'react';

export function useIsDarkMode() {
  return useMemo(
    () =>
      typeof window !== 'undefined' &&
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches,
    [],
  );
}
