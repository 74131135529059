/* eslint-disable func-params-args/func-args */

import { isError, isFarcasterError } from '../types/errors';

const linebreak = '\n';
const divider =
  linebreak +
  '######################################################################' +
  linebreak;

const stringifyError = (error: unknown) => {
  const output: string[] = [divider];

  if (isFarcasterError(error)) {
    output.push(error.constructor.name);
    output.push(`...message: ${error.message}`);

    Object.keys(error)
      .filter((key) => key !== 'error')
      .forEach((key) => {
        output.push(`...${key}: ${error[key as keyof typeof error]}`);
      });

    const nestedError = error.error;
    if (isFarcasterError(nestedError)) {
      output.push(`...error: ${nestedError.constructor.name}`);
      Object.keys(nestedError).forEach((key) => {
        let value = nestedError[key as keyof typeof nestedError];
        try {
          if (typeof value === 'object') {
            value = JSON.stringify(value);
          }
        } catch {}

        output.push(`......${key}: ${value}`);
      });
    } else {
      if (isError(nestedError)) {
        output.push(`...unwrappedErrorMessage: ${nestedError?.message}`);
        output.push(`...unwrappedErrorStack: ${nestedError?.stack}`);
      }
    }
  } else if (isError(error)) {
    output.push(error.name);
    output.push(`...message: ${error.message}`);
    output.push(`...stack: ${error.stack}`);
  } else {
    output.push(String(error));
  }

  output.push(divider);
  return output.join(linebreak);
};

export { stringifyError };
