import {
  useFarcasterApiClient,
  useMarkSyncChannelMessageRead,
} from 'farcaster-client-hooks';
import {
  confirmKeyAgreement,
  createSyncChannel,
  getKeyTransport,
} from 'farcaster-cryptography';
import {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { DebugLogger } from '~/components/debug/DebugLogger';
import { LoginError } from '~/components/login/LoginError';
import { LoginQRCodeWithInstructions } from '~/components/login/LoginQRCodeWithInstructions';
import { useAnalytics } from '~/contexts/AnalyticsProvider';
import { useAuth } from '~/contexts/AuthProvider';
import { useDecryptAuthToken } from '~/hooks/data/syncChannel/useDecryptAuthToken';
import { usePollForLatestSyncChannelMessage } from '~/hooks/data/syncChannel/usePollForLatestSyncChannelMessage';
import { useCancelOnUnmountRef } from '~/hooks/useCancelOnUnmountedRef';
import { WebAmpEvent } from '~/types';
import { dataStore, keyStore } from '~/utils/cryptographyUtils';
import { trackError } from '~/utils/errorUtils';
import { logError } from '~/utils/logUtils';
import { createUUID } from '~/utils/uuidUtils';

const isSyncChannelSender = false;

type LoginWithMobileProps = {
  onClose: () => void;
};

const LoginWithMobile: FC<LoginWithMobileProps> = memo(({ onClose }) => {
  const [key, setKey] = useState(Date.now().toString());

  const restart = useCallback(() => {
    setKey(Date.now().toString());
  }, []);

  return (
    <LoginWithMobileContent key={key} onClose={onClose} restart={restart} />
  );
});

LoginWithMobile.displayName = 'LoginWithMobile';

type LoginWithMobileContentProps = {
  onClose: () => void;
  restart: () => void;
};

const LoginWithMobileContent: FC<LoginWithMobileContentProps> = memo(
  ({ onClose, restart }) => {
    const { trackEvent } = useAnalytics();
    const { apiClient } = useFarcasterApiClient();

    const hasStartedCreatingSyncChannelRef = useRef(false);

    const channelId = useRef(createUUID()).current;

    const pollForLatestSyncChannelMessage =
      usePollForLatestSyncChannelMessage();
    const markSyncChannelMessageRead = useMarkSyncChannelMessageRead();
    const decryptAuthToken = useDecryptAuthToken();
    const { signIn } = useAuth();

    const cancelControllerRef = useCancelOnUnmountRef();

    const [error, setError] = useState<string>();
    const [attemptingLogin, setAttemptingLogin] = useState<boolean>(false);

    const createChannelAndPollForAuthToken = useCallback(async () => {
      try {
        if (hasStartedCreatingSyncChannelRef.current) {
          return;
        }

        hasStartedCreatingSyncChannelRef.current = true;
        setError(undefined);

        const transport = await getKeyTransport({ keyStore, dataStore });
        await transport.resetKeyTransport();

        const agreement = await createSyncChannel({
          cancelController: cancelControllerRef.current,
          farcasterApiClient: apiClient,
          keyStore,
          dataStore,
          sender: isSyncChannelSender,
          syncChannelIdentifier: channelId,
        });

        if (!agreement) {
          setError('We were unable to establish a sync channel');
          return;
        }

        await confirmKeyAgreement({
          agreement: agreement,
          farcasterApiClient: apiClient,
          keyStore,
          dataStore,
          cancelController: cancelControllerRef.current,
          syncChannelIdentifier: channelId,
          sender: isSyncChannelSender,
        });

        // Wait for the mnemonic message
        const message = await pollForLatestSyncChannelMessage({
          cancelControllerRef,
          channelId,
        });

        setAttemptingLogin(true);

        if (!message) {
          setError('We were unable to sync your devices');
          return;
        }

        await markSyncChannelMessageRead(
          await transport.generateSetMessageReadParams(
            channelId,
            message.messageHash,
          ),
        );

        // Decrypt the auth token message
        const authToken = await decryptAuthToken({
          channelId,
          message,
          transport: transport!,
        });

        await signIn({ authToken });

        trackEvent(WebAmpEvent.LoggedInToWebUsingCompanion, {});
        // Give half a second for the loading state animation before full reload
        setTimeout(() => window.location.reload(), 500);
      } catch (error) {
        logError(error);
        trackError(error);
        setError('We were unable to establish a sync channel');
      }
    }, [
      apiClient,
      cancelControllerRef,
      channelId,
      decryptAuthToken,
      markSyncChannelMessageRead,
      pollForLatestSyncChannelMessage,
      signIn,
      trackEvent,
    ]);

    useEffect(() => {
      trackEvent(WebAmpEvent.ViewLoginWithMobileScreen, undefined);
    }, [trackEvent]);

    const body = useMemo(() => {
      if (error) {
        return <LoginError message={error} restart={restart} />;
      }

      return (
        <LoginQRCodeWithInstructions
          channelId={channelId}
          onClose={onClose}
          attemptingLogin={attemptingLogin}
          type="web"
        />
      );
    }, [attemptingLogin, channelId, error, onClose, restart]);

    useEffect(() => {
      createChannelAndPollForAuthToken();
    }, [createChannelAndPollForAuthToken]);

    return (
      <div className="min-h-[264px] w-full">
        <DebugLogger
          name="Sign In With Mobile"
          data={{
            channelId,
          }}
        />
        {body}
      </div>
    );
  },
);

LoginWithMobileContent.displayName = 'LoginWithMobileContent';

export { LoginWithMobile };
