import { SharedAmpEvent } from 'farcaster-client-hooks';
import has from 'lodash/has';
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import { isDev, isProd } from '~/constants/env';
import { useIsSignedIn } from '~/hooks/data/useIsSignedIn';
import { AmpEventData, AmpEvents, FathomEvent } from '~/types';
import { Amplitude } from '~/utils/analyticsUtils';

import { useStandaloneMode } from './StandaloneModeProvider';

type AnalyticsContextValue = {
  trackEvent: (event: AmpEvents | SharedAmpEvent, data: AmpEventData) => void;
  trackFathomEvent: (event: FathomEvent) => void;
  dangerouslyTrackPreAuthEvent: (
    event: AmpEvents | SharedAmpEvent,
    data: AmpEventData,
  ) => void;
};

const AnalyticsContext = createContext<AnalyticsContextValue>({
  trackEvent: () => {
    // eslint-disable-next-line no-console
    console.error(
      'AnalyticsContext.trackEvent() called before its initialized properly.',
    );
  },
  trackFathomEvent: () => {
    // eslint-disable-next-line no-console
    console.error(
      'AnalyticsContext.trackEvent() called before its initialized properly.',
    );
  },
  dangerouslyTrackPreAuthEvent: () => {
    // eslint-disable-next-line no-console
    console.error(
      'AnalyticsContext.dangerouslyTrackPreAuthEvent() called before its initialized properly.',
    );
  },
});

interface AnalyticsProviderProps {
  children: ReactNode;
}

const AnalyticsProvider: FC<AnalyticsProviderProps> = ({ children }) => {
  const { inStandaloneMode } = useStandaloneMode();

  // Load Fathom only for signed out users. Once loaded it's impossible to unload it,
  // so we rely on the fact that we do a full winow reload on sign in and sign out.
  // Inspired by the fathom-client package
  const isSignedIn = useIsSignedIn();
  const loadingFathom = useRef(false);
  useEffect(() => {
    if (!isSignedIn) {
      if (has(window, 'fathom') || loadingFathom.current) {
        return;
      }
      loadingFathom.current = true;

      let tracker = document.createElement('script');
      let firstScript =
        document.getElementsByTagName('script')[0] ||
        document.querySelector('body');

      tracker.id = 'pencil-novel';
      tracker.async = true;
      tracker.src = 'https://pencil-novel.warpcast.com/script.js';
      tracker.setAttribute('data-site', 'KHDGTFLR');
      tracker.setAttribute('data-excluded-domains', 'localhost');
      tracker.setAttribute('data-spa', 'auto');

      firstScript.parentNode?.insertBefore(tracker, firstScript);
    }
  }, [isSignedIn]);

  const warpcastPlatform = useMemo(() => {
    return inStandaloneMode ? 'pwa' : 'web';
  }, [inStandaloneMode]);

  const trackEvent = useCallback(
    (e: AmpEvents | SharedAmpEvent, data: AmpEventData) => {
      if (isDev) {
        // eslint-disable-next-line no-console
        console.log(`[AMP] event: [${e}]: `, data);
      }
      if (isProd) {
        Amplitude.logEvent(e, {
          ...data,
          warpcastPlatform: warpcastPlatform,
        });
      }
    },
    [warpcastPlatform],
  );

  const trackFathomEvent = useCallback((e: FathomEvent) => {
    if (isDev) {
      // eslint-disable-next-line no-console
      console.log(`[Fathom] event: [${e}]: `);
    }
    if (isProd) {
      setTimeout(() => {
        if (has(window, 'fathom')) {
          fathom.trackEvent(e);
        }
      }, 1);
    }
  }, []);

  const dangerouslyTrackPreAuthEvent = useCallback(
    (e: AmpEvents | SharedAmpEvent, data: AmpEventData) => {
      if (isDev) {
        // eslint-disable-next-line no-console
        console.log(`[AMP] event: [${e}]: `, data);
      }
      if (isProd) {
        Amplitude.dangerouslyLogPossiblyPreAuthEvent(e, {
          ...data,
          warpcastPlatform: warpcastPlatform,
        });
      }
    },
    [warpcastPlatform],
  );

  return (
    <AnalyticsContext.Provider
      value={{
        trackEvent,
        trackFathomEvent,
        dangerouslyTrackPreAuthEvent,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};

AnalyticsProvider.displayName = 'AnalyticsProvider';

const useAnalytics = (): AnalyticsContextValue => useContext(AnalyticsContext);

export { AnalyticsProvider, useAnalytics };
