import { useQueryClient } from '@tanstack/react-query';
import { ApiCast } from 'farcaster-client-data';
import { useCallback } from 'react';

import { stringifyGlobalCacheUsageKey } from '../../../../providers/GlobalCacheUsageProvider';
import {
  BatchMergeIntoGloballyCachedCasts,
  CastUpdates,
  GloballyCachedCastCache,
} from '../../../../types';
import { shouldUpdateCache } from '../../../../utils/CacheUtils';
import { buildGloballyCachedCastKey } from './buildGloballyCachedCastKey';

const useBatchMergeIntoGloballyCachedCasts =
  (): BatchMergeIntoGloballyCachedCasts => {
    const queryClient = useQueryClient();

    return useCallback(
      ({ batchUpdates }) => {
        // First, transform the array of updates to a map, keyed on query key.
        const updatesMap: Record<string, CastUpdates> = {};
        batchUpdates.forEach((updates) => {
          const queryKey = buildGloballyCachedCastKey({
            hash: updates.hash,
            recast: !!updates.recast,
          });

          const key = stringifyGlobalCacheUsageKey(queryKey);
          updatesMap[key] = { ...updatesMap[key], ...updates };
        });

        // Then, query the cache for all globally cached casts.
        // For any entry we encounter that has an associated update in our updates map,
        // commit the updates to the cache. We do this because we assume that
        // the updates are more recent that the cached data, so we need to override it.
        // For any updates we commit, we remove the associated key from our updates map,
        // so we don't need to bother with it in the next step.
        queryClient
          .getQueriesData<GloballyCachedCastCache>({
            queryKey: buildGloballyCachedCastKey({
              hash: undefined,
              recast: undefined,
            }),
            exact: false,
          })
          .forEach(([queryKey, cachedCast]) => {
            const stringifiedKey = stringifyGlobalCacheUsageKey(queryKey);
            const updates = updatesMap[stringifiedKey];
            if (!cachedCast || !updates) {
              return;
            }

            delete updatesMap[stringifiedKey];

            if (shouldUpdateCache({ cache: cachedCast, updates })) {
              queryClient.setQueryData<GloballyCachedCastCache>(queryKey, {
                ...cachedCast,
                ...updates,
              } as ApiCast);
            }
          });

        // Lastly, we iterate over updates in our map that didn't already have an associated cache entry.
        // For each set of updates, we check the usage count. If the usage count is
        // greater than 1 (i.e. the entity is being used in multiple places), we commit
        // the update to the cache to ensure we have the refreshest data everywhere.
        Object.entries(updatesMap).forEach(([_key, updates]) => {
          queryClient.setQueryData<GloballyCachedCastCache>(
            buildGloballyCachedCastKey({
              hash: updates.hash,
              recast: !!updates.recast,
            }),
            (prevValue) =>
              // We use this only for complete objects coming from the backend (vs
              // for optimistic updates), therefore we do a simple merge
              ({
                ...prevValue,
                ...updates,
              }) as GloballyCachedCastCache,
          );
        });
      },
      [queryClient],
    );
  };

export { useBatchMergeIntoGloballyCachedCasts };
