import {
  AnalyticsEventWithoutTimestamp,
  EventingProvider,
  eventToPayload,
  EventV2Props,
  SharedAmpEvent,
  StatsigEvent,
  useExperimentation,
  useInternalEventing,
} from 'farcaster-client-hooks';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { useAnalytics } from '~/contexts/AnalyticsProvider';
import { useUserAppContext } from '~/contexts/UserAppContextProvider';

interface EventHandlerProps {
  children: React.ReactNode;
}

const WebEventingProvider: React.FC<EventHandlerProps> = ({ children }) => {
  const { trackEvent } = useAnalytics();
  const { trackExperimentationEvent } = useExperimentation();
  const { _trackInternalEvent, _trackUrgentInternalEvent } =
    useInternalEventing();
  const location = useLocation();
  const { statsigEnabled } = useUserAppContext();

  const handleStatsigEvent = React.useCallback(
    (event: StatsigEvent) => {
      if (!statsigEnabled) {
        return;
      }

      if (event.action) {
        // Create payload, adding current path
        const payload = eventToPayload(event);
        payload['path'] = location.pathname;

        trackExperimentationEvent(event.action, payload);
      }
    },
    [location.pathname, statsigEnabled, trackExperimentationEvent],
  );

  const handleEvent = React.useCallback(
    (event: SharedAmpEvent, props?: EventV2Props) => {
      // Add current path
      props ||= {};
      props['path'] = location.pathname;

      trackEvent(event, props);
    },
    [location.pathname, trackEvent],
  );

  const handleInternalEvents = React.useCallback(
    (urgent: boolean, ...events: AnalyticsEventWithoutTimestamp[]) => {
      if (urgent) {
        _trackUrgentInternalEvent(...events);
      } else {
        _trackInternalEvent(...events);
      }
    },
    [_trackInternalEvent, _trackUrgentInternalEvent],
  );

  return (
    <EventingProvider
      handleStatsigEvent={handleStatsigEvent}
      handleEvent={handleEvent}
      handleInternalEvents={handleInternalEvents}
    >
      {children}
    </EventingProvider>
  );
};

export { WebEventingProvider };
