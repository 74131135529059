import {
  ApiGetOnboardingState200Response,
  DEFAULT_TIMEOUT_ONBOARDING_STATE,
  FarcasterApiClient,
} from 'farcaster-client-data';

import { MergeIntoGloballyCachedUser } from '../../../../types';

const buildOnboardingStateFetcher =
  ({
    apiClient,
    Authorization,
    mergeIntoGloballyCachedUser,
    timeout = DEFAULT_TIMEOUT_ONBOARDING_STATE,
  }: {
    apiClient: FarcasterApiClient;
    mergeIntoGloballyCachedUser: MergeIntoGloballyCachedUser;
    Authorization?: string;
    timeout?: number;
  }) =>
  async (): Promise<ApiGetOnboardingState200Response> => {
    const response = await apiClient.getOnboardingState({
      headers: { Authorization: Authorization || '' },
      timeout,
    });
    const user = response.data.result.state.user;
    if (user) {
      mergeIntoGloballyCachedUser({ updates: user });
    }
    return response.data;
  };

export { buildOnboardingStateFetcher };
