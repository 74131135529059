import { FC, memo, useMemo } from 'react';

import { RecommendedUsers } from '~/components/rightSidebar/RecommendedUsers';
import { RightSidebarChannel } from '~/components/rightSidebar/RightSidebarChannel';
import { UpdatesCarousel } from '~/components/rightSidebar/UpdatesCarousel';
import { Search } from '~/components/search/Search';
import { useIsAdmin } from '~/hooks/data/useIsAdmin';
import { useCurrentRoute } from '~/hooks/navigation/useCurrentRoute';
import { RightSideBar } from '~/layouts/RightSideBar';
import { SupportAndTermsLinks } from '~/layouts/SupportAndTermsLinks';
// eslint-disable-next-line no-restricted-imports
import { CollectionOwnersPageRightSideBarContent } from '~/pages/collectionOwners/CollectionOwnersPageRightSideBarContent';

const AuthedRightSideBar: FC = memo(() => {
  const isAdmin = useIsAdmin();
  const currentRoute = useCurrentRoute();

  const items = useMemo(() => {
    const routeFamily = currentRoute?.routeDefinition.family;
    const routeName = currentRoute?.routeName;
    const channelKey = currentRoute?.match.params?.channelKey;

    if (
      routeFamily === 'settings' ||
      routeFamily === 'invites' ||
      routeFamily === 'directCasts' ||
      routeFamily === 'warps' ||
      routeName === 'developers' ||
      routeName === 'developersEmbeds' ||
      routeName === 'developersFrameValidator' ||
      routeName === 'developersFrames' ||
      routeName === 'addCastAction' ||
      routeName === 'discoverActions'
    ) {
      return null;
    }

    return (
      <>
        {routeFamily === 'search' ? <></> : <Search showClearIcon={false} />}

        {routeName === 'collectionOwners' && (
          <CollectionOwnersPageRightSideBarContent />
        )}
        {(routeName === 'channel' ||
          routeName === 'channelSettings' ||
          routeName === 'channelSettingsSection' ||
          routeName === 'channelFeed' ||
          routeName === 'channelFollowers') &&
        typeof channelKey !== 'undefined' ? (
          <>
            {/* <AnnouncementBanner /> */}
            <RightSidebarChannel channelKey={channelKey} />
          </>
        ) : (
          <>
            {/* <AnnouncementBanner /> */}
            {!isAdmin && <UpdatesCarousel />}
            <RecommendedUsers />
          </>
        )}
        <SupportAndTermsLinks />
      </>
    );
  }, [
    currentRoute?.match.params?.channelKey,
    currentRoute?.routeDefinition.family,
    currentRoute?.routeName,
    isAdmin,
  ]);

  // For these developer pages we don't even want to render the wrapper
  // and we are okay with content jumps when navigating to these tools.
  if (
    currentRoute?.routeName === 'developers' ||
    currentRoute?.routeName === 'developersApiKeys' ||
    currentRoute?.routeName === 'developersEmbeds' ||
    currentRoute?.routeName === 'developersFrameValidator' ||
    currentRoute?.routeName === 'developersFrames' ||
    currentRoute?.routeName === 'developersCastActionPlayground' ||
    currentRoute?.routeName === 'developersComposerActionPlayground'
  ) {
    return null;
  }

  return <RightSideBar className="pt-3">{items}</RightSideBar>;
});

export { AuthedRightSideBar };
