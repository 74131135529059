import { compactQueryKey } from '../../../../utils/QueryUtils';

const buildFeedItemsKey = ({
  feedKey,
  feedType,
  sortMode,
}: {
  feedKey?: string;
  feedType?: string;
  sortMode?: string;
}) => compactQueryKey(['feedItems', feedKey, feedType, sortMode]);

export { buildFeedItemsKey };
