import { FC, memo } from 'react';

const FollowingEachOther: FC = memo(() => {
  return (
    <div className="rounded border px-2 py-0.5 text-xs border-default text-muted">
      Following each other
    </div>
  );
});

FollowingEachOther.displayName = 'FollowEachOther';

export { FollowingEachOther };
