import { useQuery, useSuspenseQuery } from '@tanstack/react-query';

import { useFarcasterApiClient } from '../../../../providers';
import { buildGloballyCachedChannelKey } from '../globallyCachedChannel';
import { useMergeIntoGloballyCachedChannel } from '../globallyCachedChannel/useMergeIntoGloballyCachedChannel';
import { buildChannelFetcher } from './buildChannelFetcher';

const useChannelFromGlobalCache = ({
  key,
  reduceStaleness = false,
}: {
  key: string | undefined;
  reduceStaleness?: boolean;
}) => {
  const { apiClient } = useFarcasterApiClient();
  const mergeIntoGloballyCachedChannel = useMergeIntoGloballyCachedChannel();

  return useSuspenseQuery({
    queryKey: buildGloballyCachedChannelKey({ key }),

    queryFn: buildChannelFetcher({
      apiClient,
      key: key || '',
      mergeIntoGloballyCachedChannel,
    }),

    gcTime: 60 * 60 * 1000,
    staleTime: reduceStaleness ? 10 * 1000 : 60 * 60 * 1000,
  });
};

const useNonSuspenseChannelFromGlobalCache = ({
  key,
  reduceStaleness = false,
}: {
  key: string | undefined;
  reduceStaleness?: boolean;
}) => {
  const { apiClient } = useFarcasterApiClient();
  const mergeIntoGloballyCachedChannel = useMergeIntoGloballyCachedChannel();

  return useQuery({
    queryKey: buildGloballyCachedChannelKey({ key }),

    queryFn: buildChannelFetcher({
      apiClient,
      key: key || '',
      mergeIntoGloballyCachedChannel,
    }),

    gcTime: 60 * 60 * 1000,
    staleTime: reduceStaleness ? 10 * 1000 : 60 * 60 * 1000,
    enabled: key !== undefined,
  });
};

export { useChannelFromGlobalCache, useNonSuspenseChannelFromGlobalCache };
