import canonicalize from 'canonicalize';
import { ethers } from 'ethers';

import { ApiCreateAuthTokenRequestBody, SignMessage } from '../types';

const EIP_191_PREFIX = 'eip191:';
const DEFAULT_CUSTODY_BEARER_TOKEN_EXPIRES_IN = 1000 * 24 * 60 * 60 * 1000;

const buildCustodyBearerPayload = (
  {
    expiresIn = DEFAULT_CUSTODY_BEARER_TOKEN_EXPIRES_IN,
  }: {
    expiresIn: number;
  } = { expiresIn: DEFAULT_CUSTODY_BEARER_TOKEN_EXPIRES_IN },
): ApiCreateAuthTokenRequestBody => {
  const timestamp = Date.now();

  return {
    method: 'generateToken',
    params: {
      timestamp,
      expiresAt: timestamp + expiresIn,
    },
  };
};

/**
 * Generates a custody bearer token for a given wallet.
 * @param wallet - Wallet to authorize.
 * @returns The custody bearer token.
 */
const buildCustodyBearerToken = async ({
  payload,
  signMessage,
}: {
  payload: ApiCreateAuthTokenRequestBody;
  signMessage: SignMessage;
}) => {
  const canonicalizedPayload = canonicalize(payload) || '';
  const signature = await signMessage(canonicalizedPayload);
  const signatureString = Buffer.from(
    ethers.utils.arrayify(signature),
  ).toString('base64');

  return EIP_191_PREFIX + signatureString;
};

export {
  buildCustodyBearerPayload,
  buildCustodyBearerToken,
  DEFAULT_CUSTODY_BEARER_TOKEN_EXPIRES_IN,
};
