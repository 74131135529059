import { useQuery } from '@tanstack/react-query';
import { ApiUser } from 'farcaster-client-data';
import merge from 'lodash/merge';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import {
  stringifyGlobalCacheUsageKey,
  useGlobalCacheUsage,
} from '../../../../providers/GlobalCacheUsageProvider';
import { buildGloballyCachedUserKey } from './buildGloballyCachedUserKey';

const useGloballyCachedUser = ({ fallback }: { fallback: ApiUser }) => {
  const { addUsage, removeUsage } = useGlobalCacheUsage();

  const queryKey = useMemo(
    () =>
      buildGloballyCachedUserKey({
        fid: fallback.fid,
      }),
    [fallback],
  );

  const fallbackCallback = useCallback(() => fallback, [fallback]);

  const cachedValue = useQuery({
    queryKey: queryKey,
    queryFn: fallbackCallback,
  }).data;

  const stringifiedKey = useMemo(
    () => stringifyGlobalCacheUsageKey(queryKey),
    [queryKey],
  );

  useEffect(() => {
    // Keep track of the number of mounted components referencing the globally cached resource.
    // This allows us to be more efficient with our global cache updates,
    // only committing changes (and consequentially triggering rerenders) when multiple components are using the resource.
    addUsage(stringifiedKey);
    return () => removeUsage(stringifiedKey);
  }, [addUsage, stringifiedKey, removeUsage]);

  // We keep a reference to the the merged value (i.e. the fallback object combined with the global cache overrides),
  // so we can return a stable value if the hook re-renders but none of the inputs have changed.

  const mergedValueRef = useRef<ApiUser>();

  useEffect(() => {
    // When either the fallback object or cached value change, we _do_ want to return a new object,
    // so any comonents/hooks depending on the return value know to update.

    // This used to be setting state but is now setting a ref for performance reasons.
    // Dependent components will re-render when the cachedValue changes anyway
    // so we don't need to trigger an additional re-render with state here
    if (
      // We have recycling views calling globally cached content. What this sometimes results in the
      // fast scrolls on flashlists getting ref values on a different fallback call.
      // We should eventually figure out and properly fix this cache lookups.
      // However, we already do this for casts global caches so let's go ahead and duplicate that
      // logic here too.
      typeof cachedValue !== 'undefined' &&
      fallback.fid === cachedValue.fid
    )
      mergedValueRef.current = merge({}, fallback, cachedValue);
  }, [fallback, cachedValue]);

  // While the `useEffect` hook above should take care of updating the stateful merged value
  // any time the fallback or cached value change, we also merge the fallback and cached values
  // into our the current state's stable merged value before returning. Since we are merging into
  // the stable object (i.e. we aren't creating a new object each render), this assignment
  // won't trigger unnecessary re-renders for components/hooks depending on the return value.
  // However, in the event that the fallback of cached value change (which will trigger the `useEffect` above
  // to set `mergedValue` to a new value), by merging the new fallback and cached values into
  // our soon-to-be-stale merged value, we can render the new data slightly sooner (i.e. before the `useEffect` runs)
  // for any components that aren't waiting for the object reference to change.
  return useMemo(
    () =>
      merge(
        typeof mergedValueRef.current === 'undefined' ||
          mergedValueRef.current.fid === fallback.fid
          ? mergedValueRef.current
          : {},
        fallback,
        cachedValue,
      ),
    [cachedValue, fallback],
  );
};

export { useGloballyCachedUser };
