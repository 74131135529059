import { useCallback } from 'react';

import { useTrackEvent } from '../../../providers';
import { useFarcasterApiClient } from '../../../providers/FarcasterApiClientProvider';
import { SharedAmpEvent } from '../../../utils';
import { useOptimisticallyUpdateChannel } from '../optimistic/useOptimisticallyUpdateChannel';
import { useRefetchFeedSummaries } from '../queries';

const useRemoveFavoriteFeed = () => {
  const { apiClient } = useFarcasterApiClient();
  const refetchFeedSummaries = useRefetchFeedSummaries();
  const optimisticallyUpdateFeed = useOptimisticallyUpdateChannel();
  const { trackEvent } = useTrackEvent();

  return useCallback(
    async ({
      feedKey,
      favoritePosition,
    }: {
      feedKey: string;
      favoritePosition: number;
    }) => {
      const revertOptimisticUpdates = optimisticallyUpdateFeed({
        updates: {
          key: feedKey,
          viewerContext: {
            favoritePosition: -1,
          },
        },
        revertUpdates: {
          key: feedKey,
          viewerContext: {
            favoritePosition,
          },
        },
      });

      try {
        trackEvent(SharedAmpEvent.ToggleChannelFavorite, {
          toggle: 'off',
          channelKey: feedKey,
        });

        await apiClient.removeFavoriteFeed({ feedKey });
        setTimeout(() => {
          refetchFeedSummaries();
        }, 1000);
      } catch (error) {
        revertOptimisticUpdates();
        throw error;
      }
    },
    [apiClient, optimisticallyUpdateFeed, refetchFeedSummaries, trackEvent],
  );
};

export { useRemoveFavoriteFeed };
