import { useSuspenseQuery } from '@tanstack/react-query';

import { useFarcasterApiClient } from '../../../../providers';
import { buildCollectionFetcher } from './buildCollectionFetcher';
import { buildCollectionKey } from './buildCollectionKey';

const useCollection = ({ collectionId }: { collectionId: string }) => {
  const { apiClient } = useFarcasterApiClient();

  return useSuspenseQuery({
    queryKey: buildCollectionKey({ collectionId }),
    queryFn: buildCollectionFetcher({ apiClient, collectionId }),
  });
};

export { useCollection };
