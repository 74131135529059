import { PreloadLazyComponentError, sleep } from 'farcaster-client-hooks';

import { Composer, LoginQRCode } from '~/lazy/components';
import {
  ChannelPage,
  ChannelsPage,
  HomeFeedPage,
  NotificationsPage,
  ProfileCastsAndRepliesWithUsernamePage,
  ProfileCastsWithUsernamePage,
  ProfileLikesWithUsernamePage,
  SettingsConnectedAddressesPage,
  SettingsNotificationsPage,
  SettingsProfilePage,
  TrendingPage,
  UsersForYouPage,
} from '~/lazy/pages';
import { trackError } from '~/utils/errorUtils';

const initialDelay = 750;

// Lazy pages/components to preload. These will be loaded serially, so order matters.
// Beginning of the list is highest priority.
const lazyComponents = [
  HomeFeedPage,
  TrendingPage,
  NotificationsPage,
  ChannelsPage,
  ChannelPage,
  UsersForYouPage,
  ProfileCastsWithUsernamePage,
  ProfileCastsAndRepliesWithUsernamePage,
  ProfileLikesWithUsernamePage,
  LoginQRCode,
  Composer,
  SettingsProfilePage,
  SettingsNotificationsPage,
  SettingsConnectedAddressesPage,
];

const initPreloadLazyComponents = async () => {
  await sleep(initialDelay);

  for (const { preload } of lazyComponents) {
    try {
      await preload();
    } catch (error) {
      trackError(new PreloadLazyComponentError({ error }));
    }
  }
};

export { initPreloadLazyComponents };
