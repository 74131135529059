import {
  ApiGetOnboardingStateAndAuthToken200Response,
  ApiGetOnboardingStateAndAuthTokenRequestBody,
  FarcasterApiClient,
} from 'farcaster-client-data';

const buildOnboardingStateAndAuthTokenFetcher =
  ({
    apiClient,
    custodyBearerPayload,
    custodyBearerToken,
  }: {
    apiClient: FarcasterApiClient;
    custodyBearerPayload: ApiGetOnboardingStateAndAuthTokenRequestBody['authRequest'];
    custodyBearerToken: string;
  }) =>
  async (): Promise<ApiGetOnboardingStateAndAuthToken200Response> => {
    const response = await apiClient.getOnboardingStateAndAuthToken(
      { authRequest: custodyBearerPayload },
      {
        headers: { Authorization: `Bearer ${custodyBearerToken}` },
      },
    );

    return response.data;
  };

export { buildOnboardingStateAndAuthTokenFetcher };
