import { ApiUser } from 'farcaster-client-data';
import { useCallback } from 'react';

import { useNavigate } from '~/hooks/navigation/useNavigate';

const useNavigateToProfile = () => {
  const navigate = useNavigate();

  return useCallback(
    ({
      user,
      openInNewTab = false,
    }: {
      user: ApiUser;
      openInNewTab?: boolean;
    }) => {
      if (user.username) {
        return navigate({
          to: 'profileCastsWithUsername',
          params: { username: user.username },
          options: { openInNewTab },
        });
      }

      return navigate({
        to: 'profileCastsWithoutUsername',
        params: { fid: user.fid },
        options: { openInNewTab },
      });
    },
    [navigate],
  );
};

export { useNavigateToProfile };
