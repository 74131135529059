import { FC, memo } from 'react';

import { Link, LinkProps } from '~/components/links/Link';

export type LinkToProfileCastsWithUsernameProps = Omit<
  LinkProps<'profileCastsWithUsername'>,
  'to' | 'searchParams'
> & {
  fid?: number; // For prefetching
};

const LinkToProfileCastsWithUsername: FC<LinkToProfileCastsWithUsernameProps> =
  memo((props) => {
    return <Link to="profileCastsWithUsername" searchParams={{}} {...props} />;
  });

LinkToProfileCastsWithUsername.displayName = 'LinkToProfileCastsWithUsername';

export { LinkToProfileCastsWithUsername };
