import { FarcasterApiClient } from 'farcaster-client-data';

const buildCollectionFetcher =
  ({
    apiClient,
    collectionId,
  }: {
    apiClient: FarcasterApiClient;
    collectionId: string;
  }) =>
  async () => {
    const response = await apiClient.getCollection({ collectionId });
    return response.data;
  };

export { buildCollectionFetcher };
