// These values are based on P99 resource duration for these endpoints on
// most used devices as seen on DataDog.

// Using these values as timeout instead of the global default will help reduce
// the request load on poor connectivity situations.

export const DEFAULT_TIMEOUT_DIRECT_CAST_CONVERSATION = 10_000;
export const DEFAULT_TIMEOUT_DIRECT_CAST_CONVERSATION_MESSAGES = 10_000;
export const DEFAULT_TIMEOUT_DIRECT_CAST_CONVERSATION_RECENT_MESSAGES = 10_000;
export const DEFAULT_TIMEOUT_DIRECT_CAST_INBOX = 5_000;
export const DEFAULT_TIMEOUT_FEED_ITEMS = 5_000;
export const DEFAULT_TIMEOUT_NOTIFICATIONS_FOR_TAB = 10_000;
export const DEFAULT_TIMEOUT_ONBOARDING_STATE = 5_000;
export const DEFAULT_TIMEOUT_UNSEEN = 5_000;
