import {
  ApiCast,
  ApiChannel,
  ApiUser,
  ApiUserMinimal,
} from 'farcaster-client-data';

export const castKeyExtractor = (cast: ApiCast) => cast.hash;

export const channelKeyExtractor = (channel: ApiChannel) => channel.key;

export const userKeyExtractor = (user: ApiUser | ApiUserMinimal) =>
  String(user.fid);
