import { compactQueryKey } from '../../../../utils/QueryUtils';

const buildGloballyCachedCastKey = ({
  hash,
  recast,
}: {
  hash: string | undefined;
  recast: boolean | undefined;
}) => compactQueryKey(['globallyCachedCast', hash, recast]);

export { buildGloballyCachedCastKey };
