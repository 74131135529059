import { NetworkMode } from '@tanstack/react-query';

const feedItemsDefaultQueryOptions = (feedKey: string) => {
  if (feedKey === 'home' || feedKey === 'following') {
    return {
      staleTime: 1000 * 60 * 60, // 1 hour
      gcTime: 1000 * 60 * 60, // 1 hour
      networkMode: 'offlineFirst' as NetworkMode,
    };
  }

  return {};
};

export { feedItemsDefaultQueryOptions };
