import {
  createContext,
  FC,
  memo,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { featureFlagsKey } from '~/constants/storage';
import { FeatureFlagName, FeatureFlags } from '~/types';
import { getItem, setItem } from '~/utils/storageUtils';

type FeatureFlagContextValue = {
  getFeatureFlagValue: <Name extends FeatureFlagName>(
    name: Name,
  ) => FeatureFlags[Name] | undefined;
  setFeatureFlagValue: <Name extends FeatureFlagName>(
    name: Name,
    value: FeatureFlags[Name] | undefined,
  ) => Promise<void>;
  reset: () => Promise<void>;
};

const FeatureFlagContext = createContext<FeatureFlagContextValue>({
  getFeatureFlagValue: () => undefined,
  setFeatureFlagValue: async () => undefined,
  reset: async () => undefined,
});

type FeatureFlagProviderProps = {
  children: ReactNode;
};

const FeatureFlagProvider: FC<FeatureFlagProviderProps> = memo(
  ({ children }) => {
    const [isInitialized, setIsInitialized] = useState(false);

    const [flags, setFlags] = useState({} as FeatureFlags);

    useEffect(() => {
      const init = async () => {
        try {
          const persistedFlags = await getItem({
            key: featureFlagsKey,
            fallback: {} as FeatureFlags,
          });

          setFlags(persistedFlags);
        } catch {
        } finally {
          setIsInitialized(true);
        }
      };

      init();
    }, []);

    const getFeatureFlagValue = useCallback(
      <Name extends FeatureFlagName>(name: Name) => {
        return flags[name];
      },
      [flags],
    );

    const setFeatureFlagValue = useCallback(
      async <Name extends FeatureFlagName>(
        name: Name,
        value: FeatureFlags[Name] | undefined,
      ) => {
        const nextFlags = { ...flags, [name]: value };
        setFlags(nextFlags);
        await setItem({ key: featureFlagsKey, value: nextFlags });
      },
      [flags],
    );

    const reset = useCallback(async () => {
      setFlags({} as FeatureFlags);
      await setItem({ key: featureFlagsKey, value: {} });
    }, []);

    if (!isInitialized) {
      return null;
    }

    return (
      <FeatureFlagContext.Provider
        value={{ getFeatureFlagValue, reset, setFeatureFlagValue }}
      >
        {children}
      </FeatureFlagContext.Provider>
    );
  },
);

FeatureFlagProvider.displayName = 'FeatureFlagProvider';

const useFeatureFlag = () => useContext(FeatureFlagContext);

export { FeatureFlagProvider, useFeatureFlag };
