import { FC, memo } from 'react';

import { Link, LinkProps } from '~/components/links/Link';

type LinkToProfileCastsWithoutUsernameProps = Omit<
  LinkProps<'profileCastsWithoutUsername'>,
  'to' | 'searchParams'
>;

const LinkToProfileCastsWithoutUsername: FC<LinkToProfileCastsWithoutUsernameProps> =
  memo((props) => {
    return (
      <Link to="profileCastsWithoutUsername" searchParams={{}} {...props} />
    );
  });

LinkToProfileCastsWithoutUsername.displayName =
  'LinkToProfileCastsWithoutUsername';

export { LinkToProfileCastsWithoutUsername };
