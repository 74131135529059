import { Wallet } from 'ethers';

const createNewWallet = async (): Promise<Wallet> => {
  const wallet = Wallet.createRandom();
  return wallet;
};

const createWalletFromMnemonic = async ({
  mnemonic,
}: {
  mnemonic: string;
}): Promise<Wallet> => {
  const wallet = Wallet.fromMnemonic(mnemonic);
  return wallet;
};

export { createNewWallet, createWalletFromMnemonic };
