import { ArrowRightIcon } from '@primer/octicons-react';
import cn from 'classnames';
import { formatNumber, useInterval } from 'farcaster-client-hooks';
import { FC, memo, useMemo } from 'react';

import { useNavigate } from '~/hooks/navigation/useNavigate';

interface WarpsBalanceForPaymentProps {
  balanceAmount: number;
  requiredAmount: number;
  // If present, called every 3 seconds
  refreshBalance?: () => void;
}

const WarpsBalanceForPayment: FC<WarpsBalanceForPaymentProps> = memo(
  ({ balanceAmount, requiredAmount, refreshBalance }) => {
    const navigate = useNavigate();

    // Refresh balance every 3 seconds
    useInterval(refreshBalance, 3000);

    const canPay = useMemo(
      () => balanceAmount >= requiredAmount,
      [balanceAmount, requiredAmount],
    );

    if (canPay) {
      return (
        <div
          className="mt-4 flex flex-row items-center justify-center text-sm text-muted hover:cursor-pointer hover:underline"
          onClick={() => {
            navigate({
              options: { openInNewTab: true },
              to: 'warps',
              params: {},
              searchParams: {},
            });
          }}
        >
          <span className="ml-[6px] flex flex-row">
            You have{' '}
            <span className="px-1 font-bold">
              {formatNumber(balanceAmount)}
            </span>{' '}
            warps
          </span>
          <ArrowRightIcon className="ml-1" />
        </div>
      );
    } else {
      return (
        <div className="mt-4 flex flex-row items-center justify-center text-sm text-muted">
          <span className="ml-[6px] flex flex-row">
            You have{' '}
            <span className="px-1 font-bold">
              {formatNumber(balanceAmount)}
            </span>{' '}
            warps.
          </span>
          <div
            className="flex flex-row items-center text-link hover:cursor-pointer hover:underline"
            onClick={() => {
              navigate({
                options: { openInNewTab: true },
                to: 'warps',
                params: {},
                searchParams: {},
              });
            }}
          >
            <span className="ml-1">Buy warps</span>
            <ArrowRightIcon
              className={cn('ml-1', !canPay ? 'text-link' : '')}
            />
          </div>
        </div>
      );
    }
  },
);

WarpsBalanceForPayment.displayName = 'WarpsBalanceForPayment';

export { WarpsBalanceForPayment };
