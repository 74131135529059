import cn from 'classnames';
import { FC, memo, ReactNode } from 'react';

type RightSideBarProps = {
  children: ReactNode;
  className?: string;
};
const RightSideBar: FC<RightSideBarProps> = memo(({ children, className }) => {
  return (
    <aside
      className={cn(
        'sticky top-0 hidden h-full flex-shrink-0 flex-grow flex-col sm:flex sm:max-w-[330px]',
        className,
      )}
    >
      {children}
    </aside>
  );
});

RightSideBar.displayName = 'RightSideBar';

export { RightSideBar };
