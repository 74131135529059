import { useQueryClient } from '@tanstack/react-query';
import { ApiUser } from 'farcaster-client-data';
import merge from 'lodash/merge';
import { useCallback } from 'react';

import {
  GloballyCachedUserCache,
  MergeIntoGloballyCachedUser,
  UserUpdates,
} from '../../../../types';
import { shouldUpdateCache } from '../../../../utils/CacheUtils';
import { buildGloballyCachedUserKey } from './buildGloballyCachedUserKey';
import { useGetGloballyCachedUser } from './useGetGloballyCachedUser';

const useMergeIntoGloballyCachedUser = (): MergeIntoGloballyCachedUser => {
  const queryClient = useQueryClient();
  const getCachedUser = useGetGloballyCachedUser();

  return useCallback(
    ({ updates }: { updates: UserUpdates }) => {
      const queryKey = buildGloballyCachedUserKey({
        fid: updates.fid,
      });

      const cachedUser = getCachedUser({
        fid: updates.fid,
      });

      if (shouldUpdateCache({ cache: cachedUser, updates })) {
        queryClient.setQueryData<GloballyCachedUserCache>(
          queryKey,
          (prevUser: ApiUser | undefined) => merge({}, prevUser, updates),
        );
      }
    },
    [getCachedUser, queryClient],
  );
};

export { useMergeIntoGloballyCachedUser };
