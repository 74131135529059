import {
  useInfiniteQuery,
  useSuspenseInfiniteQuery,
} from '@tanstack/react-query';
import { getNextPageCursor } from 'farcaster-client-data';

import { useFarcasterApiClient } from '../../../../providers/FarcasterApiClientProvider';
import { useBatchMergeIntoGloballyCachedUsers } from '../globallyCachedUser/useBatchMergeIntoGloballyCachedUsers';
import { buildSearchUsersFetcher } from './buildSearchUsersFetcher';
import { buildSearchUsersKey } from './buildSearchUsersKey';
import { defaultLimit } from './shared';

const gcTime = 1000 * 60 * 1;

const useSearchUsers = ({
  limit = defaultLimit,
  q,
  excludeSelf = false,
  prioritizeFids,
  includeDirectCastAbility = false,
}: {
  limit?: number;
  q: string;
  excludeSelf?: boolean;
  prioritizeFids?: number[];
  includeDirectCastAbility?: boolean;
}) => {
  const { apiClient } = useFarcasterApiClient();
  const batchMergeIntoGloballyCachedUsers =
    useBatchMergeIntoGloballyCachedUsers();

  return useSuspenseInfiniteQuery({
    initialPageParam: undefined,
    queryKey: buildSearchUsersKey({ q, limit, prioritizeFids }),

    queryFn: buildSearchUsersFetcher({
      q,
      excludeSelf,
      prioritizeFids,
      limit,
      apiClient,
      batchMergeIntoGloballyCachedUsers,
      includeDirectCastAbility,
    }),

    gcTime,
    getNextPageParam: getNextPageCursor,
  });
};

const useNonSuspenseSearchUsers = ({
  limit = defaultLimit,
  q,
  excludeSelf = false,
  prioritizeFids,
  includeDirectCastAbility = false,
}: {
  limit?: number;
  q: string;
  excludeSelf?: boolean;
  prioritizeFids?: number[];
  includeDirectCastAbility?: boolean;
}) => {
  const { apiClient } = useFarcasterApiClient();
  const batchMergeIntoGloballyCachedUsers =
    useBatchMergeIntoGloballyCachedUsers();

  return useInfiniteQuery({
    initialPageParam: undefined,
    queryKey: buildSearchUsersKey({
      q,
      limit,
      prioritizeFids,
      includeDirectCastAbility,
    }),

    queryFn: buildSearchUsersFetcher({
      q,
      excludeSelf,
      prioritizeFids,
      limit,
      apiClient,
      batchMergeIntoGloballyCachedUsers,
      includeDirectCastAbility,
    }),

    gcTime,
    getNextPageParam: getNextPageCursor,

    // Set placeholder data so that fetching doesn't block rendering on initial load and when
    // changing the query. This, in tandem with the logic in useFlatSearchUsersData allows us
    // to return an undefined result and show the loading spinner
    placeholderData: {
      pages: [],
      pageParams: [],
    },
  });
};

export { useNonSuspenseSearchUsers, useSearchUsers };
