import { useQueryClient } from '@tanstack/react-query';
import { ApiUser } from 'farcaster-client-data';
import { useCallback } from 'react';

import { GloballyCachedUserCache } from '../../../../types';
import { buildGloballyCachedUserKey } from './buildGloballyCachedUserKey';

const useGetGloballyCachedUser = () => {
  const queryClient = useQueryClient();

  return useCallback(
    ({ fid }: { fid: number }): ApiUser | undefined => {
      return queryClient.getQueryData<GloballyCachedUserCache>(
        buildGloballyCachedUserKey({ fid }),
      );
    },
    [queryClient],
  );
};

export { useGetGloballyCachedUser };
