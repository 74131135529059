/* eslint-disable func-params-args/func-args */
import { Mutex } from 'async-mutex';

import { DoubleRatchet } from './DoubleRatchet';
import { KeyTransport } from './KeyTransport';
import {
  Ciphertext,
  ConflictingStateError,
  Conversation,
  DataStore,
  EmbargoedCryptographyError,
  InboxDirectCast,
  KeyStore,
  KeyTransportState,
  PrivateKey,
  SigningPrivateKey,
  StoredPasskey,
  SymmetricEncryptionMode,
  SymmetricKey,
  UnconfirmedAgreement,
} from './types';
import {
  ApiDirectCastConversation,
  ApiFid,
  ApiSyncChannelMessage,
  DirectCast,
  FarcasterApiClient,
} from './types/api';

const ensureKeyMutex = new Map<string, Mutex>();
const ratchetMap = new Map<string, DoubleRatchet>();
const conversationMap = new Map<string, DirectCast[]>();
const conversationIdCacheMap = new Map<string, ApiDirectCastConversation>();
const keyStatusListeners = new Set<KeyStatusChangeEventListener>();
let keyTransportState: KeyTransportState | undefined = undefined;

type KeyStatus = {
  sendingIdentityKey: SigningPrivateKey;
  sendingSignedPreKey: PrivateKey;
};

export type OnKeyChangeParams = {
  type: string;
  hasKeyTransport: boolean;
  hasTransportBundle: boolean;
  localSyncTime: number;
  remoteKeyTimestamp: number;
};

type KeyStatusChangeEventListener = (update: KeyStatus | undefined) => void;
type RemoveKeyStatusChangeEventListenerFunc = () => void;

const MASTER_KEY = 'MASTER_KEY';

/**
 * Creates a random (not CSPRNG, not mandatory) identifier for a message which
 * has no relationship to the contents or state around the message itself.
 */
export const generateMessageId = () => {
  return 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'.replace(/x/g, () => {
    const v = (Math.random() * 16) | 0;
    return v.toString(16);
  });
};

export const isPasskeysSupported = async ({
  keyStore,
}: {
  keyStore: KeyStore;
}): Promise<boolean> => {
  return await keyStore.isPasskeysSupported();
};

export const getStoredPasskeys = async ({
  keyStore,
}: {
  keyStore: KeyStore;
}): Promise<StoredPasskey[]> => {
  const keys = await keyStore.getStoredPasskeys();
  let deduplicatedSet: StoredPasskey[] = [];
  for (let key of keys) {
    const index = deduplicatedSet.findIndex((s) => s.address === key.address);
    if (index >= 0) {
      deduplicatedSet.splice(index, 1, key);
    } else {
      deduplicatedSet.push(key);
    }
  }
  return deduplicatedSet;
};

export const updateStoredPasskey = async ({
  keyStore,
  credentialId,
  storedPasskey,
}: {
  keyStore: KeyStore;
  credentialId: string;
  storedPasskey: StoredPasskey;
}): Promise<boolean> => {
  return await keyStore.updateStoredPasskey(credentialId, storedPasskey);
};

export const deleteStoredPasskey = async ({
  keyStore,
  credentialId,
}: {
  keyStore: KeyStore;
  credentialId: string;
}): Promise<boolean> => {
  return await keyStore.deleteStoredPasskey(credentialId);
};

export const initiatePasskeyRegistration = async ({
  keyStore,
  address,
  username,
  displayName,
  fid,
  pfpUrl,
}: {
  keyStore: KeyStore;
  address: string;
  username: string;
  displayName: string;
  fid: number;
  pfpUrl: string;
}): Promise<string> => {
  const registrationResult = await keyStore.register({
    challenge: createChallenge(),
    rp: {
      name: 'Warpcast',
      id: 'warpcast.com',
    },
    user: {
      id: address,
      name: username,
      displayName: '@' + username,
    },
    pubKeyCredParams: [
      {
        type: 'public-key',
        alg: -7,
      },
      {
        type: 'public-key',
        alg: -257,
      },
    ],
    timeout: 1800000,
    attestation: 'none',
    authenticatorSelection: {
      authenticatorAttachment: 'platform',
      requireResidentKey: true,
      residentKey: 'required',
      userVerification: 'required',
    },
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const credentialId = (registrationResult as any).id;

  await updateStoredPasskey({
    keyStore,
    credentialId,
    storedPasskey: {
      credentialId,
      address,
      fid,
      username,
      displayName,
      pfpUrl,
    },
  });

  return credentialId;
};

export const completePasskeyRegistration = async ({
  keyStore,
  credentialId,
  mnemonic,
}: {
  keyStore: KeyStore;
  credentialId: string;
  mnemonic: string;
}): Promise<boolean> => {
  return !!(await keyStore.addMnemonicToCredential({
    challenge: 'internal',
    timeout: 1800000,
    userVerification: 'required',
    rpId: 'warpcast.com',
    credentialId,
    largeBlob: mnemonic,
  }));
};

export const authenticatePasskey = async ({
  keyStore,
  credentialId,
}: {
  keyStore: KeyStore;
  credentialId: string;
}): Promise<string> => {
  return (
    await keyStore.authenticate({
      challenge: createChallenge(),
      timeout: 1800000,
      userVerification: 'required',
      rpId: 'warpcast.com',
      credentialId,
    })
  ).largeBlob!;
};

export const getInbox = async ({
  keyStore,
  fid,
}: {
  keyStore: KeyStore;
  fid: ApiFid;
}): Promise<Conversation[]> => {
  await setName({ keyStore, fid });
  const inbox = await keyStore.getInbox();
  const convoMap = inbox
    .filter((i) => !!i.lastMessage)
    .map((i) => {
      return {
        conversationId: i.conversationId,
        fids: new Set(i.participants.map((p) => p.fid)),
        lastMessageTimestamp: i.lastMessage?.timestamp ?? 0,
      };
    })
    .sort((a, b) => b.lastMessageTimestamp - a.lastMessageTimestamp);

  return inbox
    .sort(
      (a, b) =>
        (b.lastMessage?.timestamp ?? 0) - (a.lastMessage?.timestamp ?? 0),
    )
    .filter(
      (i, idx) =>
        convoMap.findIndex((c) => {
          const fidSet = new Set(i.participants.map((p) => p.fid));
          return (
            c.fids.size === fidSet.size &&
            [...c.fids].every((f) => fidSet.has(f))
          );
        }) === idx,
    )
    .map((c) => {
      c.conversationId = [...new Set(c.participants.map((p) => p.fid))]
        .sort()
        .join('-');
      return c;
    });
};

export const getUnseenConversations = async ({
  keyStore,
  fid,
}: {
  keyStore: KeyStore;
  fid: ApiFid;
}): Promise<Conversation[]> => {
  const conversations = await getInbox({ keyStore, fid });

  return conversations.filter((c) => c.lastMessage?.messageType !== 'read');
};

export const getConversation = async ({
  keyStore,
  farcasterApiClient,
  participants,
  fid,
}: {
  keyStore: KeyStore;
  farcasterApiClient: FarcasterApiClient;
  participants: ApiFid[];
  fid: ApiFid;
}): Promise<Conversation> => {
  await sync({
    keyStore,
    fid,
  });
  const inbox = await keyStore.getInbox();
  const conversation = inbox
    .filter((i) => !!i.lastMessage)
    .sort(
      (a, b) =>
        (b.lastMessage?.timestamp ?? 0) - (a.lastMessage?.timestamp ?? 0),
    )
    .find((c) => {
      const existingParticipants = new Set(c.participants.map((p) => p.fid));
      // console.log("in conversation:" + JSON.stringify(existingParticipants));
      // console.log("compare:" + JSON.stringify(participants));
      return (
        participants.length === existingParticipants.size &&
        participants.every((e) => existingParticipants.has(e))
      );
    });

  if (conversation) {
    conversation.conversationId = [
      ...new Set(conversation.participants.map((p) => p.fid)),
    ]
      .sort()
      .join('-');
    return conversation;
  }

  const keyInfo = await Promise.all(
    participants.map(async (f) => {
      const result = await farcasterApiClient.getDirectCastKeysByAccount({
        fid: f,
      });
      return {
        info: result.data.result,
      };
    }),
  );

  var conversationId = [...new Set([...participants, fid])].sort().join('-');

  return {
    conversationId,
    lastFetchedAt: new Date().getTime(),
    conversationName: '',
    retentionTime: 60 * 24 * 60 * 60 * 1000,
    participants: keyInfo.flatMap(({ info }) => {
      return info.keys.idk.map((ki) => {
        return {
          conversationId,
          inboxId: ki.inboxId,
          fid: info.user.fid,
          address: ki.account,
          userInfo: info.user,
          joinedAt: new Date().getTime(),
          identityKey: ki.base64PublicKey,
          signedPreKey: info.keys.spk.find((s) => s.inboxId === ki.inboxId)!
            .base64PublicKey,
        };
      });
    }),
    unreadCount: 0,
  };
};

export const getConversationPage = async ({
  keyStore,
  fid,
  conversationId,
  pageSize,
  before,
  after,
}: {
  keyStore: KeyStore;
  fid: ApiFid;
  conversationId: string;
  pageSize: number;
  before: number | undefined;
  after: number | undefined;
}): Promise<InboxDirectCast[]> => {
  await setName({ keyStore, fid });
  const inbox = await keyStore.getInbox();
  // yes, this is gross, but the UI no longer knows about the real conversation
  // ids, so we have this:
  const matchingFids = new Set(
    conversationId.split('-').map((c) => parseInt(c, 10)),
  );

  const convoMap = inbox.map((i) => {
    return {
      conversationId: i.conversationId,
      fids: new Set(i.participants.map((p) => p.fid)),
      lastMessage: i.lastMessage,
    };
  });
  const allRelated = convoMap.filter(
    (i) =>
      i.lastMessage &&
      i.fids.size === matchingFids.size &&
      [...i.fids].every((f) => matchingFids.has(f)),
  );
  // There's a weird double-hook call that's happening making the cursor bounce
  // unpredictably. Let's just overquery and remove the problem.
  if (allRelated.length > 1) {
    pageSize = 1000;
  }
  const result = (
    await Promise.all(
      allRelated.map(async (a) => {
        try {
          return await keyStore.getConversationPage(
            a.conversationId,
            pageSize,
            before,
            after,
          );
        } catch {
          // there is an odd edge case for early tests that get broken by this call,
          // ignore those failed promises
          return [];
        }
      }),
    )
  ).flatMap((a) => a);
  if (before !== undefined) {
    return result
      .sort((a, b) => b.timestamp - a.timestamp)
      .slice(0, pageSize)
      .sort((a, b) => a.timestamp - b.timestamp);
  } else {
    return result.sort((a, b) => a.timestamp - b.timestamp).slice(0, pageSize);
  }
};

let runOnce = 0;

export const setName = async ({
  keyStore,
  fid,
}: {
  keyStore: KeyStore;
  fid: ApiFid;
}) => {
  if (runOnce !== fid) {
    runOnce = fid;
    return await keyStore.initializeWithName('farcaster' + fid);
  }
};

export const ensureInboxKeys = async ({
  address,
  fid,
  walletSignMessage,
  deviceId,
  deviceName,
  keyStore,
  farcasterApiClient,
}: {
  address: string;
  fid: ApiFid;
  walletSignMessage: (message: ArrayLike<number> | string) => Promise<string>;
  deviceId: string;
  deviceName: string;
  keyStore: KeyStore;
  farcasterApiClient: FarcasterApiClient;
}) => {
  await setName({ keyStore, fid });
  try {
    let inboxId = await keyStore.getInboxId();
    // console.log("inboxId: " + inboxId);
    const keys = await farcasterApiClient.getDirectCastKeysByAccount({
      fid,
    });

    let addressMismatch = keys.data.result.keys.idk.filter(
      (i) => i.account.toLowerCase() !== address.toLowerCase(),
    );

    let inboxKeys = await keyStore.getPublicInboxKeys();

    // We want to consider a few scenarios. If there are mismatching addresses:
    if (addressMismatch.length > 0) {
      const existingKey = keys.data.result.keys.idk.filter(
        (i) => i.inboxId === inboxId,
      );
      // Do we have an existing key in the set?
      if (existingKey.length > 0) {
        // Are the mismatched address keys newer?
        const newerKeysWithMismatch = addressMismatch.filter(
          (a) => a.timestamp > existingKey[0]!.timestamp,
        );
        // If so, delete our own
        if (newerKeysWithMismatch.length > 0) {
          await wipeKeystore({ keyStore });
        } else {
          // Otherwise delete those
          for (const key of addressMismatch) {
            await farcasterApiClient.deleteDirectCastKeysByInbox({
              inboxId: key.inboxId,
            });
          }
        }
      }

      inboxId = await keyStore.getInboxId();
      inboxKeys = await keyStore.getPublicInboxKeys();
    }

    // console.log("fetched keys: " + JSON.stringify(keys));

    if (!keys.data.result.keys.idk.find((i) => i.inboxId === inboxId)) {
      // console.log("local keys: " + JSON.stringify(keys));
      const idkSig = await walletSignMessage(
        inboxKeys.idk.base64PublicKey + inboxKeys.idk.inboxId,
      );
      // console.log("idk sig: " + JSON.stringify(idkSig));
      const spkSig = await walletSignMessage(
        inboxKeys.spk.base64PublicKey + inboxKeys.spk.inboxId,
      );
      // console.log("spk sig: " + JSON.stringify(spkSig));
      await farcasterApiClient.addDirectCastKeysByAccount({
        idk: {
          ...inboxKeys.idk,
          account: address,
          deviceId,
          deviceName,
          base64Signature: Buffer.from(
            idkSig.replace('0x', ''),
            'hex',
          ).toString('base64'),
        },
        spk: {
          ...inboxKeys.spk,
          account: address,
          deviceId,
          deviceName,
          base64Signature: Buffer.from(
            spkSig.replace('0x', ''),
            'hex',
          ).toString('base64'),
        },
      });
      // console.log("uploaded new keys");
    }
  } catch (e: unknown) {
    if ((e as Error)?.message.includes('embargoed')) {
      throw new EmbargoedCryptographyError('ensureInboxKeys', { error: e });
    } else {
      throw e;
    }
  }
};

export const sync = async ({
  keyStore,
  fid,
}: {
  keyStore: KeyStore;
  fid: ApiFid;
}) => {
  await setName({ keyStore, fid });
  await keyStore.getInboxId();

  await keyStore.clearOldMessages();
};

export const deleteConversation = async ({
  keyStore,
  conversationId,
}: {
  keyStore: KeyStore;
  conversationId: string;
}): Promise<void> => {
  const inbox = await keyStore.getInbox();
  const matchingFids = new Set(
    conversationId.split('-').map((c) => parseInt(c, 10)),
  );
  const convoMap = inbox.map((i) => {
    return {
      conversationId: i.conversationId,
      fids: new Set(i.participants.map((p) => p.fid)),
      lastMessage: i.lastMessage,
    };
  });
  const allRelated = convoMap.filter(
    (i) =>
      i.lastMessage &&
      i.fids.size === matchingFids.size &&
      [...i.fids].every((f) => matchingFids.has(f)),
  );
  for (const related of allRelated) {
    await keyStore.deleteConversation(related.conversationId);
  }
};

/**
 * Encrypts plaintext data with the master storage key, returning a Ciphertext
 * object. Ciphertext can be safely used in `JSON.stringify`.
 */
export const encrypt = async ({
  keyStore,
  data,
  onError,
}: {
  keyStore: KeyStore;
  data: string;
  onError?: (error: unknown) => void;
}) => {
  let symKey: SymmetricKey | undefined;

  try {
    symKey = await keyStore.getSymmetricKey({
      id: MASTER_KEY,
    } as unknown as SymmetricKey);
  } catch (error) {
    onError && onError(error);
  }

  if (!symKey) {
    symKey = await keyStore.createSymmetricKey(MASTER_KEY);
  }

  return await symKey.encrypt({
    encryptionMode: SymmetricEncryptionMode.AES_256_GCM,
    base64Plaintext: Buffer.from(data, 'utf-8').toString('base64'),
  });
};

export const wipeKeystore = async ({ keyStore }: { keyStore: KeyStore }) => {
  await keyStore.wipeData();
};

/**
 * Decrypts a Ciphertext with the master storage key, returning a plaintext.
 */
export const decrypt = async ({
  keyStore,
  data,
}: {
  keyStore: KeyStore;
  data: Ciphertext;
}) => {
  let symKey = await keyStore.getSymmetricKey({
    id: MASTER_KEY,
  } as unknown as SymmetricKey);

  return Buffer.from(
    await symKey.decrypt({
      encryptionMode: SymmetricEncryptionMode.AES_256_GCM,
      ciphertext: data,
    }),
    'base64',
  ).toString('utf-8');
};

export const getKeyTransportState = async ({
  keyStore,
  dataStore,
}: {
  keyStore: KeyStore;
  dataStore: DataStore;
}) => {
  const keyTransport = await getKeyTransport({
    keyStore,
    dataStore,
  });

  const transportKey = await keyTransport.getTransportKey();

  if (transportKey) {
    keyTransportState = KeyTransportState.INITIALIZED;
  } else {
    keyTransportState = KeyTransportState.NOT_INITIALIZED;
  }

  return keyTransportState;
};

export const createSyncChannel = async ({
  farcasterApiClient,
  syncChannelIdentifier,
  keyStore,
  dataStore,
  sender,
  cancelController,
}: {
  farcasterApiClient: FarcasterApiClient;
  syncChannelIdentifier: string;
  keyStore: KeyStore;
  dataStore: DataStore;
  sender: boolean;
  cancelController: { cancel: boolean };
}) => {
  const keyTransport = await getKeyTransport({
    keyStore,
    dataStore,
  });

  const params = await keyTransport.initiateKeyAgreement(syncChannelIdentifier);
  let initiated = false;
  while (!initiated) {
    if (cancelController.cancel) {
      keyTransportState = KeyTransportState.NOT_INITIALIZED;
      throw new ConflictingStateError({
        message: 'the operation has been cancelled',
      });
    }

    try {
      await farcasterApiClient.updateSyncChannel(params);
      initiated = true;
    } catch {
      await new Promise<void>((resolve) => {
        setTimeout(() => {
          resolve();
        }, 1000);
      });
    } // Deploys and connectivity issues will interrupt this, we shouldn't break
  }

  let messages: ApiSyncChannelMessage[] = [];

  do {
    if (cancelController.cancel) {
      keyTransportState = KeyTransportState.NOT_INITIALIZED;
      throw new ConflictingStateError({
        message: 'the operation has been cancelled',
      });
    }

    await new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, 1000);
    });
    try {
      const result = await farcasterApiClient.getSyncChannel(
        await keyTransport.generateSyncChannelGetParams(syncChannelIdentifier),
      );
      messages = result.data.result.messages;
    } catch {} // Deploys and connectivity issues will interrupt this, we shouldn't break
  } while (messages.length === 0);

  if (!sender) {
    let updated = false;
    while (!updated) {
      if (cancelController.cancel) {
        keyTransportState = KeyTransportState.NOT_INITIALIZED;
        throw new ConflictingStateError({
          message: 'the operation has been cancelled',
        });
      }

      try {
        await farcasterApiClient.updateSyncChannel(
          await keyTransport.initiateKeyAgreement(syncChannelIdentifier),
        );
        updated = true;
      } catch {
        await new Promise<void>((resolve) => {
          setTimeout(() => {
            resolve();
          }, 1000);
        });
      } // Deploys and connectivity issues will interrupt this, we shouldn't break
    }

    do {
      if (cancelController.cancel) {
        keyTransportState = KeyTransportState.NOT_INITIALIZED;
        throw new ConflictingStateError({
          message: 'the operation has been cancelled',
        });
      }
      await new Promise<void>((resolve) => {
        setTimeout(() => {
          resolve();
        }, 1000);
      });

      try {
        const result = await farcasterApiClient.getSyncChannel(
          await keyTransport.generateSyncChannelGetParams(
            syncChannelIdentifier,
          ),
        );
        messages = result.data.result.messages;
      } catch {} // Deploys and connectivity issues will interrupt this, we shouldn't break
    } while (messages.length === 0);
  }

  let agreement: UnconfirmedAgreement | undefined;

  for (const message of messages) {
    if (
      JSON.parse(Buffer.from(message.message, 'base64').toString('utf-8'))
        .type === 'PublicKey'
    ) {
      agreement = await keyTransport.receiveKeyAgreementRequest({
        channelId: syncChannelIdentifier,
        ...message,
      });
      const readParams = await keyTransport.generateSetMessageReadParams(
        syncChannelIdentifier,
        message.messageHash,
      );

      let markedRead = false;
      while (!markedRead) {
        if (cancelController.cancel) {
          keyTransportState = KeyTransportState.NOT_INITIALIZED;
          throw new ConflictingStateError({
            message: 'the operation has been cancelled',
          });
        }

        try {
          await farcasterApiClient.markSyncChannelMessageRead(readParams);
          markedRead = true;
        } catch {
          await new Promise<void>((resolve) => {
            setTimeout(() => {
              resolve();
            }, 1000);
          });
        } // Deploys and connectivity issues will interrupt this, we shouldn't break
      }
    }
  }

  keyTransportState = KeyTransportState.AGREE_KEY;
  return agreement;
};

export const confirmKeyAgreement = async ({
  agreement,
  farcasterApiClient,
  syncChannelIdentifier,
  keyStore,
  dataStore,
  sender,
  cancelController,
}: {
  agreement: UnconfirmedAgreement;
  farcasterApiClient: FarcasterApiClient;
  syncChannelIdentifier: string;
  keyStore: KeyStore;
  dataStore: DataStore;
  sender: boolean;
  cancelController: { cancel: boolean };
}) => {
  const keyTransport = await getKeyTransport({
    keyStore,
    dataStore,
  });

  let messages: ApiSyncChannelMessage[] = [];

  let optionalMessage = await keyTransport.confirmKeyAgreement(
    syncChannelIdentifier,
    agreement,
    sender,
  );

  if (optionalMessage) {
    let sent = false;
    while (!sent) {
      if (cancelController.cancel) {
        keyTransportState = KeyTransportState.NOT_INITIALIZED;
        throw new ConflictingStateError({
          message: 'the operation has been cancelled',
        });
      }

      try {
        await farcasterApiClient.updateSyncChannel(optionalMessage);
        sent = true;
      } catch {
        await new Promise<void>((resolve) => {
          setTimeout(() => {
            resolve();
          }, 1000);
        });
      } // Deploys and connectivity issues will interrupt this, we shouldn't break
    }
  }

  if (!sender) {
    do {
      if (cancelController.cancel) {
        keyTransportState = KeyTransportState.NOT_INITIALIZED;
        throw new ConflictingStateError({
          message: 'the operation has been cancelled',
        });
      }
      await new Promise<void>((resolve) => {
        setTimeout(() => {
          resolve();
        }, 1000);
      });
      try {
        const result = await farcasterApiClient.getSyncChannel(
          await keyTransport.generateSyncChannelGetParams(
            syncChannelIdentifier,
          ),
        );
        messages = result.data.result.messages;
      } catch {} // Deploys and connectivity issues will interrupt this, we shouldn't break
    } while (messages.length === 0);

    for (const message of messages) {
      if (
        JSON.parse(Buffer.from(message.message, 'base64').toString('utf-8'))
          .type === 'SymmetricKey'
      ) {
        await keyTransport.handleSyncMessage({
          ...message,
          channelId: syncChannelIdentifier,
        });
        const readParams = await keyTransport.generateSetMessageReadParams(
          syncChannelIdentifier,
          message.messageHash,
        );
        let markedRead = false;
        while (!markedRead) {
          if (cancelController.cancel) {
            keyTransportState = KeyTransportState.NOT_INITIALIZED;
            throw new ConflictingStateError({
              message: 'the operation has been cancelled',
            });
          }

          try {
            await farcasterApiClient.markSyncChannelMessageRead(readParams);
            markedRead = true;
          } catch {
            await new Promise<void>((resolve) => {
              setTimeout(() => {
                resolve();
              }, 1000);
            });
          } // Deploys and connectivity issues will interrupt this, we shouldn't break
        }
      }
    }

    await dataStore.setLastSyncTime();
  } else {
    // reset all the ratchets so the next send has reinit headers - yes we wipe
    // the ratchet map after, but this affects stored state of ratchets, so we
    // will recover this information and reinit.
    for (const [, ratchet] of ratchetMap) {
      await ratchet.resetRatchet();
    }
  }

  keyTransportState = KeyTransportState.INITIALIZED;
  // nuke the ratchet map – we have to rebuild them because old key transports persist otherwise
  ratchetMap.clear();
  await dataStore.clearConversationFetchTimes();
  conversationIdCacheMap.clear();
  conversationMap.clear();
  return keyTransport;
};

export const addKeyStatusChangeListener = (
  listener: KeyStatusChangeEventListener,
): RemoveKeyStatusChangeEventListenerFunc => {
  // This needs to be updated for multi-account.
  ensureKeyMutex.get('farcaster')?.runExclusive(() => {
    keyStatusListeners.add(listener);
  });

  return () => {
    ensureKeyMutex.get('farcaster')?.runExclusive(() => {
      keyStatusListeners.delete(listener);
    });
  };
};

const keyTransport = new Map<string, KeyTransport>();

export const getKeyTransport = async ({
  keyStore,
  dataStore,
}: {
  keyStore: KeyStore;
  dataStore: DataStore;
}): Promise<KeyTransport> => {
  if (!keyTransport.has(keyStore.name)) {
    const eckey = await keyStore.createEphemeralKey();
    keyTransport.set(
      keyStore.name,
      new KeyTransport({
        ecTransportAgreementKey: eckey,
        keyStore,
        dataStore,
      }),
    );
  }

  return keyTransport.get(keyStore.name)!;
};

export const unsafeKeyTransportReset = async ({
  keyStore,
  dataStore,
}: {
  keyStore: KeyStore;
  dataStore: DataStore;
}) => {
  const keyTransport = await getKeyTransport({
    keyStore,
    dataStore,
  });
  await keyTransport.resetKeyTransport();

  for (const listener of keyStatusListeners.values()) {
    listener(undefined);
  }

  ratchetMap.clear();
  conversationMap.clear();
  await dataStore.clearConversationFetchTimes();
  conversationIdCacheMap.clear();
};

// On a surface glance this seems dangerous, but the challenge is effectively
// moot, just needs to be unique.
const createChallenge = () => {
  return Buffer.from(
    'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'.replace(
      /[x]/g,
      () => {
        const r = (Math.random() * 16) | 0,
          v = r;
        return v.toString(16);
      },
    ),
    'hex',
  ).toString('base64');
};
