import { ArrowRightIcon, InfoIcon } from '@primer/octicons-react';
import { isHandledFetchError } from 'farcaster-client-data';
import {
  formatNumber,
  SharedAmpEvent,
  useChannelCreationInfo,
  useCreateChannel,
  useDebouncedValue,
  useValidateNewChannelKey,
} from 'farcaster-client-hooks';
import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { DefaultButton } from '~/components/forms/buttons/DefaultButton';
import { TextInput } from '~/components/forms/TextInput';
import { Image } from '~/components/images/Image';
import { ExternalLink } from '~/components/links/ExternalLink';
import { DefaultModalContainer } from '~/components/modals/DefaultModalContainer';
import { Modal } from '~/components/modals/Modal';
import { Warp } from '~/components/warps/Warp';
import { useAnalytics } from '~/contexts/AnalyticsProvider';
import { useNavigate } from '~/hooks/navigation/useNavigate';
import { useNavigateToChannel } from '~/hooks/navigation/useNavigateToChannel';

export const CHANNEL_KEY_VALIDATION_REGEX = /^[0-9a-z][0-9a-z-]{0,15}$/;
const INVALID_NAME_ERROR_MSG = 'Allowed characters: a-z, 0-9, - (not at start)';

type CreateChannelModalProps = {
  onClose: () => void;
};

const CreateChannelModal: FC<CreateChannelModalProps> = memo(({ onClose }) => {
  const { data } = useChannelCreationInfo();
  const navigate = useNavigate();
  const navigateToChannel = useNavigateToChannel();
  const { trackEvent } = useAnalytics();

  const [localValidationError, setLocalValidationError] = useState<
    string | undefined
  >(undefined);
  const [serverValidationError, setServerValidationError] = useState<
    string | undefined
  >(undefined);
  const [serverCreationError, setServerCreationError] = useState<
    string | undefined
  >(undefined);
  const [key, setKey] = useState('');
  const debouncedKey = useDebouncedValue({
    value: key,
    debounceDuration: 300,
  });
  const createChannel = useCreateChannel();

  useEffect(() => {
    if (key && !CHANNEL_KEY_VALIDATION_REGEX.test(key)) {
      setLocalValidationError(INVALID_NAME_ERROR_MSG);
    } else {
      setLocalValidationError('');
    }
  }, [key]);

  const validateNewChannelKey = useValidateNewChannelKey();
  useEffect(() => {
    if (debouncedKey) {
      (async () => {
        const result = await validateNewChannelKey({
          channelKey: debouncedKey,
        });

        if (!result.valid) {
          setServerValidationError(INVALID_NAME_ERROR_MSG);
        } else if (!result.available) {
          setServerValidationError(
            `There is a already a ${debouncedKey} channel`,
          );
        } else {
          setServerValidationError('');
        }
      })();
    } else {
      setServerValidationError('');
    }
  }, [debouncedKey, validateNewChannelKey]);

  const keyValid = useMemo(
    // Only valid if we've checked the key the user is seeing
    () =>
      key.length > 0 &&
      key === debouncedKey &&
      !localValidationError &&
      !serverValidationError,
    [debouncedKey, key, localValidationError, serverValidationError],
  );

  const doCreateChannel = useCallback(async () => {
    if (data && data.channelCreationPossible && data.warpsCostPerYear) {
      try {
        await createChannel({
          key: debouncedKey,
          warpsCost: data.warpsCostPerYear,
        });
        trackEvent(SharedAmpEvent.CreateChannel, undefined);

        onClose();
        navigateToChannel({ channelKey: debouncedKey });
      } catch (error) {
        if (isHandledFetchError(error) && error.responseData.errors.length) {
          const message = error.responseData.errors[0].message;
          setServerCreationError(message);
        } else {
          setServerCreationError(
            'Error creating channel. Please try again later',
          );
        }
      }
    }
  }, [
    data,
    createChannel,
    debouncedKey,
    trackEvent,
    onClose,
    navigateToChannel,
  ]);

  return (
    <Modal>
      <DefaultModalContainer onClose={onClose}>
        <div className="flex h-full w-full flex-col items-center justify-center">
          <div
            className="relative flex max-w-2xl flex-col justify-between rounded-lg border bg-app border-default"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Image
              src={'/~/images/NewChannelHero.png'}
              style={{
                width: 500,
                height: 230,
                minWidth: 500,
                minHeight: 230,
              }}
              alt={'Header'}
              className="z-0 px-4 pb-6 pt-4"
            />
            <div className="absolute inset-0 z-10 flex h-[230px] items-end rounded-t-lg bg-gradient-to-t from-white from-10% to-transparent text-2xl text-default dark:from-[#1f162a]">
              <span className="pl-6 font-semibold">
                A home for your community
              </span>
            </div>
            <div className="flex w-[500px] flex-col px-6">
              <div className="mt-4 text-default">
                Create a space on Farcaster where you can bring people with
                shared interests together.
              </div>
              <div className="mt-4 text-default">
                Channel names have a{' '}
                <ExternalLink
                  href="https://warpcast.notion.site/Username-policy-f4f3b1c024b24b3bbc8aaca609b3558e"
                  title="Learn more"
                >
                  no squatting policy
                </ExternalLink>
                .
              </div>
              {data && data.warpsCostPerYear && (
                <div className="mt-4 flex flex-row items-center">
                  <span className="mr-2 text-muted">
                    Creating a channel costs
                  </span>
                  <Warp size={16} />
                  <span className="ml-1 mr-3 text-default">
                    {formatNumber(data.warpsCostPerYear)} / year
                  </span>
                  <ExternalLink
                    href="https://warpcast.notion.site/Channels-77aa2f975b164ebebf356287a2b3c667"
                    title="Learn more"
                  >
                    <InfoIcon className="text-muted" />
                  </ExternalLink>
                </div>
              )}
              <span className="mt-6 text-sm text-muted">Your channel name</span>
              <TextInput
                className="mt-2"
                contentEditable={true}
                value={key}
                autoFocus={false}
                autoCorrect="false"
                autoCapitalize="false"
                spellCheck={false}
                unselectable={'off'}
                maxLength={16}
                onChange={(text) => {
                  setKey(text.target.value.toLowerCase());
                }}
              />
              <span className="mt-1 text-sm text-danger">
                {serverValidationError ||
                  localValidationError ||
                  serverCreationError}
                &nbsp;
              </span>
              <DefaultButton
                variant="normal"
                disabled={!data?.channelCreationPossible || !keyValid}
                onClick={doCreateChannel}
                title="Create channel"
                size="lg"
                className="mt-4"
              >
                Create channel
              </DefaultButton>
              {data && data.warpsBalance && (
                <div
                  className="mb-6 mt-8 flex flex-row items-center justify-center text-sm text-muted hover:cursor-pointer hover:underline"
                  onClick={() => {
                    navigate({
                      options: { openInNewTab: true },
                      to: 'warps',
                      params: {},
                      searchParams: {},
                    });
                  }}
                >
                  <span>
                    You have {formatNumber(data.warpsBalance ?? 0)} warps
                  </span>
                  <ArrowRightIcon className={'ml-1'} />
                </div>
              )}
            </div>
          </div>
        </div>
      </DefaultModalContainer>
    </Modal>
  );
});

CreateChannelModal.displayName = 'CreateChannelModal';

export { CreateChannelModal };
