import { ADMIN_FIDS } from 'farcaster-client-hooks';
import { useMemo } from 'react';

import { useCachedCurrentUser } from '~/hooks/data/useCachedCurrentUser';

const useIsAdmin = () => {
  const cachedCurrentUser = useCachedCurrentUser();

  return useMemo(
    () =>
      typeof cachedCurrentUser !== 'undefined' &&
      ADMIN_FIDS.has(cachedCurrentUser.fid),
    [cachedCurrentUser],
  );
};

export { useIsAdmin };
