import { Bytes } from 'ethers';
import {
  defaultBaseUrl,
  FarcasterApiClient,
  FarcasterApiClientMetaOptions,
  Fetcher,
  OnError,
  OnFetchStart,
  OnSuccess,
  OnTimeout,
} from 'farcaster-client-data';
import React, {
  createContext,
  memo,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import { GlobalCacheUsageProvider } from './GlobalCacheUsageProvider';
import { PurgedProvider } from './PurgedProvider';

export type FarcasterApiClientContextValue = {
  apiClient: FarcasterApiClient;
  baseUrl: string;
  signMessage: SignMessage;
};

export type SignMessage = (message: string | Bytes) => Promise<string>;

const FarcasterApiClientContext = createContext<FarcasterApiClientContextValue>(
  {
    apiClient: new FarcasterApiClient({
      baseUrl: defaultBaseUrl,
      meta: {},
      debug: false,
      timeoutRetryDecayFactor: 0.3,
    }),
    baseUrl: defaultBaseUrl,
    signMessage: async () => '',
  },
);

export type FarcasterApiClientProviderProps = {
  address: string | undefined;
  baseUrl?: string;
  wsUrl?: string;
  children: ReactNode;
  debug?: boolean;
  meta: FarcasterApiClientMetaOptions;
  fetch?: Fetcher;
  mutateTimeout?: number;
  onError?: OnError;
  onFetchStart?: OnFetchStart;
  onSuccess?: OnSuccess;
  onTimeout?: OnTimeout;
  readTimeout?: number;
  signMessage: SignMessage;
  getAmplitudeDeviceId?: () => string | undefined;
  getAmplitudeSessionId?: () => number | undefined;
  timeoutRetryDecayFactor?: number;
  checkOffline?: () => Promise<boolean>;
};

const FarcasterApiClientProvider = memo(
  ({
    baseUrl = defaultBaseUrl,
    wsUrl,
    children,
    debug = false,
    fetch,
    meta,
    mutateTimeout,
    onError,
    onFetchStart,
    onSuccess,
    onTimeout,
    readTimeout,
    signMessage,
    getAmplitudeDeviceId,
    getAmplitudeSessionId,
    timeoutRetryDecayFactor,
    checkOffline,
  }: FarcasterApiClientProviderProps) => {
    const options = useMemo(() => {
      return {
        baseUrl,
        wsUrl,
        debug,
        fetch,
        meta,
        mutateTimeout,
        onError,
        onFetchStart,
        onSuccess,
        onTimeout,
        readTimeout,
        getAmplitudeDeviceId,
        getAmplitudeSessionId,
        timeoutRetryDecayFactor,
        checkOffline,
      };
    }, [
      baseUrl,
      wsUrl,
      debug,
      fetch,
      meta,
      mutateTimeout,
      onError,
      onFetchStart,
      onSuccess,
      onTimeout,
      readTimeout,
      getAmplitudeDeviceId,
      getAmplitudeSessionId,
      timeoutRetryDecayFactor,
      checkOffline,
    ]);

    const apiClient = useRef<FarcasterApiClient>();

    useEffect(() => {
      // this makes sure we're not instantiating a new class we don't
      // need on every render, defining inline w/ useRef still executes
      if (!apiClient.current) {
        apiClient.current = new FarcasterApiClient(options);
      }

      apiClient.current?.updateOptions(options);
    }, [apiClient, options]);

    const derivedApiClient = useMemo(() => {
      // TODO: should actually just be returning the ref or providing a getter
      // method instead, but apiClient is used at so many call sites that it
      // is not feasible to change it right now, migrating to `apiClient()`
      // getter with a codemod might be the best way to address systemically
      if (apiClient.current) {
        return apiClient.current;
      }

      // would rather not need to do this, but apiClient cannot be undefined
      return new FarcasterApiClient(
        options || {
          baseUrl: defaultBaseUrl,
          meta: {},
        },
      );
    }, [apiClient, options]);

    const contextValue = useMemo(
      () => ({
        apiClient: derivedApiClient,
        baseUrl,
        signMessage,
      }),
      [baseUrl, derivedApiClient, signMessage],
    );

    return useMemo(
      () => (
        <GlobalCacheUsageProvider>
          <PurgedProvider>
            <FarcasterApiClientContext.Provider value={contextValue}>
              {children}
            </FarcasterApiClientContext.Provider>
          </PurgedProvider>
        </GlobalCacheUsageProvider>
      ),
      [children, contextValue],
    );
  },
);

FarcasterApiClientProvider.displayName = 'FarcasterApiClientProvider';

const useFarcasterApiClient = () => useContext(FarcasterApiClientContext);

export { FarcasterApiClientProvider, useFarcasterApiClient };
