import {
  ApiNotificationType,
  ApiUserChannelsCategory,
  CastHashPrefix,
} from 'farcaster-client-data';

import {
  AddCastActionPage,
  AdminFeedsComparisonPage,
  AdminReviewsPage,
  ApiKeysPage,
  AppsPage,
  BookmarksPage,
  CastActionPlaygroundPage,
  ChannelFeedPage,
  ChannelFollowersPage,
  ChannelFollowersYouKnowPage,
  ChannelJoinViaCodePage,
  ChannelMembersPage,
  ChannelPage,
  ChannelsPage,
  ChannelsSearchPage,
  ClaimInvitePage,
  CoinbaseWalletRegistrationPage,
  CollectionOwnersPage,
  ComposePage,
  ComposerActionPlaygroundPage,
  ConversationQuotesWithoutUsernamePage,
  ConversationQuotesWithUsernamePage,
  ConversationReactionsWithoutUsernamePage,
  ConversationReactionsWithUsernamePage,
  ConversationRecastsWithoutUsernamePage,
  ConversationRecastsWithUsernamePage,
  ConversationWithoutUsernamePage,
  ConversationWithUsernamePage,
  DebugAdminTokenPage,
  DebugCastsPage,
  DebugFeatureFlagsPage,
  DebugMenuPage,
  DevelopersEmbedsPage,
  DevelopersPage,
  DirectCastsConversationPage,
  DirectCastsInboxCreatePage,
  DirectCastsInboxPage,
  DirectCastsInvitePage,
  DiscoverActionsPage,
  DownloadPage,
  FollowersWithoutUsernamePage,
  FollowersWithUsernamePage,
  FollowersYouKnowWithoutUsernamePage,
  FollowersYouKnowWithUsernamePage,
  FollowingPage,
  FollowingWithoutUsernamePage,
  FollowingWithUsernamePage,
  FrameDevelopersPage,
  FrameValidatorPage,
  GlobalFrameAnalyticsPage,
  HomeFeedPage,
  LaunchComposerActionPage,
  LocationUsersPage,
  MagicLinkPage,
  ManageChannelsForCategoryPage,
  MintPage,
  NotificationGroupAssetsPage,
  NotificationGroupCastsPage,
  NotificationGroupChannelRoleInvitesPage,
  NotificationGroupFramesPage,
  NotificationGroupUsersPage,
  NotificationsPage,
  PaidInvitgePage,
  PrivacyPolicyPage,
  ProfileCastsAndRepliesWithoutUsernamePage,
  ProfileCastsAndRepliesWithUsernamePage,
  ProfileCastsWithoutUsernamePage,
  ProfileCastsWithUsernamePage,
  ProfileLikesWithoutUsernamePage,
  ProfileLikesWithUsernamePage,
  RecentSearchPage,
  RecoveryInitiatePage,
  RecoveryPage,
  RecoveryStartPage,
  SettingsActionsPage,
  SettingsAdvancedPage,
  SettingsCastsAndUsersPage,
  SettingsConnectedAccountsPage,
  SettingsConnectedAddressesPage,
  SettingsDirectCastsOthersPage,
  SettingsDirectCastsPage,
  SettingsDirectCastsRecommendedPage,
  SettingsMutedKeywordsPage,
  SettingsNotificationsPage,
  SettingsProfilePage,
  SettingsStarterPacksPage,
  SignInWithFarcasterPage,
  SignupForInvitePage,
  StarterPackPage,
  SupportPage,
  TermsOfUsePage,
  TopSearchPage,
  TrendingPage,
  UsersForYouPage,
  UsersSearchPage,
  WarpsPage,
} from '~/lazy/pages';
import { ChannelEditSection } from '~/types/routing';

import {
  appExplorePathPrefix,
  appInboxPathPrefix,
  appPathPrefix,
  appSearchPathPrefix,
  appSettingsPathPrefix,
} from './routePrefixes';

const family = (family: RouteFamily) => family;

// Used for highlighting the active tab in the left side bar
export type RouteFamily =
  | 'default'
  | 'home'
  | 'channel'
  | 'notifications'
  | 'explore'
  | 'profile'
  | 'settings'
  | 'invites'
  | 'collections'
  | 'directCasts'
  | 'search'
  | 'warps'
  | 'bookmarks';

const routes = {
  homeFeed: {
    path: '/',
    params: {},
    search: {
      mintURL: undefined,
      composerActionURL: undefined,
    } as {
      mintURL?: string;
      composerActionURL?: string;
    },
    family: family('home'),
    Component: HomeFeedPage,
  },
  following: {
    path: `${appPathPrefix}/following`,
    params: {},
    search: {},
    family: family('home'),
    Component: FollowingPage,
  },
  trending: {
    path: `${appPathPrefix}/trending`,
    params: {},
    search: {},
    family: family('home'),
    Component: TrendingPage,
  },
  channel: {
    path: `${appPathPrefix}/channel/:channelKey`,
    params: { channelKey: '' } as {
      channelKey: string;
    },
    search: {},
    family: family('channel'),
    Component: ChannelPage,
  },
  channelSettings: {
    path: `${appPathPrefix}/channel/:channelKey/settings`,
    params: { channelKey: '' } as {
      channelKey: string;
    },
    search: {},
    family: family('channel'),
    // Reuse ChannelPage so we avoid flashes due to lazy loading
    Component: ChannelPage,
  },
  channelSettingsSection: {
    path: `${appPathPrefix}/channel/:channelKey/settings/:section`,
    params: { channelKey: '', section: 'details' } as {
      channelKey: string;
      section: ChannelEditSection;
    },
    search: {},
    family: family('channel'),
    // Reuse ChannelPage so we avoid flashes due to lazy loading
    Component: ChannelPage,
  },
  channelFeed: {
    path: `${appPathPrefix}/channel/:channelKey/:tab`,
    params: { channelKey: '', tab: 'recent' } as {
      channelKey: string;
      tab: string;
    },
    search: {},
    family: family('channel'),
    Component: ChannelFeedPage,
  },
  channelFollowers: {
    path: `${appPathPrefix}/channel/:channelKey/followers`,
    params: { channelKey: '' } as {
      channelKey: string;
    },
    search: {},
    family: family('channel'),
    Component: ChannelFollowersPage,
  },
  channelMembers: {
    path: `${appPathPrefix}/channel/:channelKey/members`,
    params: { channelKey: '' } as {
      channelKey: string;
    },
    search: {},
    family: family('channel'),
    Component: ChannelMembersPage,
  },
  channelFollowersYouKnow: {
    path: `${appPathPrefix}/channel/:channelKey/followers-you-know`,
    params: { channelKey: '' } as {
      channelKey: string;
    },
    search: {},
    family: family('channel'),
    Component: ChannelFollowersYouKnowPage,
  },
  collectionOwners: {
    path: `${appPathPrefix}/collections/:collectionSlug/owners`,
    params: { collectionSlug: '' } as {
      collectionSlug: string;
    },
    search: {},
    family: family('collections'),
    Component: CollectionOwnersPage,
  },
  conversationWithoutUsername: {
    path: `${appPathPrefix}/conversations/:castHash`,
    params: { castHash: '' } as {
      castHash: string;
    },
    search: {},
    family: family('default'),
    Component: ConversationWithoutUsernamePage,
  },
  conversationReactionsWithoutUsername: {
    path: `${appPathPrefix}/conversations/:castHash/reactions`,
    params: { castHash: '' } as {
      castHash: string;
    },
    search: {},
    family: family('default'),
    Component: ConversationReactionsWithoutUsernamePage,
  },
  conversationRecastsWithoutUsername: {
    path: `${appPathPrefix}/conversations/:castHash/recasts`,
    params: { castHash: '' } as {
      castHash: string;
    },
    search: {},
    family: family('default'),
    Component: ConversationRecastsWithoutUsernamePage,
  },
  conversationQuotesWithoutUsername: {
    path: `${appPathPrefix}/conversations/:castHash/quotes`,
    params: { castHash: '' } as {
      castHash: string;
    },
    search: {},
    family: family('default'),
    Component: ConversationQuotesWithoutUsernamePage,
  },
  followersYouKnowWithoutUsername: {
    path: `${appPathPrefix}/profiles/:fid/followers-you-know`,
    params: { fid: 0 } as {
      fid: number;
    },
    search: {},
    family: family('default'),
    Component: FollowersYouKnowWithoutUsernamePage,
  },
  followersWithoutUsername: {
    path: `${appPathPrefix}/profiles/:fid/followers`,
    params: { fid: 0 } as {
      fid: number;
    },
    search: {},
    family: family('default'),
    Component: FollowersWithoutUsernamePage,
  },
  followingWithoutUsername: {
    path: `${appPathPrefix}/profiles/:fid/following`,
    params: { fid: 0 } as {
      fid: number;
    },
    search: {},
    family: family('default'),
    Component: FollowingWithoutUsernamePage,
  },
  globalFrameAnalytics: {
    path: `${appPathPrefix}/frame-analytics`,
    params: {},
    search: {},
    family: family('default'),
    Component: GlobalFrameAnalyticsPage,
  },
  profileCastsWithoutUsername: {
    path: `${appPathPrefix}/profiles/:fid`,
    params: { fid: 0 } as {
      fid: number;
    },
    search: {},
    family: family('profile'),
    Component: ProfileCastsWithoutUsernamePage,
  },
  profileCastsAndRepliesWithoutUsername: {
    path: `${appPathPrefix}/profiles/:fid/casts-and-replies`,
    params: { fid: 0 } as {
      fid: number;
    },
    search: {},
    family: family('profile'),
    Component: ProfileCastsAndRepliesWithoutUsernamePage,
  },
  profileLikesWithoutUsername: {
    path: `${appPathPrefix}/profiles/:fid/likes`,
    params: { fid: 0 } as {
      fid: number;
    },
    search: {},
    family: family('profile'),
    Component: ProfileLikesWithoutUsernamePage,
  },
  notifications: {
    path: `${appPathPrefix}/notifications`,
    params: {},
    search: {},
    family: family('notifications'),
    Component: NotificationsPage,
  },
  notificationsWithTabs: {
    path: `${appPathPrefix}/notifications/:tab`,
    params: { tab: '' } as {
      tab: string | undefined;
    },
    search: {},
    family: family('notifications'),
    Component: NotificationsPage,
  },
  notificationGroupUsers: {
    path: `${appPathPrefix}/notifications/:type/:groupId`,
    params: { groupId: '', type: 'follow' } as {
      groupId: string;
      type: ApiNotificationType;
    },
    search: { title: '' } as {
      title: string | undefined;
    },
    family: family('notifications'),
    Component: NotificationGroupUsersPage,
  },
  notificationGroupAssets: {
    path: `${appPathPrefix}/notifications/asset-event/:groupId`,
    params: { groupId: '' } as {
      groupId: string;
    },
    search: { title: '' } as {
      title: string;
    },
    family: family('notifications'),
    Component: NotificationGroupAssetsPage,
  },
  notificationGroupCasts: {
    path: `${appPathPrefix}/notifications/casts/:type`,
    params: { type: 'new-cast' } as {
      type: Extract<
        ApiNotificationType,
        | 'new-cast'
        | 'new-cast-in-channel'
        | 'unfollowed-cast-reply'
        | 'trending-cast'
        | 'channel-pinned-cast'
      >;
    },
    search: { groupId: '' } as {
      groupId: string;
    },
    family: family('notifications'),
    Component: NotificationGroupCastsPage,
  },
  notificationGroupChannelRoleInvites: {
    path: `${appPathPrefix}/notifications/channel-role-invites`,
    params: {},
    search: { groupId: '' } as {
      groupId: string;
    },
    family: family('notifications'),
    Component: NotificationGroupChannelRoleInvitesPage,
  },
  notificationGroupFrames: {
    path: `${appPathPrefix}/notifications/frames`,
    params: {},
    search: { groupId: '' } as {
      groupId: string;
    },
    family: family('notifications'),
    Component: NotificationGroupFramesPage,
  },
  directCastsInvite: {
    path: `${appPathPrefix}/group/:inviteCode`,
    params: { inviteCode: '' } as {
      inviteCode: string;
    },
    search: {},
    family: family('default'),
    Component: DirectCastsInvitePage,
  },
  directCastsCreate: {
    path: `${appInboxPathPrefix}/create/:fid`,
    params: { fid: 0 } as {
      fid: number;
    },
    search: {
      text: '',
    } as {
      text: string;
    },
    family: family('directCasts'),
    Component: DirectCastsInboxCreatePage,
  },
  directCastsInbox: {
    path: `${appInboxPathPrefix}`,
    params: {},
    search: {},
    family: family('directCasts'),
    Component: DirectCastsInboxPage,
  },
  directCastsConversation: {
    path: `${appInboxPathPrefix}/:conversationId`,
    params: { conversationId: '' } as {
      conversationId: string;
    },
    search: { text: '' } as {
      text?: string;
    },
    family: family('directCasts'),
    Component: DirectCastsConversationPage,
  },
  locationUsers: {
    path: `${appPathPrefix}/locations/:placeId`,
    params: { placeId: '' } as {
      placeId: string;
    },
    search: {},
    family: family('default'),
    Component: LocationUsersPage,
  },
  coinbaseWalletRegister: {
    path: `${appPathPrefix}/register/cbw`,
    params: {},
    search: {} as {
      paid?: boolean;
      email?: string;
    },
    family: family('default'),
    Component: CoinbaseWalletRegistrationPage,
  },
  signInWithFarcaster: {
    path: `${appPathPrefix}/sign-in-with-farcaster`,
    params: {},
    search: {} as {},
    family: family('default'),
    Component: SignInWithFarcasterPage,
  },
  signInWithFarcasterV2: {
    path: `${appPathPrefix}/siwf`,
    params: {},
    search: {} as {},
    family: family('default'),
    Component: SignInWithFarcasterPage,
  },
  settingsProfile: {
    path: appSettingsPathPrefix,
    params: {},
    search: {},
    family: family('settings'),
    Component: SettingsProfilePage,
  },
  settingsNotifications: {
    path: `${appSettingsPathPrefix}/notifications`,
    params: {},
    search: {},
    family: family('settings'),
    Component: SettingsNotificationsPage,
  },
  settingsConnectedAddresses: {
    path: `${appSettingsPathPrefix}/verified-addresses`,
    params: {},
    search: {},
    family: family('settings'),
    Component: SettingsConnectedAddressesPage,
  },
  settingsConnectedAccounts: {
    path: `${appSettingsPathPrefix}/connected-accounts`,
    params: {},
    search: {} as { success?: boolean },
    family: family('settings'),
    Component: SettingsConnectedAccountsPage,
  },
  settingsDirectCasts: {
    path: `${appSettingsPathPrefix}/direct-casts`,
    params: {},
    search: {},
    family: family('settings'),
    Component: SettingsDirectCastsPage,
  },
  settingsDirectCastsRecommended: {
    path: `${appSettingsPathPrefix}/direct-casts/recommended`,
    params: {},
    search: {},
    family: family('settings'),
    Component: SettingsDirectCastsRecommendedPage,
  },
  settingsDirectCastsOthers: {
    path: `${appSettingsPathPrefix}/direct-casts/others`,
    params: {},
    search: {},
    family: family('settings'),
    Component: SettingsDirectCastsOthersPage,
  },
  settingsActions: {
    path: `${appSettingsPathPrefix}/actions`,
    params: {},
    search: {},
    family: family('settings'),
    Component: SettingsActionsPage,
  },
  settingsCastsAndUsers: {
    path: `${appSettingsPathPrefix}/casts-and-users`,
    params: {},
    search: {},
    family: family('settings'),
    Component: SettingsCastsAndUsersPage,
  },
  settingsMutedKeywords: {
    path: `${appSettingsPathPrefix}/muted-keywords`,
    params: {},
    search: {},
    family: family('settings'),
    Component: SettingsMutedKeywordsPage,
  },
  settingsMutedAccounts: {
    path: `${appSettingsPathPrefix}/muted-accounts`,
    params: {},
    search: {},
    family: family('settings'),
    Component: SettingsMutedKeywordsPage,
  },
  settingsStarterPacks: {
    path: `${appSettingsPathPrefix}/starter-packs`,
    params: {},
    search: {},
    family: family('settings'),
    Component: SettingsStarterPacksPage,
  },
  starterPack: {
    path: `${appPathPrefix}/starter-packs/:id`,
    params: {
      id: '',
    } as {
      id: string;
    },
    search: {},
    family: family('default'),
    Component: StarterPackPage,
  },
  starterPackWithUsername: {
    path: `/:username/pack/:id`,
    params: { username: '', id: '' } as {
      username: string;
      id: string;
    },
    search: {},
    family: family('default'),
    Component: StarterPackPage,
  },
  settingsAdvanced: {
    path: `${appSettingsPathPrefix}/advanced`,
    params: {},
    search: {},
    family: family('settings'),
    Component: SettingsAdvancedPage,
  },
  download: {
    path: `${appPathPrefix}/download`,
    params: {},
    search: {},
    family: family('default'),
    Component: DownloadPage,
  },
  signup: {
    path: `${appPathPrefix}/signup`,
    params: {},
    search: {},
    family: family('default'),
    Component: DownloadPage,
  },
  bookmarks: {
    path: `${appPathPrefix}/bookmarks`,
    params: {},
    search: {},
    family: family('bookmarks'),
    Component: BookmarksPage,
  },
  warps: {
    path: `${appPathPrefix}/warps`,
    params: {},
    search: {} as {
      success?: boolean;
    },
    family: family('warps'),
    Component: WarpsPage,
  },
  manageChannelsCategory: {
    path: `${appPathPrefix}/manage-channels/:category`,
    params: {
      category: 'follow',
    } as {
      category: ApiUserChannelsCategory;
    },
    search: {},
    family: family('default'),
    Component: ManageChannelsForCategoryPage,
  },
  channels: {
    path: `${appExplorePathPrefix}/channels`,
    params: {},
    search: {},
    family: family('explore'),
    Component: ChannelsPage,
  },
  channelJoinViaCode: {
    path: `${appPathPrefix}/channel/:channelKey/join`,
    params: { channelKey: '' } as {
      channelKey: string;
    },
    search: { inviteCode: '' } as {
      inviteCode: string;
    },
    family: family('default'),
    Component: ChannelJoinViaCodePage,
  },
  app: {
    path: `${appExplorePathPrefix}/apps/:slug`,
    params: {},
    search: {},
    family: family('explore'),
    Component: AppsPage,
  },
  apps: {
    path: `${appExplorePathPrefix}/apps`,
    params: {},
    search: {},
    family: family('explore'),
    Component: AppsPage,
  },
  top: {
    path: `${appSearchPathPrefix}/top`,
    params: {},
    search: { q: '' } as {
      q: string;
    },
    family: family('search'),
    Component: TopSearchPage,
  },
  recent: {
    path: `${appSearchPathPrefix}/recent`,
    params: {},
    search: { q: '' } as {
      q: string;
    },
    family: family('search'),
    Component: RecentSearchPage,
  },
  searchChannels: {
    path: `${appSearchPathPrefix}/channels`,
    params: {},
    search: { q: '' } as {
      q: string;
    },
    family: family('search'),
    Component: ChannelsSearchPage,
  },
  searchUsers: {
    path: `${appSearchPathPrefix}/users`,
    params: {},
    search: { q: '' } as {
      q: string;
    },
    family: family('search'),
    Component: UsersSearchPage,
  },
  users: {
    path: `${appExplorePathPrefix}/users`,
    params: {},
    search: {},
    family: family('explore'),
    Component: UsersForYouPage,
  },
  compose: {
    path: `${appPathPrefix}/compose`,
    params: {},
    search: {
      text: '',
      embeds: [],
      channelKey: undefined,
      parentCastHash: undefined,
    } as {
      text: string;
      embeds: string[];
      channelKey: string | undefined;
      parentCastHash: string | undefined;
    },
    family: family('home'),
    Component: ComposePage,
  },
  mint: {
    path: `${appPathPrefix}/mint`,
    params: {},
    search: {
      url: '',
    } as {
      url: string;
    },
    family: family('default'),
    Component: MintPage,
  },
  debug: {
    path: `${appPathPrefix}/debug`,
    params: {},
    search: {},
    family: family('default'),
    Component: DebugMenuPage,
  },
  debugCasts: {
    path: `${appPathPrefix}/debug/casts`,
    params: {},
    search: {},
    family: family('default'),
    Component: DebugCastsPage,
  },
  debugFeatureFlags: {
    path: `${appPathPrefix}/debug/feature-flags`,
    params: {},
    search: {},
    family: family('default'),
    Component: DebugFeatureFlagsPage,
  },

  debugAdminToken: {
    path: `${appPathPrefix}/debug/admin-token`,
    params: {},
    search: {},
    family: family('default'),
    Component: DebugAdminTokenPage,
  },
  adminFeedsComparison: {
    path: `${appPathPrefix}/admin/feeds-comparison`,
    params: {},
    search: {},
    family: family('default'),
    Component: AdminFeedsComparisonPage,
  },
  adminReviews: {
    path: `${appPathPrefix}/admin/reviews`,
    params: {},
    search: {},
    family: family('default'),
    Component: AdminReviewsPage,
  },
  developersApiKeys: {
    path: `${appPathPrefix}/developers/api-keys`,
    params: {},
    search: {},
    family: family('default'),
    Component: ApiKeysPage,
  },
  developersEmbeds: {
    path: `${appPathPrefix}/developers/embeds`,
    params: {},
    search: {},
    family: family('default'),
    Component: DevelopersEmbedsPage,
  },
  developersFrames: {
    path: `${appPathPrefix}/developers/frames`,
    params: {},
    search: {
      url: undefined,
    } as {
      url: string | undefined;
    },
    family: family('default'),
    Component: FrameDevelopersPage,
  },
  developersFrameValidator: {
    path: `${appPathPrefix}/developers/frames-legacy`,
    params: {},
    search: {
      url: undefined,
    } as {
      url: string | undefined;
    },
    family: family('default'),
    Component: FrameValidatorPage,
  },
  developersCastActionPlayground: {
    path: `${appPathPrefix}/developers/cast-actions`,
    params: {},
    search: {} as {
      url: string | undefined;
      name: string | undefined;
      icon: string | undefined;
      postUrl: string | undefined;
      actionType: string | undefined;
    },
    family: family('default'),
    Component: CastActionPlaygroundPage,
  },
  developersComposerActionPlayground: {
    path: `${appPathPrefix}/developers/composer-actions`,
    params: {},
    search: {} as {
      name: string | undefined;
      postUrl: string | undefined;
    },
    family: family('default'),
    Component: ComposerActionPlaygroundPage,
  },
  developers: {
    path: `${appPathPrefix}/developers`,
    params: {},
    search: {},
    family: family('default'),
    Component: DevelopersPage,
  },
  support: {
    path: `${appPathPrefix}/support`,
    params: {},
    search: {},
    family: family('default'),
    Component: SupportPage,
  },
  termsOfUse: {
    path: `${appPathPrefix}/terms-of-use`,
    params: {},
    search: {},
    family: family('default'),
    Component: TermsOfUsePage,
  },
  privacyPolicy: {
    path: `${appPathPrefix}/privacy-policy`,
    params: {},
    search: {},
    family: family('default'),
    Component: PrivacyPolicyPage,
  },
  '#': {
    path: '#',
    params: {},
    search: {},
    family: family('default'),
    Component: HomeFeedPage,
  },
  addCastAction: {
    path: `${appPathPrefix}/add-cast-action`,
    params: {},
    search: {} as {
      name?: string;
      icon?: string;
      actionType?: string;
      postUrl?: string;
      url?: string;
    },
    family: family('default'),
    Component: AddCastActionPage,
  },
  discoverActions: {
    path: `${appPathPrefix}/discover-actions`,
    params: {},
    search: {} as { q?: string },
    family: family('default'),
    Component: DiscoverActionsPage,
  },
  launchComposerAction: {
    path: `${appPathPrefix}/composer-action`,
    params: {},
    search: {} as { url?: string },
    family: family('default'),
    Component: LaunchComposerActionPage,
  },

  // Vanity Routes
  // These routes must be defined at the end of the object
  // so hooks like `useCurrentRoute` – which take the first route they match –
  // don't prematurely match a vanity url where the leading symbol (e.g. `~`) is the username and
  // the path segment (e.g. `notifications`) is the `castHashPrefix`.
  // We must also adhere to this ordering in `Router` to accommodate vanity routes, unfortunately.
  conversationWithUsername: {
    path: '/:username/:castHashPrefix',
    params: { username: '', castHashPrefix: '' } as {
      username: string;
      castHashPrefix: CastHashPrefix;
    },
    search: {},
    family: family('default'),
    Component: ConversationWithUsernamePage,
  },
  conversationReactionsWithUsername: {
    path: '/:username/:castHashPrefix/reactions',
    params: { castHashPrefix: '', username: '' } as {
      castHashPrefix: CastHashPrefix;
      username: string;
    },
    search: {},
    family: family('default'),
    Component: ConversationReactionsWithUsernamePage,
  },
  conversationRecastsWithUsername: {
    path: '/:username/:castHashPrefix/recasts',
    params: { castHashPrefix: '', username: '' } as {
      castHashPrefix: CastHashPrefix;
      username: string;
    },
    search: {},
    family: family('default'),
    Component: ConversationRecastsWithUsernamePage,
  },
  conversationQuotesWithUsername: {
    path: '/:username/:castHashPrefix/quotes',
    params: { castHashPrefix: '', username: '' } as {
      castHashPrefix: CastHashPrefix;
      username: string;
    },
    search: {},
    family: family('default'),
    Component: ConversationQuotesWithUsernamePage,
  },
  profileCastsWithUsername: {
    path: '/:username',
    params: { username: '' } as {
      username: string;
    },
    search: {},
    family: family('profile'),
    Component: ProfileCastsWithUsernamePage,
  },
  profileCastsAndRepliesWithUsername: {
    path: '/:username/casts-and-replies',
    params: { username: '' } as {
      username: string;
    },
    search: {},
    family: family('profile'),
    Component: ProfileCastsAndRepliesWithUsernamePage,
  },
  profileLikesWithUsername: {
    path: '/:username/likes',
    params: { username: '' } as {
      username: string;
    },
    search: {},
    family: family('profile'),
    Component: ProfileLikesWithUsernamePage,
  },
  followersYouKnowWithUsername: {
    path: '/:username/followers-you-know',
    params: { username: '' } as {
      username: string;
    },
    search: {},
    family: family('default'),
    Component: FollowersYouKnowWithUsernamePage,
  },
  followersWithUsername: {
    path: '/:username/followers',
    params: { username: '' } as {
      username: string;
    },
    search: {},
    family: family('default'),
    Component: FollowersWithUsernamePage,
  },
  followingWithUsername: {
    path: '/:username/following',
    params: { username: '' } as {
      username: string;
    },
    search: {},
    family: family('default'),
    Component: FollowingWithUsernamePage,
  },
  recoveryStart: {
    path: '/start-recovery',
    params: {},
    search: { token: '', email: '' } as {
      token: string;
      email: string;
    },
    family: family('default'),
    Component: RecoveryStartPage,
  },
  recoveryInitiate: {
    path: `${appPathPrefix}/recovery-init`,
    params: {},
    search: {},
    family: family('default'),
    Component: RecoveryInitiatePage,
  },
  recovery: {
    path: '/recovery',
    params: {},
    search: {},
    family: family('default'),
    Component: RecoveryPage,
  },
  magicLink: {
    path: '/magic-link',
    params: {},
    search: {},
    family: family('default'),
    Component: MagicLinkPage,
  },
  signupForInvite: {
    path: `${appPathPrefix}/invite-page/:inviterFid`,
    params: { inviterFid: 0 } as {
      inviterFid: number;
    },
    search: { id: '' } as {
      id: string;
    },
    family: family('default'),
    Component: SignupForInvitePage,
  },
  claimInvite: {
    path: `${appPathPrefix}/gift-invite`,
    params: {},
    search: { id: '' } as {
      id: string;
    },
    family: family('default'),
    Component: ClaimInvitePage,
  },
  paidInvite: {
    path: `${appPathPrefix}/join`,
    params: {},
    search: { code: '' } as {
      code: string;
    },
    family: family('default'),
    Component: PaidInvitgePage,
  },

  // Catch all
  catchAll: {
    path: '*',
    params: {},
    search: {},
    family: family('default'),
    Component: HomeFeedPage,
  },
} as const;

const defaultRoute = routes.homeFeed;

const routesArray = Object.values(routes);

const redirects: {
  path: string;
  url: string;
}[] = [];

export { defaultRoute, redirects, routes, routesArray };
