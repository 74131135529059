import { FC, memo } from 'react';

import { LoadingIndicator } from '~/components/loaders/LoadingIndicator';

const FullScreenLoadingIndicator: FC = memo(() => (
  <div className="flex h-full min-h-screen w-full flex-col items-center justify-start pt-48">
    <LoadingIndicator />
  </div>
));

export { FullScreenLoadingIndicator };
