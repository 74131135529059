import amplitude from 'amplitude-js';

import { amplitudeApiKey } from '~/constants/analytics';
import { baseApiHost, baseUseHttps } from '~/constants/api';

const client = amplitude.getInstance();

let disabled = false;
let hasUserId = false;

/**
 * Track analytics with Amplitude.
 *
 * This is a singleton wrapper around analytics-js that checks if tracking is enabled and passes through method calls.
 */
const Amplitude = {
  /** Start collecting analytics if tracking is enabled */
  init: (): void => {
    if (!disabled) {
      Amplitude.enable();
    }
  },

  /** Enable tracking of analytics */
  enable: (): void => {
    disabled = false;
    client.init(amplitudeApiKey, undefined, {
      apiEndpoint: `${baseApiHost}/v2/amp/api`,
      forceHttps: baseUseHttps,
    });
    client.setOptOut(false);
  },

  /** Disable tracking of analytics */
  disable: (): void => {
    disabled = true;
    client.setOptOut(true);
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  logEvent: (
    event: string,
    data?: unknown,
    callback?: amplitude.Callback | undefined,
  ): void => {
    if (!disabled && hasUserId) {
      client.logEvent(event, data, callback);
    }
  },

  // We are allowing this as we have some specific flows where we really want
  // to analyze and the event count should be really low.
  // We will re-think our strategy here later by either upgrading Amplitude,
  // or using Fathom more.
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  dangerouslyLogPossiblyPreAuthEvent: (
    event: string,
    data?: unknown,
    callback?: amplitude.Callback | undefined,
  ): void => {
    if (!disabled) {
      client.logEvent(event, data, callback);
    }
  },

  setUserId: (userId: string | null): void => {
    if (!disabled) {
      client.setUserId(userId);
      hasUserId = userId !== null;
    }
  },

  identify: (
    identify: amplitude.Identify,
    callback?: amplitude.Callback | undefined,
  ): void => {
    if (!disabled) {
      client.identify(identify, callback);
    }
  },

  setUserProperties: ({
    username,
    version,
    appVersion,
  }: {
    username: string | undefined;
    version: string;
    appVersion: string;
  }): void => {
    if (!disabled) {
      client.setUserProperties({
        username,
        app_version: appVersion,
        warpcast_version: version,
        // UPDATE 2023-10: Removed warpcast_platform as its confusing others.
        // Use Amplitude's own Platform value for checking events
      });
    }
  },
};

Object.freeze(Amplitude);
export { Amplitude };
