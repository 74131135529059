import React, {
  createContext,
  FC,
  memo,
  ReactNode,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';

/*
The way this context works is that it maintains a queue of video URLs that should be auto-played
and provides as a state the URL of the video that is allowed to play (= active).
- Videos should run effects on activeVideoUrl and compare with their own url to start/stop playing
- Videos should enqueue themselves with enqueueVideoForAutoplay when coming into view, ideally
  in vertical order
- Videos should dequeue themselves with dequeueVideoFromAutoplay when going out of view or moving
  to manual mode (after user interaction)
- When a user manually plays a video, forcePlayVideo should be called which will change the active
  video (which should then stop itself). When a manually played video is stopped,
  dequeueVideoFromAutoplay should be called to remove it from the queue and switch the active
  video to the first in the queue.
*/
export type VideoPlayStatusContextType = {
  activeVideoUrl: string;
  enqueueVideoForAutoplay: (url: string) => void;
  dequeueVideoFromAutoplay: (url: string) => void;
  forcePlayVideo: (url: string) => void;
};

const VidePlayStatusContext = createContext<VideoPlayStatusContextType>({
  activeVideoUrl: '',
  enqueueVideoForAutoplay: () => {},
  dequeueVideoFromAutoplay: () => {},
  forcePlayVideo: () => {},
});

export type VideoPlayStatusProviderProps = {
  children: ReactNode;
};

const VideoPlayStatusProvider: FC<VideoPlayStatusProviderProps> = memo(
  ({ children }) => {
    const queueRef = useRef<string[]>([]);
    const activeVideoUrlRef = useRef('');
    const [activeVideoUrl, setActiveVideoUrl] = useState('');

    const recalculateState = useCallback(() => {
      if (!queueRef.current.includes(activeVideoUrlRef.current)) {
        if (queueRef.current.length > 0) {
          const url = queueRef.current[0];
          activeVideoUrlRef.current = url;
          setActiveVideoUrl(url);
        } else if (activeVideoUrlRef.current !== '') {
          activeVideoUrlRef.current = '';
          setActiveVideoUrl('');
        }
      }
    }, []);

    const enqueueVideoForAutoplay = useCallback(
      (url: string) => {
        if (!queueRef.current.includes(url)) {
          queueRef.current.push(url);
          recalculateState();
        }
      },
      [recalculateState],
    );

    const dequeueVideoFromAutoplay = useCallback(
      (url: string) => {
        const index = queueRef.current.indexOf(url);
        if (index !== -1) {
          queueRef.current.splice(index, 1);
          recalculateState();
        }
      },
      [recalculateState],
    );

    const forcePlayVideo = useCallback((url: string) => {
      if (!queueRef.current.includes(url)) {
        queueRef.current.push(url);
      }
      if (activeVideoUrlRef.current !== url) {
        activeVideoUrlRef.current = url;
        setActiveVideoUrl(url);
      }
    }, []);

    return (
      <VidePlayStatusContext.Provider
        value={{
          activeVideoUrl,
          enqueueVideoForAutoplay,
          dequeueVideoFromAutoplay,
          forcePlayVideo,
        }}
      >
        {children}
      </VidePlayStatusContext.Provider>
    );
  },
);

VideoPlayStatusProvider.displayName = 'VideoPlayStatusProvider';

const useVideoPlayStatus = () => useContext(VidePlayStatusContext);

export { useVideoPlayStatus, VideoPlayStatusProvider };
