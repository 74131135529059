import { FC, memo, ReactNode, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { RemoveScrollBar } from 'react-remove-scroll-bar';

import { modalRootId } from '~/constants/modals';

type ModalProps = {
  children: ReactNode;
};

const Modal: FC<ModalProps> = memo(({ children }) => {
  useEffect(() => {
    document.documentElement.classList.add('modal-open');
    return () => document.documentElement.classList.remove('modal-open');
  }, []);

  return createPortal(
    <>
      {children}
      <RemoveScrollBar gapMode="margin" />
    </>,
    document.getElementById(modalRootId)!,
  );
});

Modal.displayName = 'Modal';

export { Modal };
