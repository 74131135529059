import { useCallback } from 'react';

import { useTrackEvent } from '../../../providers';
import { useFarcasterApiClient } from '../../../providers/FarcasterApiClientProvider';
import { SharedAmpEvent } from '../../../utils';
import { useOptimisticallyUpdateChannel } from '../optimistic/useOptimisticallyUpdateChannel';
import { useRefetchFeedSummaries } from '../queries';

const useAddFavoriteFeed = () => {
  const { apiClient } = useFarcasterApiClient();
  const refetchFeedSummaries = useRefetchFeedSummaries();
  const optimisticallyUpdateFeed = useOptimisticallyUpdateChannel();
  const { trackEvent } = useTrackEvent();

  return useCallback(
    async ({ feedKey }: { feedKey: string }) => {
      const revertOptimisticUpdates = optimisticallyUpdateFeed({
        updates: {
          key: feedKey,
          viewerContext: {
            favoritePosition: 1000,
          },
        },
        revertUpdates: {
          key: feedKey,
          viewerContext: {
            favoritePosition: -1,
          },
        },
      });

      try {
        trackEvent(SharedAmpEvent.ToggleChannelFavorite, {
          toggle: 'on',
          channelKey: feedKey,
        });

        await apiClient.addFavoriteFeed({ feedKey });
        setTimeout(() => {
          refetchFeedSummaries();
        }, 1000);
      } catch (error) {
        revertOptimisticUpdates();
        throw error;
      }
    },
    [apiClient, optimisticallyUpdateFeed, refetchFeedSummaries, trackEvent],
  );
};

export { useAddFavoriteFeed };
