/* eslint-disable @next/next/no-img-element */
import {
  DetailedHTMLProps,
  FC,
  ImgHTMLAttributes,
  memo,
  useCallback,
  useState,
} from 'react';

export type ImageProps = DetailedHTMLProps<
  ImgHTMLAttributes<{}>,
  HTMLImageElement
> & {
  alt: string;
  fallback?: string;
  src: string;
};

const Image: FC<ImageProps> = memo(
  ({ fallback, src, loading = 'lazy', ...props }) => {
    const [didFailToLoadMap, setDidFailToLoadMap] = useState<
      Record<string, boolean | undefined>
    >({});

    const resolvedSrc = didFailToLoadMap[src] && fallback ? fallback : src;

    const onError = useCallback(() => {
      setDidFailToLoadMap((prevMap) => ({ ...prevMap, [src]: true }));

      if (props.onError) {
        props.onError;
      }
    }, [props.onError, src]);

    return (
      // eslint-disable-next-line jsx-a11y/alt-text
      <img loading={loading} src={resolvedSrc} onError={onError} {...props} />
    );
  },
);

Image.displayName = 'Image';

export { Image };
