import {
  ApiGetOnboardingState200Response,
  ApiOnboardingState,
} from 'farcaster-client-data';
import { useRef } from 'react';

const useFallbackOnboardingState = (): ApiGetOnboardingState200Response => {
  return useRef({
    result: {
      state: {
        email: undefined,
        user: undefined,
        handledConnectAddress: false,
        canRegisterUsername: false,
        hasOnboarding: false,
        needsRegistrationPayment: true,
        hasFid: false,
        hasFname: false,
        hasPhone: false,
        needsPhone: true,
        hasConfirmedEmail: false,
        hasDelegatedSigner: false,
        hasStorage: false,
        hasCompletedRegistration: false,
        hasSetupProfile: false,
        handledPushNotificationsNudge: false,
        handledContactsNudge: false,
        handledInterestsNudge: false,
        hasValidPaidInvite: false,
        sponsoredRegisterEligible: false,
      } satisfies ApiOnboardingState,
    },
  }).current;
};

export { useFallbackOnboardingState };
