export enum SharedAmpEvent {
  AckExerciseCaution = 'ack exercise caution',
  AddCastAction = 'add cast action',
  AddComposerActionEmbed = 'add composer action embed',
  BlowfishErrorRetry = 'blowfish error retry',
  ClickApplyAdvancedSearchFilters = 'click apply advanced search filters',
  ClickBanUserFromChannel = 'click ban user from channel',
  ClickUnbanUserFromChannel = 'click unban user from channel',
  ClickBlock = 'click block', // correspond to confirming the action not triggering the prompt
  ClickBoostCast = 'click boost cast',
  ClickCast = 'click cast',
  ClickCastShareableImage = 'click cast shareable image',
  ClickChannelFollowersYouKnow = 'click channel followers you know',
  ClickChannelLead = 'click channel lead',
  ClickComposerAction = 'click composer action',
  ClickConnectWalletCancel = 'click connect wallet cancel',
  ClickConnectWalletContinue = 'click connect wallet continue',
  ClickCreateGiftInvite = 'click create gift invite',
  ClickDiscoverActions = 'click discover actions',
  ClickExploreAction = 'click explore action',
  ClickExploreApp = 'click explore app',
  ClickExploreFrame = 'click explore frame',
  ClickExploreSpotlight = 'click explore spotlight',
  ClickFollowersYouKnow = 'click followers you know',
  ClickFrameButton = 'click frame button',
  ClickFramePreview = 'click frame preview',
  ClickHideCast = 'click hide cast',
  ClickInviteUser = 'click invite user',
  ClickMute = 'click mute', // correspond to confirming the action not triggering the prompt
  ClickNewCastsToast = 'click new casts toast',
  ClickNotification = 'click notification',
  ClickGroupedNotification = 'click grouped notification',
  ClickPayUser = 'click pay user', // top-level pay button clicked to start flow
  ClickPayUserContinueInWallet = 'click pay user continue in wallet',
  ClickPayUserNudgeForAddress = 'click pay user nudge for address',
  ClickPayUserWithProvider = 'click pay user with provider ',
  ClickPinCast = 'click pin cast',
  ClickProfileChannelStreak = 'click profile channel streak',
  ClickProfileChannelStreakNavigateToChannel = 'click profile channel streak navigate to channel',
  ClickRepliedDirectCast = 'click replied direct cast',
  ClickScrollDownDirectCasts = 'click scroll down direct casts',
  ClickSearchResult = 'click search result',
  ClickShareGiftInvite = 'click share gift invite',
  ClickCopyGiftInviteLink = 'click copy gift invite link',
  ClickShowFewerLikeThis = 'click show fewer like this',
  ClickShowHiddenReplies = 'click show hidden replies',
  ClickStartAdvancedSearch = 'click start advanced search',
  ClickTxnCancel = 'click txn cancel',
  ClickTxnContinueComplete = 'click txn continue complete',
  ClickTxnErrorDismiss = 'click txn error dismiss',
  ClickTxnErrorTryAgain = 'click txn error try again',
  ClickTxnWarningCancel = 'click txn warning cancel',
  ClickTxnWarningContinue = 'click txn warning continue',
  ClickUnpinCast = 'click unpin cast',
  ClickViewTippedCast = 'click view tipped cast',
  ClickUnblock = 'click unblock',
  ClickUnmute = 'click unmute',
  ClickWarnAndHideCast = 'click warn and hide cast',
  ClickWarnCast = 'click warn cast',
  CompletePayUserTransaction = 'complete pay user transaction',
  ContinueInWalletFrameTx = 'continue in wallet frame tx',
  CreateChannel = 'create channel',
  DeclineExerciseCaution = 'decline exercise caution',
  DeleteDirectCastConversation = 'delete direct cast conversation',
  DismissNuxFollowInstructions = 'dismiss nux follow instructions',
  DismissNuxFollowsBanner = 'dismiss nux follows banner',
  DismissUserRecommendation = 'dismiss user recommendation',
  EditChannelOwner = 'edit channel owner',
  ExpandPfp = 'expand pfp',
  FavoriteFeed = 'favorite feed',
  FollowChannel = 'follow channel',
  FollowUser = 'follow user',
  FollowUserRecommendation = 'follow user recommendation',
  FrameTxError = 'frame tx error',
  FrameTxSuccess = 'frame tx success',
  LongPressRecast = 'long press recast',
  MuteKeyword = 'mute keyword',
  OpenCastActions = 'open cast actions',
  PayToCastInChannel = 'pay to cast in channel',
  PinDirectCastMessage = 'pin direct cast message',
  PlayVideo = 'play video',
  PressCastNowChannelStreakPrompt = 'press cast now on channel streaks prompt',
  PressGoToChannelChannelStreakPrompt = 'press go to channel on channel streaks prompt',
  PressLetsGoChannelStreaksPrompt = 'press lets go channel streaks prompt',
  PressNotInterestedChannelStreakPrompt = 'press not interested on channel streaks prompt',
  PressSelectChannelChannelStreakPrompt = 'press select channel on channel streaks prompt',
  PressUserBoostCastNow = 'press cast now on boost info',
  ReactToCast = 'react to cast',
  RemoveCastAction = 'remove cast action',
  SeeMoreUserRecommendations = 'see more user recomendations',
  SelectComposerRowSuggestedChannel = 'select composer row suggested channel',
  SetSettingChannelCastsShown = 'set setting channel casts shown',
  SetSettingConversationRepliesShown = 'set setting conversation replies shown',
  SetSettingNotificationsGrouping = 'set setting notifications grouping',
  ShareCast = 'share cast',
  ShareCastCopy = 'share cast via copy',
  ShareCastDirectCast = 'share cast via direct cast',
  ShareCastMenu = 'share cast via menu',
  ShareCastSelectGroupConvo = 'share cast select group convo',
  ShareCastSelectUser = 'share cast select user',
  ShowCastInfo = 'show cast info',
  ShowRecastOrQuoteCastPrompt = 'show recast or quote cast prompt',
  ShowShareCastPrompt = 'show share cast prompt',
  ShowStartChannelStreaksPrompt = 'show start channel streaks prompt',
  StartFrameTx = 'start frame tx',
  SubmitInvite = 'submit invite',
  UnfavoriteFeed = 'unfavorite feed',
  UnmuteKeyword = 'unmute keyword',
  UnpinDirectCastMessage = 'unpin direct cast message',
  UnpinDirectCastMessageFromHeader = 'unpin direct cast message from header',
  UpdateUsername = 'update username',
  ViewBookmarks = 'view bookmarks',
  ViewChannel = 'view channel',
  ViewCreatorRewardInExplorer = 'view creator reward in explorer',
  ViewComposerActionForm = 'view composer action form',
  ViewConnectWallet = 'view connect wallet',
  ViewExploreActionsPage = 'view explore actions page',
  ViewExploreAppDetailsPage = 'view explore app details page',
  ViewExploreAppsPage = 'view explore apps page',
  ViewExploreFarcasterPage = 'view explore farcaster page',
  ViewExploreFramesPage = 'view explore frames page',
  ViewFramesHome = 'view frames home',
  ViewFeedHighlights = 'view feed highlights',
  ViewFeedFollowing = 'view feed following',
  ViewFeedHome = 'view feed home',
  ViewFeedNfts = 'view feed nfts',
  ViewGiftInvitePage = 'view gift invite page',
  ViewInvitePage = 'view invite page',
  ViewNotification = 'view notification',
  ViewNotifications = 'view notifications',
  ViewNuxFollowInstructions = 'view nux follow instructions',
  ViewPayUserNoAddress = 'view pay user no address',
  ViewPayUserOtherOptions = 'view pay user other options',
  ViewTxnError = 'view txn error',
  ViewTxnPreview = 'view txn preview',
  ViewTxnSent = 'view txn sent',
  ViewTxnWarning = 'view txn warning',
  AddChannelsToGiftInvite = 'add channels to gift invite',
  CopyChannelInviteLink = 'copy channel invite link',
  ResetChannelInviteLink = 'reset channel invite link',
  ToggleChannelFavorite = 'toggle channel favorite',
  ToggleChannelNotifications = 'toggle channel notifications',
  ToggleChannelFollow = 'toggle channel follow',
  ShareChannel = 'share channel',
  ViewJoinChannelViaInviteCode = 'view join channel via invite code',
  JoinChannelViaInviteCode = 'join channel via invite code',
  ViewJoinChannelAccessRestricted = 'view join channel access restricted',
  InviteToChannelRole = 'invite to channel role',
  RemoveFromChannelRole = 'remove from channel role',
  ClickDirectCastAnnouncementCTA = 'click direct cast announcement cta',
  ClickChannelHeaderAction = 'click channel header action',
  ClickManageChannelsOnNav = 'click manage channels on nav',
  ViewManageChannels = 'view manage channels',
  ViewManageChannelsCategory = 'view manage channels category',
  ClickManageChannelsCategorySeeAll = 'click manage channels category see all',
  SendUserToXToAuth = 'send user to x to auth',
  PressUserProfileXAccount = 'press user profile x account',
  PressDisconnectXAccount = 'press disconnect x account',
  PressViewHomeOnTrending = 'press view home on trending',
  ClickUnpinCastFromProfile = 'click unpin cast from profile',
  ClickPinCastToProfile = 'click pin cast to profile',
  ViewMutedAccounts = 'view muted accounts',
  ViewStarterPacks = 'view starter packs',
  ViewStarterPack = 'view starter pack',
  PressStarterPackOnStarterPacks = 'press starter pack on starter packs',
  PressCreateStarterPackOnStarterPacks = 'press create starter pack on starter packs',
  PressStarterPackOnFeed = 'press starter pack cast embed',
  PressStarterPackOnProfileFeed = 'press starter pack on profile',
  ViewCreateStarterPack = 'view create starter pack',
  ViewEditStarterPack = 'view edit starter pack',
  CreateStarterPack = 'create starter pack',
  DeleteStarterPack = 'delete starter pack',
  PressStarterPackWizardContinue = 'press starter pack wizard continue',
  ViewStarterPackCreatedModal = 'view starter pack created modal',
  PressShareStarterPack = 'press share starter pack',
  PressFollowAllStarterPack = 'press follow all starter pack',
  UpdateStarterPack = 'update starter pack',
  LaunchFrame = 'launch frame',
  RequestFrameEthTransaction = 'request frame eth transaction',
  ConfirmFrameEthTransaction = 'confirm frame eth transaction',
  RequestFrameEthSignature = 'request frame eth signature',
  ConfirmFrameEthSignature = 'confirm frame eth signature',
  AddFavoriteFrame = 'add favorite frame',
  RemoveFavoriteFrame = 'remove favorite frame',
  EnableFrameNotifications = 'enable frame notifications',
  DisableFrameNotifications = 'disable frame notifications',
  ClickFrameAuthor = 'click frame author',
}
