import process from 'process';

const env = process.env.NODE_ENV;
const isDev = env === 'development';
const isProd = !isDev;

// eslint-disable-next-line turbo/no-undeclared-env-vars
const releaseId = process.env.RELEASE_ID || 'dev'; // Replaced at build time. See craco.config.js

export { env, isDev, isProd, releaseId };
