import React, { FC } from 'react';

interface RedirectProps {
  url: string;
}

export const Redirect: FC<RedirectProps> = ({ url }) => {
  React.useEffect(() => {
    window.location.replace(url);
  }, [url]);

  return null;
};
