import { datadogRum } from '@datadog/browser-rum';
import {
  // eslint-disable-next-line no-restricted-imports
  useNonSuspenseUserAppContext,
  useUserByFid,
} from 'farcaster-client-hooks';
import { FC, memo, ReactNode, useEffect, useRef } from 'react';

import { appVersion } from '~/constants/version';
import { useCurrentUser } from '~/hooks/data/useCurrentUser';
import { useIsAdmin } from '~/hooks/data/useIsAdmin';
import { Amplitude } from '~/utils/analyticsUtils';

type EnsureCachedCurrentUserProps = {
  children: ReactNode;
};

// Add users to the array if you need to get full sampling from their device
// for debugging for a while. Eventually this should come from the backend
// so we are able to dynamically manage this.
const MANUALLY_MAINTAINED_HIGH_SAMPLING_RATE_FIDS = [420540, 248216];

const EnsureCachedCurrentUser: FC<EnsureCachedCurrentUserProps> = memo(
  ({ children }) => {
    const isAdmin = useIsAdmin();
    const user = useCurrentUser(); // Fetch + cache the current user
    // Don't block the UI from loading.
    const { data: userAppContext } = useNonSuspenseUserAppContext({});
    const { data: userAddiontalData } = useUserByFid({
      fid: user?.fid || 0,
    });

    const lastUserFidAmpRef = useRef<number>();
    const lastAppVersionAmpRef = useRef<string>();

    const lastUserFidDdRef = useRef<number>();
    const lastAppVersionDdRef = useRef<string>();

    useEffect(() => {
      if (!user) {
        Amplitude.setUserId(null);
        return;
      }

      if (
        user.fid !== lastUserFidAmpRef.current ||
        appVersion !== lastAppVersionAmpRef.current
      ) {
        lastUserFidAmpRef.current = user.fid;
        lastAppVersionAmpRef.current = appVersion;

        Amplitude.setUserId(user.fid.toString());
        Amplitude.setUserProperties({
          username: user.username,
          version: appVersion,
          appVersion,
        });
      }
    }, [user]);

    useEffect(() => {
      if (!user || !userAppContext || !userAddiontalData) {
        return;
      }

      if (
        user.fid !== lastUserFidDdRef.current ||
        appVersion !== lastAppVersionDdRef.current
      ) {
        lastUserFidDdRef.current = user.fid;
        lastAppVersionDdRef.current = appVersion;

        const id = String(user.fid);
        const higherSamplingRateEnabled =
          userAppContext.higherClientEventSamplingRateEnabled;

        const userProps = {
          id,
          name: user.username,
          fid: user.fid,
          cohort: higherSamplingRateEnabled ? 'in_us' : 'default',
        };
        datadogRum.setUser(userProps);

        if (
          window.localStorage !== null &&
          typeof window.localStorage !== 'undefined' &&
          (isAdmin ||
            MANUALLY_MAINTAINED_HIGH_SAMPLING_RATE_FIDS.indexOf(user.fid) !==
              -1 ||
            higherSamplingRateEnabled)
        ) {
          window.localStorage.setItem('dd-sampling-rate', '100');
        }
      }
    }, [user, isAdmin, userAppContext, userAddiontalData]);

    return <>{children}</>;
  },
);

EnsureCachedCurrentUser.displayName = 'EnsureCachedCurrentUser';

export { EnsureCachedCurrentUser };
