import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { useFarcasterApiClient } from '../../../../providers';
import { OnboardingStateCache } from '../../../../types';
import { useMergeIntoGloballyCachedUser } from '../globallyCachedUser/useMergeIntoGloballyCachedUser';
import { buildOnboardingStateFetcher } from './buildOnboardingStateFetcher';
import { buildOnboardingStateKey } from './buildOnboardingStateKey';

const useRefreshOnboardingState = () => {
  const { apiClient } = useFarcasterApiClient();
  const queryClient = useQueryClient();
  const mergeIntoGloballyCachedUser = useMergeIntoGloballyCachedUser();

  return useCallback(async () => {
    const response = await buildOnboardingStateFetcher({
      apiClient,
      mergeIntoGloballyCachedUser,
    })();
    queryClient.setQueryData<OnboardingStateCache>(
      buildOnboardingStateKey(),
      response,
    );
    return response;
  }, [apiClient, queryClient, mergeIntoGloballyCachedUser]);
};

export { useRefreshOnboardingState };
