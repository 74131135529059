import { ApiSyncChannelMessage, AuthToken } from 'farcaster-client-data';
import { KeyTransport } from 'farcaster-cryptography';
import { useCallback } from 'react';

const useDecryptAuthToken = () => {
  return useCallback(
    async ({
      channelId,
      message,
      transport,
    }: {
      channelId: string;
      message: ApiSyncChannelMessage;
      transport: KeyTransport;
    }) => {
      const base64AuthToken = await transport!.handleSyncMessage({
        ...message,
        channelId,
      });
      const authToken = JSON.parse(
        Buffer.from(base64AuthToken as string, 'base64').toString('utf8'),
      ) as AuthToken;

      return authToken;
    },
    [],
  );
};

export { useDecryptAuthToken };
