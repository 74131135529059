import { usePrefetchFeedItems } from 'farcaster-client-hooks';
import { useCallback } from 'react';

import { useCheckIfShouldSkipOptimisticPrefetch } from '~/hooks/data/useCheckIfShouldSkipOptimisticPrefetch';

const useOptimisticallyPrefetchFeed = () => {
  const checkIfShouldSkipOptimisticPrefetch =
    useCheckIfShouldSkipOptimisticPrefetch();

  const prefetchFeedItems = usePrefetchFeedItems();

  const onNullFeedItemsResponse = useCallback(() => {
    // FIXME: Fill this out if we notice issues on web clients similar to Web
  }, []);

  return useCallback(
    ({ feedKey }: { feedKey: string }) => {
      if (checkIfShouldSkipOptimisticPrefetch()) {
        return;
      }

      prefetchFeedItems({
        feedKey,
        feedType: 'default',
        updateState: false,
        onNullFeedItemsResponse: onNullFeedItemsResponse,
      });
    },
    [
      checkIfShouldSkipOptimisticPrefetch,
      prefetchFeedItems,
      onNullFeedItemsResponse,
    ],
  );
};

export { useOptimisticallyPrefetchFeed };
