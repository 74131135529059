import { useCallback } from 'react';

import { useTrackEvent } from '../../providers/EventingProvider';
import { SharedAmpEvent, StatsigAction } from '../../utils';

export enum CastClickType {
  Author = 'author',
  Cast = 'cast',
  ChannelMention = 'channel mention',
  ExtLink = 'ext link',
  FollowAuthor = 'follow author',
  Frame = 'frame',
  FrameButton = 'frame button',
  Image = 'image',
  IntLink = 'int link',
  Mention = 'mention',
  Mint = 'mint',
  QuotedCast = 'quoted cast',
  Reply = 'reply',
  ShareDirectCast = 'share dc',
  ShareImage = 'share image',
  ShareLink = 'share link',
  ShareNative = 'share native',
  VideoUnmute = 'video unmute',
  VideoFullscreen = 'video fullscreen',
}

type CastClickData = {
  castHash?: string;
  type: CastClickType;
};

export const useTrackCastClick = () => {
  const {
    trackEvent,
    trackStatsigEvent,
    trackUrgentInternalEvent,
    defaultEventProps,
  } = useTrackEvent();

  return useCallback(
    (data: CastClickData) => {
      const castHash = data.castHash ?? defaultEventProps.castHash;
      if (castHash) {
        // Track with urgency so that we have a higher chance of capturing if user quickly closes app or refreshes
        trackUrgentInternalEvent(
          {
            type: 'cast-interaction',
            data: { subType: `click-${data.type.replace(' ', '-')}`, castHash },
          },
          // Track a view event in case the user quickly clicked or cast was only partially visibile
          {
            type: 'cast-view',
            data: { castHash: castHash },
          },
        );
      }

      trackEvent(SharedAmpEvent.ClickCast, {
        type: data.type,
      });

      if (data.type === CastClickType.Author) {
        trackStatsigEvent({ action: StatsigAction.ClickCastAuthor });
      } else if (data.type === CastClickType.Cast) {
        trackStatsigEvent({ action: StatsigAction.ClickCast });
      } else if (data.type === CastClickType.ChannelMention) {
        trackStatsigEvent({ action: StatsigAction.ClickCastChannelMention });
      } else if (data.type === CastClickType.ExtLink) {
        trackStatsigEvent({ action: StatsigAction.ClickCastExtLink });
      } else if (data.type === CastClickType.FollowAuthor) {
        trackStatsigEvent({ action: StatsigAction.ClickCastFollowAuthor });
      } else if (data.type === CastClickType.Frame) {
        trackStatsigEvent({ action: StatsigAction.ClickCastFrame });
      } else if (data.type === CastClickType.FrameButton) {
        trackStatsigEvent({ action: StatsigAction.ClickCastFrameButton });
      } else if (data.type === CastClickType.Image) {
        trackStatsigEvent({ action: StatsigAction.ClickCastImage });
      } else if (data.type === CastClickType.IntLink) {
        trackStatsigEvent({ action: StatsigAction.ClickCastIntLink });
      } else if (data.type === CastClickType.Mention) {
        trackStatsigEvent({ action: StatsigAction.ClickCastMention });
      } else if (data.type === CastClickType.Mint) {
        trackStatsigEvent({ action: StatsigAction.ClickCastMint });
      } else if (data.type === CastClickType.QuotedCast) {
        trackStatsigEvent({ action: StatsigAction.ClickCastQuotedCast });
      } else if (data.type === CastClickType.Reply) {
        trackStatsigEvent({ action: StatsigAction.ClickCastReply });
      } else if (data.type === CastClickType.ShareDirectCast) {
        trackStatsigEvent({ action: StatsigAction.ClickCastShareDirectCast });
      } else if (data.type === CastClickType.ShareImage) {
        trackStatsigEvent({ action: StatsigAction.ClickCastShareImage });
      } else if (data.type === CastClickType.ShareLink) {
        trackStatsigEvent({ action: StatsigAction.ClickCastShareLink });
      } else if (data.type === CastClickType.ShareNative) {
        trackStatsigEvent({ action: StatsigAction.ClickCastShareNative });
      } else if (data.type === CastClickType.VideoFullscreen) {
        trackStatsigEvent({ action: StatsigAction.ClickCastVideoFullscreen });
      } else if (data.type === CastClickType.VideoUnmute) {
        trackStatsigEvent({ action: StatsigAction.ClickCastVideoUnmute });
      }
    },
    [
      defaultEventProps.castHash,
      trackStatsigEvent,
      trackEvent,
      trackUrgentInternalEvent,
    ],
  );
};
