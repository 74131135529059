import { datadogRum } from '@datadog/browser-rum';
import { isFarcasterApiError } from 'farcaster-client-data';

import { logError } from '~/utils/logUtils';

const getErrorDescription = (error: unknown) => {
  if (isFarcasterApiError(error)) {
    if (error.isNetworkError) {
      return 'Could not reach Warpcast. Check your connection.';
    }
    if (error.hasTimedOut) {
      return 'Receiving data from Warpcast is taking too long. Check your connection.';
    }

    return 'Error retrieving data. Please try again.';
  }

  return 'We encountered an unexpected error.';
};

const trackError = (error: unknown) => {
  if (process.env.NODE_ENV === 'development') {
    logError(error);
  }

  try {
    if (typeof datadogRum.addError === 'function') {
      datadogRum.addError(error);
    }
  } catch {
    // Some browsers may disable error tracking, resulting in 500s.
    // Not much we can do here - lets avoid failing the whole calling stack.
  }
};

export { getErrorDescription, trackError };
