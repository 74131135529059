import {
  FetchNextPageOptions,
  InfiniteData,
  InfiniteQueryObserverResult,
} from '@tanstack/react-query';
import {
  ApiDirectCastConversationFilter,
  ApiDirectCastConversationMessageTTLDays,
  ApiDirectCastInboxConversationInfoV3,
  ApiGetDirectCastInbox200Response,
} from 'farcaster-client-data';
import {
  useDirectCastInboxByAccount,
  useInvalidateDirectCastInboxByAccount,
  useOptimisticallyAddNewDirectCastMessage,
  useOptimisticallyApplyConversationMessageTTL,
  useUnseen,
  useWebSockets,
} from 'farcaster-client-hooks';
import React, {
  createContext,
  FC,
  memo,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useCurrentUser } from '~/hooks/data/useCurrentUser';

// Changing this to be every 5 minutes
const defaultRefreshUpdatesInterval = 300 * 1000;

export type PossiblyOptimisticDirectCast =
  ApiDirectCastInboxConversationInfoV3 & {
    optimistic?: boolean;
  };

type DirectCastsContextValue = {
  isInitializing: boolean;
  conversations: {
    [conversationId: string]: PossiblyOptimisticDirectCast | undefined;
  };
  fetchNextPage: (
    options?: FetchNextPageOptions,
  ) => Promise<
    InfiniteQueryObserverResult<
      InfiniteData<ApiGetDirectCastInbox200Response>,
      unknown
    >
  >;
  isLoading: boolean;
  filter: ApiDirectCastConversationFilter | undefined;
  setFilter: (filter: ApiDirectCastConversationFilter | undefined) => void;
  hasArchived: boolean;
  requestsCount: number;
  hasUnreadRequests: boolean;
  isEmpty: boolean;
};

const DirectCastsContext = createContext<DirectCastsContextValue>({
  isInitializing: true,
  conversations: {},
  fetchNextPage: () => null as never,
  isLoading: true,
  setFilter: () => undefined,
  filter: undefined,
  hasArchived: true,
  requestsCount: 0,
  isEmpty: true,
  hasUnreadRequests: false,
});

type DirectCastsProviderProps = {
  children: ReactNode;
};

const DirectCastsProvider: FC<DirectCastsProviderProps> = memo(
  ({ children }) => {
    const currentUser = useCurrentUser();

    const { inboxCount } = useUnseen();

    const [isInitializing, setIsInitializing] = useState<boolean>(true);

    const [optimisticConversations, setOptimisticConversations] = useState<
      PossiblyOptimisticDirectCast[]
    >([]);

    const optimisticallyApplyConversationMessageTTL =
      useOptimisticallyApplyConversationMessageTTL();

    const [filter, setFilter] = useState<
      ApiDirectCastConversationFilter | undefined
    >(undefined);

    const { data, fetchNextPage, refetch, isPending } =
      useDirectCastInboxByAccount({
        fid: currentUser.fid,
        category: 'default',
        filter,
      });

    const hasArchived = useMemo(() => {
      if (data?.pages && data.pages.length > 0) {
        return data.pages[data.pages.length - 1].result.hasArchived;
      }

      return false;
    }, [data?.pages]);

    const requestsCount = useMemo(() => {
      if (data?.pages && data.pages.length > 0) {
        return data.pages[data.pages.length - 1].result.requestsCount;
      }

      return 0;
    }, [data?.pages]);

    const hasUnreadRequests = useMemo(() => {
      if (data?.pages && data.pages.length > 0) {
        return data.pages[data.pages.length - 1].result.hasUnreadRequests;
      }

      return false;
    }, [data?.pages]);

    const outdatedOptimistic = useMemo(() => {
      if (!optimisticConversations.length || !data?.pages.length) {
        return [];
      }

      return data.pages
        .flatMap((p) => p.result.conversations)
        .filter((c) =>
          optimisticConversations.find(
            (o) =>
              o.conversationId === c.conversationId &&
              ((o.lastMessage?.serverTimestamp || 0) <
                (c.lastMessage?.serverTimestamp || 0) ||
                (o.lastMessage?.messageId || '') ===
                  (c.lastMessage?.messageId || '')),
          ),
        );
    }, [data?.pages, optimisticConversations]);

    useEffect(() => {
      if (outdatedOptimistic.length > 0) {
        setOptimisticConversations((prev) =>
          prev.filter(
            (o) =>
              !outdatedOptimistic.find(
                (outdated) => outdated.conversationId === o.conversationId,
              ),
          ),
        );
      }
    }, [outdatedOptimistic]);

    const invalidateDirectCastInboxByAccount =
      useInvalidateDirectCastInboxByAccount();
    const optimisticallyAddNewDirectCastMessage =
      useOptimisticallyAddNewDirectCastMessage();

    const registeredWebSocketCallbacks = React.useRef<boolean>(false);

    const { registerOnMessageCallback } = useWebSockets();

    useEffect(() => {
      if (registeredWebSocketCallbacks.current) {
        return;
      }

      registerOnMessageCallback({
        messageType: 'refresh-direct-cast-conversation',
        cbReferenceId: 'DirectCastsProvider',
        cb: ({ message }) => {
          if (message.messageType !== 'refresh-direct-cast-conversation') {
            return;
          }

          invalidateDirectCastInboxByAccount({
            fid: currentUser.fid,
            category: 'default',
          });

          invalidateDirectCastInboxByAccount({
            fid: currentUser.fid,
            category: 'default',
            filter: 'unread',
          });

          invalidateDirectCastInboxByAccount({
            fid: currentUser.fid,
            category: 'archived',
          });

          optimisticallyAddNewDirectCastMessage({
            message: message.payload.message,
          });

          if (message.payload.message.type === 'message_ttl_change') {
            const messageTTLInt = parseInt(message.payload.message.message, 10);
            if (!isNaN(messageTTLInt) && messageTTLInt > 0) {
              optimisticallyApplyConversationMessageTTL({
                conversationId: message.payload.message.conversationId,
                messageTTL:
                  messageTTLInt as ApiDirectCastConversationMessageTTLDays,
              });
            }
          }
        },
      });

      registerOnMessageCallback({
        messageType: 'refresh-self-direct-casts-inbox',
        cbReferenceId: 'DirectCastsProvider',
        cb: ({ message }) => {
          if (message.messageType !== 'refresh-self-direct-casts-inbox') {
            return;
          }

          refetch({ cancelRefetch: false });
        },
      });

      registeredWebSocketCallbacks.current = true;
    }, [
      currentUser.fid,
      invalidateDirectCastInboxByAccount,
      optimisticallyAddNewDirectCastMessage,
      refetch,
      registerOnMessageCallback,
      optimisticallyApplyConversationMessageTTL,
    ]);

    const combinedConversations = useMemo(() => {
      const result: { [key: string]: PossiblyOptimisticDirectCast } = {};

      // Add optimistic conversations
      for (const conv of optimisticConversations) {
        result[conv.conversationId] = conv;
      }

      // Add non-optimistic conversations from data
      if (data?.pages) {
        for (const page of data.pages) {
          for (const conv of page.result.conversations) {
            if (!result[conv.conversationId]) {
              result[conv.conversationId] = conv;
            }
          }
        }
      }

      return result;
    }, [data?.pages, optimisticConversations]);

    useEffect(() => {
      if (isInitializing) {
        setIsInitializing(
          Object.keys(combinedConversations).length === 0 && isPending,
        );
      }
    }, [combinedConversations, isInitializing, isPending]);

    useEffect(() => {
      let hasEffectCleanedUp = false;

      let timeout: ReturnType<typeof setTimeout>;

      const refresh = async () => {
        await refetch({
          cancelRefetch: false,
        });

        optimisticallyApplyConversationMessageTTL();

        if (!hasEffectCleanedUp) {
          timeout = setTimeout(refresh, defaultRefreshUpdatesInterval);
        }
      };

      timeout = setTimeout(refresh, defaultRefreshUpdatesInterval);

      return () => {
        hasEffectCleanedUp = true;
        clearTimeout(timeout);
      };
    }, [
      data?.pages,
      inboxCount,
      refetch,
      optimisticallyApplyConversationMessageTTL,
    ]);

    return (
      <DirectCastsContext.Provider
        value={{
          conversations: combinedConversations,
          isInitializing,
          fetchNextPage,
          isLoading: isPending,
          hasArchived,
          requestsCount,
          hasUnreadRequests,
          filter,
          setFilter,
          isEmpty: Object.keys(combinedConversations).length === 0,
        }}
      >
        {children}
      </DirectCastsContext.Provider>
    );
  },
);

DirectCastsProvider.displayName = 'DirectCastsProvider';

const useDirectCasts = () => useContext(DirectCastsContext);

export { DirectCastsProvider, useDirectCasts };
