import * as HoverCard from '@radix-ui/react-hover-card';
import classNames from 'classnames';
import React from 'react';

type HoverCardTooltipProps = {
  className: string;
  trigger: React.ReactNode;
  content: React.ReactNode;
};

const HoverCardTooltip: React.FC<HoverCardTooltipProps> = ({
  trigger,
  content,
  className,
}) => {
  return (
    <HoverCard.Root>
      <HoverCard.Trigger asChild>
        <span className={classNames('relative', className)}>{trigger}</span>
      </HoverCard.Trigger>
      <HoverCard.Portal>
        <HoverCard.Content
          className="HoverCardContent z-50"
          side="bottom"
          onClick={(e) => {
            // We are blocking click events to be propagated down to the components below
            // the hover card.
            e.stopPropagation();
          }}
        >
          <span className="w-300 relative">
            <React.Suspense>{content}</React.Suspense>
          </span>
        </HoverCard.Content>
      </HoverCard.Portal>
    </HoverCard.Root>
  );
};

HoverCardTooltip.displayName = 'HoverCardTooltip';

export { HoverCardTooltip };
