import { useCallback } from 'react';

import { DeleteFeedFollowError } from '../../../errors';
import { useFarcasterApiClient, useTrackEvent } from '../../../providers';
import { SharedAmpEvent } from '../../../utils';
import { useOptimisticallyUpdateChannel } from '../optimistic/useOptimisticallyUpdateChannel';
import {
  useInvalidateChannel,
  useInvalidateDiscoverChannels,
  useInvalidateHighlightedChannels,
  useInvalidateUserChannelsForCategory,
  useInvalidateUserFollowingChannels,
  useRefetchFeedSummaries,
} from '../queries';
import { useRemoveFromUserChannelsForCategoryCache } from '../queries/userChannelsForCategory/useRemoveFromUserChannelsForCategoryCache';

const useDeleteFeedFollow = () => {
  const { apiClient } = useFarcasterApiClient();
  const optimisticallyUpdateFeed = useOptimisticallyUpdateChannel();
  const removeFromUserChannelsForCategoryCache =
    useRemoveFromUserChannelsForCategoryCache();
  const refetchFeedSummaries = useRefetchFeedSummaries();
  const invalidateHighlightedChannels = useInvalidateHighlightedChannels();
  const invalidateChannel = useInvalidateChannel();
  const invalidateUserFollowingChannels = useInvalidateUserFollowingChannels();
  const invalidateUserChannelsForCategory =
    useInvalidateUserChannelsForCategory();
  const invalidateDiscoverChannels = useInvalidateDiscoverChannels();
  const { trackEvent } = useTrackEvent();

  return useCallback(
    async ({
      feedKey,
      following,
      fid,
      location,
    }: {
      feedKey: string;
      following: boolean;
      fid: number;
      // used for analytics
      location?: string;
    }) => {
      const revertOptimisticUpdates = optimisticallyUpdateFeed({
        updates: {
          key: feedKey,
          viewerContext: {
            following: false,
          },
        },
        revertUpdates: {
          key: feedKey,
          viewerContext: {
            following,
          },
        },
      });

      removeFromUserChannelsForCategoryCache({
        fid,
        channelKey: feedKey,
        category: 'follow',
      });

      try {
        trackEvent(SharedAmpEvent.ToggleChannelFollow, {
          channelKey: feedKey,
          toggle: 'on',
          location: location ?? 'unknown',
        });

        const result = await apiClient.deleteFeedFollow({
          feedKey: feedKey,
        });

        void Promise.all([
          refetchFeedSummaries(),
          invalidateUserFollowingChannels(),
          invalidateDiscoverChannels(),
          invalidateChannel({ key: feedKey }),
          invalidateUserChannelsForCategory({ fid, category: 'follow' }),
        ]);

        // Backend updates this async, so wait a bit
        setTimeout(() => {
          void invalidateHighlightedChannels();
        }, 2000);

        return result;
      } catch (error) {
        revertOptimisticUpdates();
        void invalidateUserChannelsForCategory({ fid, category: 'follow' });

        throw new DeleteFeedFollowError({
          feedKey: feedKey,
          error,
        });
      }
    },
    [
      optimisticallyUpdateFeed,
      removeFromUserChannelsForCategoryCache,
      trackEvent,
      apiClient,
      refetchFeedSummaries,
      invalidateUserFollowingChannels,
      invalidateDiscoverChannels,
      invalidateChannel,
      invalidateUserChannelsForCategory,
      invalidateHighlightedChannels,
    ],
  );
};

export { useDeleteFeedFollow };
