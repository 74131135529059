import { FC, memo } from 'react';

const FollowsYou: FC = memo(() => {
  return (
    <div className="rounded border px-2 py-0.5 text-xs border-default text-muted">
      Follows you
    </div>
  );
});

FollowsYou.displayName = 'FollowsYou';

export { FollowsYou };
