import {
  SharedAmpEvent,
  useChannelFollowersYouKnow,
} from 'farcaster-client-hooks';
import React from 'react';

import { LinkToChannelFollowersYouKnow } from '~/components/links/LinkToChannelFollowersYouKnow';
import { FollowersYouKnowContent } from '~/components/profiles/headerSections/FollowersYouKnow';
import { useAnalytics } from '~/contexts/AnalyticsProvider';

type ChannelFollowersYouKnowSectionProps = {
  channelKey: string;
};

const ChannelFollowersYouKnowSection: React.FC<
  ChannelFollowersYouKnowSectionProps
> = ({ channelKey }) => {
  return (
    <React.Suspense fallback={<div className="h-[20px]" />}>
      <ChannelFollowersYouKnow channelKey={channelKey} />
    </React.Suspense>
  );
};

ChannelFollowersYouKnowSection.displayName = 'ChannelFollowersYouKnowSection';

const ChannelFollowersYouKnow: React.FC<
  ChannelFollowersYouKnowSectionProps
> = ({ channelKey }) => {
  const { trackEvent } = useAnalytics();

  const { data } = useChannelFollowersYouKnow({ channelKey, limit: 3 });

  const users = React.useMemo(() => {
    return data!.pages.flatMap((page) => page.result.users);
  }, [data]);

  const totalCount = React.useMemo(() => {
    return data!.pages[0].result.totalCount;
  }, [data]);

  const onFollowersYouKnowClick = React.useCallback(() => {
    trackEvent(SharedAmpEvent.ClickChannelFollowersYouKnow, undefined);
  }, [trackEvent]);

  if (totalCount === 0) {
    return null;
  }

  return (
    <LinkToChannelFollowersYouKnow
      className="h-[20px] items-start text-muted hover:underline"
      title="Channel followers you know"
      channelKey={channelKey}
      onClick={onFollowersYouKnowClick}
    >
      <FollowersYouKnowContent
        users={users}
        totalCount={totalCount}
        variant={'condensed'}
      />
    </LinkToChannelFollowersYouKnow>
  );
};

ChannelFollowersYouKnow.displayName = 'ProfileFollowersYouKnow';

export { ChannelFollowersYouKnowSection };
