import { compactQueryKey } from '../../../../utils/QueryUtils';

export const buildSearchSummaryKey = ({
  q,
  maxChannels,
  maxUsers,
  addFollowersYouKnowContext,
}: {
  q: string | undefined;
  maxChannels: number;
  maxUsers: number;
  addFollowersYouKnowContext: boolean;
}) =>
  compactQueryKey([
    'searchSummary',
    q,
    maxChannels,
    maxUsers,
    addFollowersYouKnowContext,
  ]);
