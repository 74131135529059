import { FC, memo, ReactNode } from 'react';

type ContainerProps = {
  children: ReactNode;
};

const Container: FC<ContainerProps> = memo(({ children }) => {
  return (
    <div className="container mx-auto min-h-full">
      <div className="flex min-h-screen flex-row justify-center">
        {children}
      </div>
    </div>
  );
});

Container.displayName = 'Container';

export { Container };
