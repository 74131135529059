import {
  ApiCastInteractionEvent,
  ApiCastViewEvent,
  ApiMinimalCastViewEvent,
} from 'farcaster-client-data';
import React, {
  createContext,
  FC,
  memo,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react';

import { useFarcasterApiClient } from './FarcasterApiClientProvider';

const SUBMIT_NORMAL_EVENTS_AFTER_MILLIS = 5000;
const SUBMIT_URGENT_EVENTS_AFTER_MILLIS = 1000;

type ApiClientAnalyticsEvent = ApiCastViewEvent | ApiCastInteractionEvent;

type DistributiveOmit<T, K extends keyof T> = T extends unknown
  ? Omit<T, K>
  : never;

export type AnalyticsEventWithoutTimestamp = DistributiveOmit<
  ApiClientAnalyticsEvent,
  'ts'
>;

export type InternalEventingContextValue = {
  // Do not use these 2 directly - use EventingProvider instead
  _trackInternalEvent: (...events: AnalyticsEventWithoutTimestamp[]) => void;
  _trackUrgentInternalEvent: (
    ...events: AnalyticsEventWithoutTimestamp[]
  ) => void;

  // Used when fetching feed items to synchronously submit cast views
  getAndRemoveCastViewEvents: () => ApiMinimalCastViewEvent[];
  addBackCastViewEvents: (events: ApiMinimalCastViewEvent[]) => void;
};

const InternalEventingContext = createContext<InternalEventingContextValue>({
  _trackInternalEvent: () => {},
  _trackUrgentInternalEvent: () => {},
  getAndRemoveCastViewEvents: () => [],
  addBackCastViewEvents: () => {},
});

export type InternalEventingProviderProps = {
  children: ReactNode;
};

const InternalEventingProvider: FC<InternalEventingProviderProps> = memo(
  ({ children }) => {
    const { apiClient } = useFarcasterApiClient();

    const recentCastHashesViewedRef = useRef<Record<string, number>>({});
    const eventsBufferRef = useRef<ApiClientAnalyticsEvent[]>([]);
    const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

    const bufferEvent = useCallback((event: ApiClientAnalyticsEvent) => {
      if (event.type === 'cast-view') {
        const recentTimestamp =
          recentCastHashesViewedRef.current[event.data.castHash];

        if (recentTimestamp && event.ts >= recentTimestamp) {
          // Do not emit later cast view events for the same cast within the same batch
          // The reason we store/use the date is that we may get an older event from addBackCastViewEvents()
          return;
        }
      }

      recentCastHashesViewedRef.current[event.data.castHash] = event.ts;
      eventsBufferRef.current.push(event);
    }, []);

    const submitEvents = useCallback(async () => {
      try {
        if (eventsBufferRef.current.length === 0) {
          return;
        }

        const events = eventsBufferRef.current;
        eventsBufferRef.current = [];

        const recentCastHashes = recentCastHashesViewedRef.current;
        recentCastHashesViewedRef.current = {};

        try {
          await apiClient.recordAnalyticsEvents({ events });
        } catch (e) {
          // Something failed -> add events and viewed cast hashes back to buffers so we can retry later
          for (const [castHash, ts] of Object.entries(recentCastHashes)) {
            // The failed views should always be older than any new ones so should be safe to overwrite
            recentCastHashesViewedRef.current[castHash] = ts;
          }

          eventsBufferRef.current.push(...events);
        }
      } finally {
        timeoutRef.current = undefined;
      }
    }, [apiClient]);

    const _trackInternalEvent = useCallback(
      (...events: AnalyticsEventWithoutTimestamp[]) => {
        events.forEach((event) => bufferEvent({ ...event, ts: Date.now() }));

        if (!timeoutRef.current) {
          timeoutRef.current = setTimeout(
            submitEvents,
            SUBMIT_NORMAL_EVENTS_AFTER_MILLIS,
          );
        }
      },
      [bufferEvent, submitEvents],
    );

    const _trackUrgentInternalEvent = useCallback(
      (...events: AnalyticsEventWithoutTimestamp[]) => {
        events.forEach((event) => bufferEvent({ ...event, ts: Date.now() }));

        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(
          submitEvents,
          SUBMIT_URGENT_EVENTS_AFTER_MILLIS,
        );
      },
      [bufferEvent, submitEvents],
    );

    const getAndRemoveCastViewEvents = useCallback(() => {
      const castViewEvents: ApiMinimalCastViewEvent[] = [];
      const nonCastViewEvents: ApiClientAnalyticsEvent[] = [];

      for (const event of eventsBufferRef.current) {
        if (event.type === 'cast-view') {
          castViewEvents.push({
            ts: event.ts,
            hash: event.data.castHash,
            on: event.data.on,
            channel: event.data.channel,
            feed: event.data.feed,
          });
        } else {
          nonCastViewEvents.push(event);
        }
      }
      eventsBufferRef.current = nonCastViewEvents;

      recentCastHashesViewedRef.current = {};
      return castViewEvents;
    }, []);

    const addBackCastViewEvents = useCallback(
      (events: ApiMinimalCastViewEvent[]) => {
        events.forEach((event) => {
          bufferEvent({
            type: 'cast-view',
            ts: event.ts,
            data: {
              castHash: event.hash,
              on: event.on,
              channel: event.channel,
              feed: event.feed,
            },
          });
        });
      },
      [bufferEvent],
    );

    const props = useMemo(
      () => ({
        _trackInternalEvent,
        _trackUrgentInternalEvent,
        getAndRemoveCastViewEvents,
        addBackCastViewEvents,
      }),
      [
        _trackInternalEvent,
        _trackUrgentInternalEvent,
        getAndRemoveCastViewEvents,
        addBackCastViewEvents,
      ],
    );

    return (
      <InternalEventingContext.Provider value={props}>
        {children}
      </InternalEventingContext.Provider>
    );
  },
);
InternalEventingProvider.displayName = 'InternalEventingProvider';

const useInternalEventing = () => useContext(InternalEventingContext);

export { InternalEventingProvider, useInternalEventing };
