import React, {
  createContext,
  FC,
  memo,
  ReactNode,
  useCallback,
  useContext,
} from 'react';
import { GateResult, useGate, useLayer } from 'statsig-react';

import {
  StatsigAction,
  StatsigEventData,
  StatsigEventValue,
  StatsigGateName,
  StatsigLayer,
  StatsigLayerParam,
  StatsigLayerValue,
} from '../utils/ExperimentationUtils';

type ExperimentationContextValue = {
  // Emit a metrics event
  trackExperimentationEvent: (
    event: StatsigAction,
    data?: StatsigEventData,
    eventValue?: StatsigEventValue,
  ) => void;

  // Function called by the useExperimentLayerValue hook after an experiment layer value is obtained,
  // e.g. to set it in Amplitude
  layerValueCallback: (
    layer: StatsigLayer,
    param: StatsigLayerParam,
    value: StatsigLayerValue,
  ) => void;
};

const ExperimentationContext = createContext<ExperimentationContextValue>({
  trackExperimentationEvent: () => {},
  layerValueCallback: (
    _layer: StatsigLayer,
    _param: StatsigLayerParam,
    _value: StatsigLayerValue,
  ) => {},
});

type ExperimentationProviderProps = {
  trackExperimentationEvent: (
    event: StatsigAction,
    data?: StatsigEventData,
    eventValue?: StatsigEventValue,
  ) => void;
  layerValueCallback?: (
    layer: StatsigLayer,
    param: StatsigLayerParam,
    value: StatsigLayerValue,
  ) => void;
  logEvents?: boolean;

  children: ReactNode;
};

const ExperimentationProvider: FC<ExperimentationProviderProps> = memo(
  ({
    trackExperimentationEvent,
    layerValueCallback = () => {},
    logEvents = false,
    children,
  }) => {
    const errorHandlingTrackExperimentationEvent = useCallback(
      (
        event: StatsigAction,
        data?: StatsigEventData,
        eventValue?: StatsigEventValue,
      ) => {
        try {
          if (logEvents) {
            // eslint-disable-next-line no-console
            console.log(`[StatSig] event: [${event}]: ${JSON.stringify(data)}`);
          }

          trackExperimentationEvent(event, data, eventValue);
        } catch (error) {
          if (logEvents) {
            // eslint-disable-next-line no-console
            console.error(`[StatSig error]: ${error}`);
          }
        }
      },
      [logEvents, trackExperimentationEvent],
    );

    return (
      <ExperimentationContext.Provider
        value={{
          trackExperimentationEvent: errorHandlingTrackExperimentationEvent,
          layerValueCallback,
        }}
      >
        {children}
      </ExperimentationContext.Provider>
    );
  },
);

ExperimentationProvider.displayName = 'ExperimentationProvider';

const useExperimentation = () => useContext(ExperimentationContext);

interface ExperimentLayer {
  isLoading: boolean;
  getLayerValue: <T extends StatsigLayerValue>(
    param: StatsigLayerParam,
    defaultValue: T,
  ) => T;
}

function useExperimentLayer(layer: StatsigLayer): ExperimentLayer {
  const { layerValueCallback } = useExperimentation();
  const { layer: layerObj, isLoading } = useLayer(layer);

  const getLayerValue = useCallback(
    <T extends StatsigLayerValue>(
      param: StatsigLayerParam,
      defaultValue: T,
    ): T => {
      const value = layerObj.get(param, defaultValue);
      layerValueCallback(layer, param, value);
      return value;
    },
    [layer, layerObj, layerValueCallback],
  );

  return {
    isLoading,
    getLayerValue,
  };
}

function useFeatureGate(gateName: StatsigGateName): GateResult {
  return useGate(gateName);
}

export {
  ExperimentationProvider,
  useExperimentation,
  useExperimentLayer,
  useFeatureGate,
};
