// eslint-disable-next-line no-restricted-imports
import Linkify from 'linkify-it';

import { customRules } from '~/utils/linkify/rules';
import { tlds } from '~/utils/linkify/tlds';

const userLinkPrefix = 'farcaster://user/';
const channelLinkPrefix = 'farcaster://channel/';

const BASE_CHAIN_ID = 8453;
const ZORA_CHAIN_ID = 7777777;
const OP_CHAIN_ID = 10;
const ETH_CHAIN_ID = 1;

export const ETH_CHAIN_URI_PREFIX = `chain://eip155:${ETH_CHAIN_ID}/`;
export const BASE_CHAIN_URI_PREFIX = `chain://eip155:${BASE_CHAIN_ID}/`;
export const ZORA_CHAIN_URI_PREFIX = `chain://eip155:${ZORA_CHAIN_ID}/`;
export const OP_CHAIN_URI_PREFIX = `chain://eip155:${OP_CHAIN_ID}/`;

const CAIP_19_PATTERN = /([a-z0-9]+):((?:0x)?[a-fA-F0-9]{1,})(\/(\d+))?$/;

const getDefaultLinkifyInstance = () => {
  const instance = new Linkify();
  instance.tlds(tlds, true);
  customRules.forEach((rule) => {
    instance.add(rule.domainToken, rule);
  });

  instance.add('@', {
    validate(text, pos) {
      const tail = text.slice(pos);

      if (mentionRegexForLinkify.test(tail)) {
        // Linkifier allows punctuation chars before prefix,
        // but we additionally disable `@` ("@@mention" is invalid)
        if (tail.charAt(0) === '@') {
          return false;
        }

        const matches = tail.match(mentionRegexForLinkify);

        if (matches && matches.length > 0) {
          return matches[0].length;
        }
      }

      return false;
    },
    normalize(match) {
      match.url = userLinkPrefix + match.url.replace(/^@/, '');
    },
  });

  instance.add('/', {
    validate(text, pos) {
      const tail = text.slice(pos);

      if (channelMentionRegexForLinkify.test(tail)) {
        // Linkifier allows punctuation chars before prefix,
        // but we additionally disable `/` ("//channel" is invalid)
        if (tail.charAt(0) === '/') {
          return false;
        }

        const matches = tail.match(channelMentionRegexForLinkify);

        if (matches && matches.length > 0) {
          return matches[0].length;
        }
      }

      return false;
    },
    normalize(match) {
      match.url = channelLinkPrefix + match.url.replace(/^\//, '');
    },
  });

  // Treat `farcaster://` links like regular links. We process them as in-app
  // links below so they don't use the OS URL protocol handler.
  instance.add('farcaster:', {
    validate: (text, pos) => {
      const tail = text.slice(pos);
      const matches = tail.match(/\/\/casts\/([A-Za-z0-9]+)\/([A-Za-z0-9]+)/i);
      if (matches && matches.length > 0) {
        const match = matches[0];
        return match.length;
      }
      return false;
    },
  });

  instance.add(ETH_CHAIN_URI_PREFIX, {
    validate: (textToMatch: string) => {
      const matches = textToMatch.match(CAIP_19_PATTERN);

      return matches !== null && matches.length > 0 && matches[0].length;
    },
  });

  instance.add(BASE_CHAIN_URI_PREFIX, {
    validate: (textToMatch: string) => {
      const matches = textToMatch.match(CAIP_19_PATTERN);

      return matches !== null && matches.length > 0 && matches[0].length;
    },
  });

  instance.add(ZORA_CHAIN_URI_PREFIX, {
    validate: (textToMatch: string) => {
      const matches = textToMatch.match(CAIP_19_PATTERN);

      return matches !== null && matches.length > 0 && matches[0].length;
    },
  });

  instance.add(OP_CHAIN_URI_PREFIX, {
    validate: (textToMatch: string) => {
      const matches = textToMatch.match(CAIP_19_PATTERN);

      return matches !== null && matches.length > 0 && matches[0].length;
    },
  });

  return instance;
};

const mentionRegexString = `^[a-z0-9][a-z0-9-]{0,15}(\\.eth)?(?=$|${
  getDefaultLinkifyInstance().re.src_ZPCc
})`;
const mentionRegexForLinkify = new RegExp(`^${mentionRegexString}`);

const channelMentionRegexString = `^[a-z0-9][a-z0-9-]{0,15}(?=$|${
  getDefaultLinkifyInstance().re.src_ZPCc
})`;
const channelMentionRegexForLinkify = new RegExp(
  `^${channelMentionRegexString}`,
);

const getComposerInstance = () => {
  const instance = new Linkify();
  instance.tlds(tlds, true);

  instance.set({ fuzzyEmail: false });
  instance.add('mailto:', null);
  instance.add('@', null);
  instance.add('/', null);

  instance.add(ETH_CHAIN_URI_PREFIX, {
    validate: (textToMatch: string) => {
      const matches = textToMatch.match(CAIP_19_PATTERN);

      return matches !== null && matches.length > 0 && matches[0].length;
    },
  });

  instance.add(BASE_CHAIN_URI_PREFIX, {
    validate: (textToMatch: string) => {
      const matches = textToMatch.match(CAIP_19_PATTERN);

      return matches !== null && matches.length > 0 && matches[0].length;
    },
  });

  instance.add(ZORA_CHAIN_URI_PREFIX, {
    validate: (textToMatch: string) => {
      const matches = textToMatch.match(CAIP_19_PATTERN);

      return matches !== null && matches.length > 0 && matches[0].length;
    },
  });

  instance.add(OP_CHAIN_URI_PREFIX, {
    validate: (textToMatch: string) => {
      const matches = textToMatch.match(CAIP_19_PATTERN);

      return matches !== null && matches.length > 0 && matches[0].length;
    },
  });

  return instance;
};

const getDirectCastsComposerInstance = () => {
  const instance = new Linkify();
  instance.tlds(tlds, true);

  instance.set({ fuzzyLink: false });
  instance.set({ fuzzyEmail: false });
  instance.add('mailto:', null);
  instance.add('@', null);
  instance.add('/', null);

  return instance;
};

export {
  channelLinkPrefix,
  getComposerInstance,
  getDefaultLinkifyInstance,
  getDirectCastsComposerInstance,
  userLinkPrefix,
};
