import classNames from 'classnames';
import { FC, memo, useMemo } from 'react';

import { BackButton } from '~/components/forms/buttons/BackButton';
import { useStandaloneMode } from '~/contexts/StandaloneModeProvider';
import { useIsSignedIn } from '~/hooks/data/useIsSignedIn';

const MobileHeader: FC = memo(() => {
  const { inStandaloneMode } = useStandaloneMode();

  const isSignedIn = useIsSignedIn();

  const mobileHeaderIsHidden = useMemo(
    () => inStandaloneMode || !isSignedIn,
    [inStandaloneMode, isSignedIn],
  );

  return (
    <nav
      className={classNames(
        'sticky top-0 z-50 items-center justify-start border-b px-4 py-2 bg-app border-default',
        mobileHeaderIsHidden ? 'hidden' : 'flex flex-row sm:hidden',
      )}
    >
      <BackButton />
    </nav>
  );
});

MobileHeader.displayName = 'MobileHeader';

export { MobileHeader };
