import amplitude from 'amplitude-js';
import {
  ExperimentationProvider,
  StatsigAction,
  StatsigEventData,
  StatsigEventValue,
  StatsigLayer,
  StatsigLayerParam,
  StatsigLayerValue,
} from 'farcaster-client-hooks';
import React, { memo, useCallback, useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Statsig, StatsigProvider } from 'statsig-react';

import { baseApiUrl } from '~/constants/api';
import { isProd } from '~/constants/env';
import { statsigApiKey, statsigEnv } from '~/constants/statsig';
import { appVersion } from '~/constants/version';
import { GlobalGateProvider } from '~/contexts/GlobalGateProvider';
import { useCurrentUser } from '~/hooks/data/useCurrentUser';

interface ExperimentationProviderProps {
  children: React.ReactNode;
}
const WebExperimentationProvider: React.FC<ExperimentationProviderProps> = memo(
  ({ children }) => {
    const user = useCurrentUser();

    const trackExperimentationEvent = useCallback(
      (
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        event: StatsigAction,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        data: StatsigEventData = undefined,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        eventValue: StatsigEventValue = undefined,
      ) => {
        Statsig.logEvent(event.toString(), eventValue, data);
      },
      [],
    );

    const layerValueCallback = useCallback(
      (
        layer: StatsigLayer,
        param: StatsigLayerParam,
        value: StatsigLayerValue,
      ) => {
        // Add a user-level property in Amplitude with the layer name, parameter and value
        const userProperties = new amplitude.Identify().set(
          `exp|${layer.toString()}|${param.toString()}`,
          value,
        );
        amplitude.identify(userProperties);
      },
      [],
    );

    const apiEndpoint = useMemo(
      () =>
        isProd ? `${baseApiUrl}/v2/ss/v1/` : 'https://api.statsig.com/v1/',
      [],
    );

    return (
      <StatsigProvider
        sdkKey={statsigApiKey}
        user={{
          userID: user.fid,
          appVersion,
        }}
        options={{
          environment: {
            tier: statsigEnv,
          },
          api: apiEndpoint,
          eventLoggingApi: apiEndpoint,
          initTimeoutMs: 5_000,
        }}
        waitForInitialization
      >
        <ExperimentationProvider
          trackExperimentationEvent={trackExperimentationEvent}
          layerValueCallback={layerValueCallback}
          logEvents={!isProd}
        >
          <GlobalGateProvider>{children}</GlobalGateProvider>
        </ExperimentationProvider>
      </StatsigProvider>
    );
  },
);

WebExperimentationProvider.displayName = 'WebExperimentationProvider';

export { WebExperimentationProvider };
