import { Bytes } from 'ethers';
import { useCallback } from 'react';

import { createWalletFromMnemonic } from '~/utils/walletUtils';

const useSignOperation = <T>() => {
  return useCallback(
    async ({
      mnemonic,
      operation,
    }: {
      mnemonic: string;
      operation: (
        address: string,
        signMessage: (data: string | Bytes) => Promise<string>,
      ) => T;
    }) => {
      const wallet = await createWalletFromMnemonic({ mnemonic });
      return await operation(wallet.address, (data: string | Bytes) =>
        wallet.signMessage(data),
      );
    },
    [],
  );
};

export { useSignOperation };
