import { NetworkMode } from '@tanstack/react-query';

import { onboardingStateCacheTime } from './shared';

const onboardingStateDefaultQueryOptions = {
  staleTime: onboardingStateCacheTime,
  gcTime: onboardingStateCacheTime,
  networkMode: 'offlineFirst' as NetworkMode,
};

export { onboardingStateDefaultQueryOptions };
