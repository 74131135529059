import { ApiUserPreferences } from 'farcaster-client-data';
import { useCallback } from 'react';

import { useFarcasterApiClient } from '../../../providers';
import { useInvalidateUserPreferences } from '../queries';

const useSetUserPreferences = () => {
  const { apiClient } = useFarcasterApiClient();

  const invalidateUserPreferences = useInvalidateUserPreferences();

  return useCallback(
    async ({ preferences }: { preferences: ApiUserPreferences }) => {
      const response = await apiClient.setUserPreferences({ preferences });

      invalidateUserPreferences();

      return response.data;
    },
    [apiClient, invalidateUserPreferences],
  );
};

export { useSetUserPreferences };
