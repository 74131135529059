const authTokenKey = 'auth-token';
const featureFlagsKey = 'feature-flags';
const mostRecentReactionsKey = 'most-recent-reactions';
const transportAgreementPublicKeysKey = 'transport-agreement-public-keys';
const castDraftsKey = 'cast-drafts';

export {
  authTokenKey,
  castDraftsKey,
  featureFlagsKey,
  mostRecentReactionsKey,
  transportAgreementPublicKeysKey,
};
