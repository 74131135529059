import React from 'react';

type HomeLastSelectedTabContextType = {
  feedKey: string;
  setFeedKey: ({ feedKey }: { feedKey: string }) => void;
};

const HomeLastSelectedTabContext =
  React.createContext<HomeLastSelectedTabContextType>({} as never);

const LAST_ACTIVE_FEED_STORAGE_KEY = 'last-active-feed';

export function HomeLastSelectedTabContextProvider({
  children,
}: React.PropsWithChildren) {
  const [feedKey, setFeedKey] = React.useState<string>(
    sessionStorage.getItem(LAST_ACTIVE_FEED_STORAGE_KEY) || 'home',
  );

  const setFeedKeyForContext = React.useCallback(
    ({ feedKey: feedKeyToSet }: { feedKey: string }) => {
      if (feedKey !== feedKeyToSet) {
        setFeedKey(feedKeyToSet);

        if (feedKeyToSet === 'home' || feedKeyToSet === 'following') {
          sessionStorage.setItem(LAST_ACTIVE_FEED_STORAGE_KEY, feedKeyToSet);
        } else {
          sessionStorage.removeItem(LAST_ACTIVE_FEED_STORAGE_KEY);
        }
      }
    },
    [feedKey],
  );

  const value = React.useMemo(
    () => ({
      feedKey,
      setFeedKey: setFeedKeyForContext,
    }),
    [feedKey, setFeedKeyForContext],
  );

  return (
    <HomeLastSelectedTabContext.Provider value={value}>
      {children}
    </HomeLastSelectedTabContext.Provider>
  );
}

export function useHomeLastSelectedTab() {
  return React.useContext(HomeLastSelectedTabContext);
}
