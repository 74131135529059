import { useSuspenseInfiniteQuery } from '@tanstack/react-query';
import { getNextPageCursor } from 'farcaster-client-data';

import { useFarcasterApiClient } from '../../../../providers/FarcasterApiClientProvider';
import { useBatchMergeIntoGloballyCachedUsers } from '../globallyCachedUser/useBatchMergeIntoGloballyCachedUsers';
import { buildChannelFollowersYouKnowFetcher } from './buildChannelFollowersYouKnowFetcher';
import { buildChannelFollowersYouKnowKey } from './buildChannelFollowersYouKnowKey';

const useChannelFollowersYouKnow = ({
  channelKey,
  limit,
}: {
  channelKey: string;
  limit: number;
}) => {
  const { apiClient } = useFarcasterApiClient();
  const batchMergeIntoGloballyCachedUsers =
    useBatchMergeIntoGloballyCachedUsers();

  return useSuspenseInfiniteQuery({
    initialPageParam: undefined,
    queryKey: buildChannelFollowersYouKnowKey({ channelKey, limit }),

    queryFn: buildChannelFollowersYouKnowFetcher({
      apiClient,
      batchMergeIntoGloballyCachedUsers,
      channelKey,
      limit,
    }),

    getNextPageParam: getNextPageCursor,
  });
};

export { useChannelFollowersYouKnow };
