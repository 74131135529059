import { FC, memo } from 'react';

import { LinkToHomeFeed } from '~/components/links/LinkToHomeFeed';
import { Logo } from '~/components/Logo';
import { useScrollToTopOfRoot } from '~/hooks/useScrollToTopOfRoot';

type LeftSideBarLogoProps = {
  title: string;
};

const LeftSideBarLogo: FC<LeftSideBarLogoProps> = memo(({ title }) => {
  const scrollToTopOfRoot = useScrollToTopOfRoot();

  return (
    <LinkToHomeFeed
      title={title}
      className="mx-4 block xl:ml-[0.05rem]"
      onClick={() => {
        scrollToTopOfRoot();
      }}
    >
      <div className="flex h-14 flex-col items-center justify-center md:items-start">
        <Logo />
      </div>
    </LinkToHomeFeed>
  );
});

LeftSideBarLogo.displayName = 'LeftSideBarLogo';

export { LeftSideBarLogo };
