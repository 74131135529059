import { Bytes } from 'ethers';
import {
  ApiOnboardingState,
  ApiToken,
  buildCustodyBearerPayload,
  buildCustodyBearerToken,
  DEFAULT_CUSTODY_BEARER_TOKEN_EXPIRES_IN,
  FarcasterApiClient,
} from 'farcaster-client-data';
import { useCallback } from 'react';

import { useSignOperation } from './useSignOperation';

const useSignAuthTokenOperation = () => {
  const signOperation = useSignOperation<
    Promise<{
      state: ApiOnboardingState;
      token?: ApiToken;
      address: string;
    }>
  >();
  return useCallback(
    async ({
      mnemonic,
      apiClient,
    }: {
      mnemonic: string;
      apiClient: FarcasterApiClient;
    }) => {
      return await signOperation({
        mnemonic: mnemonic,
        operation: async (
          address: string,
          signMessage: (data: string | Bytes) => Promise<string>,
        ) => {
          const custodyBearerPayload = buildCustodyBearerPayload({
            expiresIn: DEFAULT_CUSTODY_BEARER_TOKEN_EXPIRES_IN,
          });
          const custodyBearerToken = await buildCustodyBearerToken({
            payload: custodyBearerPayload,
            signMessage: (data: string | Bytes) => signMessage(data),
          });
          const response = await apiClient.getOnboardingStateAndAuthToken(
            { authRequest: custodyBearerPayload },
            {
              headers: { Authorization: `Bearer ${custodyBearerToken}` },
            },
          );
          const result = response.data.result;
          return {
            ...result,
            address,
          };
        },
      });
    },
    [signOperation],
  );
};

export { useSignAuthTokenOperation };
