import { useCallback } from 'react';

import { UpdateUserVisibilityError } from '../../../errors';
import { useFarcasterApiClient } from '../../../providers';
import { useOptimisticallyUpdateUser } from '../optimistic';
import {
  useInvalidateDirectCastInboxByAccount,
  useRemoveConversationWithUser,
} from '../queries';
import { useRemoveUserFromAllFeeds } from '../queries/feedItems/useRemoveUserFromAllFeeds';

const useMarkInvisible = () => {
  const { apiClient } = useFarcasterApiClient();
  const optimisticallyUpdateUser = useOptimisticallyUpdateUser();
  const removeUserFromAllFeeds = useRemoveUserFromAllFeeds();
  const removeConversationWithUser = useRemoveConversationWithUser();
  const invalidateDirectCastInboxByAccount =
    useInvalidateDirectCastInboxByAccount();

  return useCallback(
    async ({
      targetFid,
      block,
      following,
      viewerFid,
    }: {
      viewerFid?: number;
      block: boolean;
      targetFid: number;
      following: boolean | undefined;
    }) => {
      const revertMarkInvisibleUpdate = optimisticallyUpdateUser({
        updates: {
          fid: targetFid,
          viewerContext: { invisible: true, blocking: block, following: false },
        },
        revertUpdates: {
          fid: targetFid,
          viewerContext: { invisible: false, blocking: false, following },
        },
      });

      try {
        const response = await apiClient.limitVisibility({
          targetFid,
          block,
        });
        removeUserFromAllFeeds({ fid: targetFid });

        if (viewerFid) {
          removeConversationWithUser({ viewerFid, targetFid });
          invalidateDirectCastInboxByAccount({
            fid: viewerFid,
            category: 'default',
          });
          invalidateDirectCastInboxByAccount({
            fid: viewerFid,
            category: 'request',
          });
          invalidateDirectCastInboxByAccount({
            fid: viewerFid,
            category: 'archived',
          });
        }

        return response.data;
      } catch (error) {
        revertMarkInvisibleUpdate();

        throw new UpdateUserVisibilityError({ error });
      }
    },
    [
      apiClient,
      optimisticallyUpdateUser,
      removeUserFromAllFeeds,
      removeConversationWithUser,
      invalidateDirectCastInboxByAccount,
    ],
  );
};

export { useMarkInvisible };
