import { FC, memo } from 'react';

const Following: FC = memo(() => {
  return (
    <div className="rounded-full border px-2 py-0.5 text-xs border-default text-muted">
      Following
    </div>
  );
});

Following.displayName = 'Following';

export { Following };
