import { isPasskeysSupported } from 'farcaster-cryptography';
import { FC, memo, useEffect, useMemo, useState } from 'react';

import { LoginWithMobile } from '~/components/login/LoginWithMobile';
import { LoginWithMobilePasskeys } from '~/components/login/LoginWithMobilePasskeys';
import { UnsupportedBrowser } from '~/components/login/UnsupportedBrowser';
import { DefaultModalContainer } from '~/components/modals/DefaultModalContainer';
import { Modal } from '~/components/modals/Modal';
import { useFeatureFlag } from '~/contexts/FeatureFlagProvider';
import { keyStore } from '~/utils/cryptographyUtils';

type LoginModalProps = {
  onClose: () => void;
};

const LoginModal: FC<LoginModalProps> = memo(({ onClose }) => {
  const { getFeatureFlagValue } = useFeatureFlag();
  const [canUsePasskeys, setCanUsePasskeys] = useState<boolean>(false);

  useEffect(() => {
    isPasskeysSupported({ keyStore }).then((supported) => {
      setCanUsePasskeys(supported);
    });
  }, []);

  const body = useMemo(() => {
    if (getFeatureFlagValue('login-with-passkeys')) {
      if (canUsePasskeys) {
        return <LoginWithMobilePasskeys onClose={onClose} />;
      } else {
        return <UnsupportedBrowser onClose={onClose} />;
      }
    } else {
      return <LoginWithMobile onClose={onClose} />;
    }
  }, [canUsePasskeys, getFeatureFlagValue, onClose]);

  return (
    <Modal>
      <DefaultModalContainer onClose={onClose}>
        <div className="flex h-full w-full flex-col items-center justify-center p-4">
          <div
            className="relative flex w-full max-w-md flex-col items-start justify-center rounded-lg border p-4 bg-app border-default"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {body}
          </div>
        </div>
      </DefaultModalContainer>
    </Modal>
  );
});

LoginModal.displayName = 'LoginModal';

export { LoginModal };
