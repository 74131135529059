import { lazyWithPreload } from './helpers';

export const LoginQRCode = lazyWithPreload(() =>
  import('~/components/login/LoginQRCode').then((res) => ({
    default: res.LoginQRCode,
  })),
);

export const Composer = lazyWithPreload(() =>
  import('~/components/composer/Composer').then((res) => ({
    default: res.Composer,
  })),
);

export const FarcasterFrameAttachment = lazyWithPreload(() =>
  import('~/components/attachments/FarcasterFrameAttachment').then((res) => ({
    default: res.FarcasterFrameAttachment,
  })),
);

export const PayUserDialog = lazyWithPreload(() =>
  import('~/components/payUser/PayUserDialog').then((res) => ({
    default: res.PayUserDialog,
  })),
);
