import {
  InternalEventingProvider,
  WebSocketsProvider,
} from 'farcaster-client-hooks';
import { FC } from 'react';
import { Slide, ToastContainer } from 'react-toastify';

import { PreloadAuthedResources } from '~/components/PrefetchAuthedResources';
import { EnsureCachedCurrentUser } from '~/components/users/EnsureCachedCurrentUser';
import { DirectCastsDraftsProvider } from '~/contexts/DirectCastsDraftsProvider';
import { DirectCastsProvider } from '~/contexts/DirectCastsProvider';
import { DirectCastsScrollLocksProvider } from '~/contexts/DirectCastsScrollLocksProvider';
import { LaunchFrameProvider } from '~/contexts/LaunchFrameProvider';
import { MuteUserProvider } from '~/contexts/MuteUserProvider';
import { PayUserProvider } from '~/contexts/PayUserProvider';
import { UserAppContextProvider } from '~/contexts/UserAppContextProvider';
import { WebEventingProvider } from '~/contexts/WebEventingProvider';
import { WebExperimentationProvider } from '~/contexts/WebExperimentationProvider';
import { WebUnseenProvider } from '~/contexts/WebUnseenProvider';
import { AuthedLeftSideBar } from '~/layouts/AuthedLeftSideBar';
import { AuthedRightSideBar } from '~/layouts/AuthedRightSideBar';
import { Container } from '~/layouts/Container';
import { MainContent } from '~/layouts/MainContent';

const AuthedLayout: FC = () => {
  return (
    <EnsureCachedCurrentUser>
      <UserAppContextProvider>
        <WebSocketsProvider>
          <WebUnseenProvider>
            <MuteUserProvider>
              <DirectCastsProvider>
                <DirectCastsDraftsProvider>
                  <DirectCastsScrollLocksProvider>
                    <WebExperimentationProvider>
                      {/* InternalEventingProvider must be a child of FarcasterApiClientProvider and PubSubProvider */}
                      <InternalEventingProvider>
                        {/* WebEventingProvider must be a child of WebExperimentationProvider */}
                        <WebEventingProvider>
                          <LaunchFrameProvider>
                            <PayUserProvider>
                              <Container>
                                <PreloadAuthedResources />
                                <AuthedLeftSideBar />
                                <MainContent />
                                <AuthedRightSideBar />
                                <ToastContainer
                                  autoClose={3000}
                                  hideProgressBar={true}
                                  transition={Slide}
                                  pauseOnHover={false}
                                  closeOnClick={true}
                                  pauseOnFocusLoss={false}
                                  closeButton={false}
                                  icon={false}
                                  theme="colored"
                                  position="top-center"
                                />
                              </Container>
                            </PayUserProvider>
                          </LaunchFrameProvider>
                        </WebEventingProvider>
                      </InternalEventingProvider>
                    </WebExperimentationProvider>
                  </DirectCastsScrollLocksProvider>
                </DirectCastsDraftsProvider>
              </DirectCastsProvider>
            </MuteUserProvider>
          </WebUnseenProvider>
        </WebSocketsProvider>
      </UserAppContextProvider>
    </EnsureCachedCurrentUser>
  );
};

export { AuthedLayout };
