import { useCallback } from 'react';

import { useFarcasterApiClient } from '../../../providers/FarcasterApiClientProvider';

const useUpdatePaidInvite = () => {
  const { apiClient } = useFarcasterApiClient();

  return useCallback(
    async ({
      inviteId,
      inviteToChannelKeys,
    }: {
      inviteId: string;
      inviteToChannelKeys: string[];
    }) => {
      await apiClient.updatePaidInvite({ inviteId, inviteToChannelKeys });
    },
    [apiClient],
  );
};

export { useUpdatePaidInvite };
