import { StatsigEvent, useTrackEvent } from 'farcaster-client-hooks';
import React, { FC, ReactNode } from 'react';

export type ExternalLinkProps = {
  children?: ReactNode;
  className?: string;
  href: string;
  stopPropagation?: boolean;
  title: string;
  emitEventProps?: StatsigEvent;
  onClick?: () => void;
  externalLinkRef?: React.RefObject<HTMLAnchorElement>;
};

const ExternalLink: FC<ExternalLinkProps> = ({
  children,
  className,
  href,
  stopPropagation = true,
  title,
  emitEventProps,
  onClick,
  externalLinkRef,
}) => {
  const { trackStatsigEvent } = useTrackEvent();

  return (
    // eslint-disable-next-line react/forbid-elements
    <a
      ref={externalLinkRef}
      className={className}
      onClick={(e) => {
        trackStatsigEvent({ ...emitEventProps, url: href });
        if (stopPropagation) {
          e.stopPropagation();
        }

        if (onClick) {
          onClick();
        }
      }}
      title={title}
      href={href}
      target={'_blank'}
    >
      {children}
    </a>
  );
};

export { ExternalLink };
