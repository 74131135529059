import { FC, memo } from 'react';

type Size = 'sm' | 'md' | 'lg';

type LogoProps = {
  size?: Size;
};

const sizeCn: Record<Size, string> = {
  sm: '32',
  md: '42',
  lg: '64',
};

const Logo: FC<LogoProps> = memo(({ size = 'sm' }) => {
  return (
    <svg
      width={sizeCn[size]}
      height={sizeCn[size]}
      viewBox="0 0 1260 1260"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_2)">
        <path
          d="M947.747 1259.61H311.861C139.901 1259.61 0 1119.72 0 947.752V311.871C0 139.907 139.901 0.00541362 311.861 0.00541362H947.747C1119.71 0.00541362 1259.61 139.907 1259.61 311.871V947.752C1259.61 1119.72 1119.71 1259.61 947.747 1259.61Z"
          fill="#472A91"
        />
        <path
          d="M826.513 398.633L764.404 631.889L702.093 398.633H558.697L495.789 633.607L433.087 398.633H269.764L421.528 914.36H562.431L629.807 674.876L697.181 914.36H838.388L989.819 398.633H826.513Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_2">
          <rect width="1259.61" height="1259.61" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
});

Logo.displayName = 'Logo';

export { Logo };
