import { useQueryClient } from '@tanstack/react-query';
import {
  buildCustodyBearerPayload,
  buildCustodyBearerToken,
  DEFAULT_CUSTODY_BEARER_TOKEN_EXPIRES_IN,
  SignMessage,
} from 'farcaster-client-data';
import { useCallback } from 'react';

import { useFarcasterApiClient } from '../../../../providers';
import { OnboardingStateCache } from '../../../../types';
import { buildOnboardingStateAndAuthTokenFetcher } from './buildOnboardingStateAndAuthTokenFetcher';
import { buildOnboardingStateKey } from './buildOnboardingStateKey';

const useRefreshOnboardingStateAndAuthToken = () => {
  const { apiClient } = useFarcasterApiClient();
  const queryClient = useQueryClient();

  return useCallback(
    async ({
      expiresIn = DEFAULT_CUSTODY_BEARER_TOKEN_EXPIRES_IN,
      signMessage,
    }: {
      expiresIn?: number;
      signMessage: SignMessage;
    }) => {
      const custodyBearerPayload = buildCustodyBearerPayload({ expiresIn });
      const custodyBearerToken = await buildCustodyBearerToken({
        payload: custodyBearerPayload,
        signMessage,
      });

      const response = await buildOnboardingStateAndAuthTokenFetcher({
        apiClient,
        custodyBearerPayload,
        custodyBearerToken,
      })();

      queryClient.setQueryData<OnboardingStateCache>(
        buildOnboardingStateKey(),
        response,
      );

      return response;
    },
    [apiClient, queryClient],
  );
};

export { useRefreshOnboardingStateAndAuthToken };
