import { useQueryClient } from '@tanstack/react-query';
import { ApiToken, AuthToken } from 'farcaster-client-data';
import {
  RemovePersistentQueryStorageError,
  SignOutListenerError,
  useFarcasterApiClient,
  useRefreshOnboardingState,
  useRefreshOnboardingStateAndAuthToken,
} from 'farcaster-client-hooks';
import pull from 'lodash/pull';
import React, {
  createContext,
  FC,
  memo,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { authTokenKey } from '~/constants/storage';
import { useClearKeyTransport } from '~/hooks/useClearKeyTransport';
import { trackError } from '~/utils/errorUtils';
import { getItem, setItem } from '~/utils/storageUtils';

import { usePersistQueryClientInstance } from './PersistQueryClientInstanceProvider';

type OnSignOutListener = () => void;
type RemoveOnSignOutListener = () => void;
type AddSignOutListener = (
  listener: OnSignOutListener,
) => RemoveOnSignOutListener;

type AuthContextValue = {
  authToken: AuthToken | undefined;
  signIn: (params: { authToken: ApiToken; persist?: boolean }) => Promise<void>;
  signOut: () => Promise<void>;
  addSignOutListener: AddSignOutListener;
};

const AuthContext = createContext<AuthContextValue>({
  authToken: undefined,
  signIn: async () => undefined,
  signOut: async () => undefined,
  addSignOutListener: () => () => undefined,
});

type AuthProviderProps = {
  children: ReactNode;
};

const AuthProvider: FC<AuthProviderProps> = memo(({ children }) => {
  const queryClient = useQueryClient();
  const { apiClient } = useFarcasterApiClient();

  const [isInitialized, setIsInitialized] = useState(false);
  const [authToken, setAuthToken] = useState<AuthToken>();
  const onSignOutListeners = useRef([] as OnSignOutListener[]).current;

  const { localStoragePersister } = usePersistQueryClientInstance();

  const refreshOnboardingState = useRefreshOnboardingState();
  const refreshOnboardingStateAndAuthToken =
    useRefreshOnboardingStateAndAuthToken();

  const clearKeyTransport = useClearKeyTransport();

  const signIn = useCallback(
    async ({
      authToken: newAuthToken,
      persist = true,
    }: {
      authToken: AuthToken | undefined;
      persist?: boolean;
    }) => {
      setAuthToken(newAuthToken);

      apiClient.updateOptions({
        getAuthToken: newAuthToken ? async () => newAuthToken : undefined,
      });

      if (persist) {
        setItem({ key: authTokenKey, value: newAuthToken });
      }
    },
    [apiClient],
  );

  const signOut = useCallback(async () => {
    onSignOutListeners.forEach((listener) => {
      try {
        listener();
      } catch (error) {
        trackError(new SignOutListenerError({ error }));
      }
    });

    setAuthToken(undefined);

    await clearKeyTransport();

    await setItem({ key: authTokenKey, value: undefined });

    // Wait a second before clearing the query cache.
    // This gives the navigator a chance to unmount the authed stacks
    // so we don't invalidate the cache and immediately refetch.
    setTimeout(async () => {
      queryClient.clear();

      try {
        if (localStoragePersister) {
          await localStoragePersister.removeClient();
        }
      } catch (error) {
        trackError(new RemovePersistentQueryStorageError({ error }));
      }

      location.reload();
    }, 750);
  }, [
    clearKeyTransport,
    localStoragePersister,
    onSignOutListeners,
    queryClient,
  ]);

  const addSignOutListener: AddSignOutListener = useCallback(
    (listener: OnSignOutListener) => {
      onSignOutListeners.push(listener);
      return () => {
        pull(onSignOutListeners, listener);
      };
    },
    [onSignOutListeners],
  );

  useEffect(() => {
    const init = async () => {
      if (isInitialized) {
        return;
      }

      try {
        const persistedAuthToken = await getItem<AuthToken | undefined>({
          key: authTokenKey,
          fallback: undefined,
        });

        if (persistedAuthToken) {
          signIn({ authToken: persistedAuthToken, persist: false });
          await refreshOnboardingState();
        }
      } catch (error) {
        trackError(error);
      }

      setIsInitialized(true);
    };

    init();
  }, [
    isInitialized,
    refreshOnboardingState,
    refreshOnboardingStateAndAuthToken,
    setAuthToken,
    signIn,
  ]);

  if (!isInitialized) {
    return null;
  }

  return (
    <AuthContext.Provider
      value={{ authToken, signIn, signOut, addSignOutListener }}
    >
      {children}
    </AuthContext.Provider>
  );
});

AuthProvider.displayName = 'AuthProvider';

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
