import { CheckIcon, CopyIcon, HistoryIcon } from '@primer/octicons-react';
import { ApiChannel } from 'farcaster-client-data';
import {
  channelKeyExtractor,
  SharedAmpEvent,
  useCreateInvite,
  useInvalidateInviteWithWarpsOffering,
  useInviteWithWarpsOfferingWithRefreshOnMount,
  useUpdatePaidInvite,
  useUserChannelsForCategory,
  useUserChannelsForCategoryWithRefreshOnMount,
} from 'farcaster-client-hooks';
import React, {
  FC,
  memo,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { QRCode } from 'react-qrcode-logo';

import { ChannelImage } from '~/components/channelsV3/ChannelImage';
import { DefaultButton } from '~/components/forms/buttons/DefaultButton';
import {
  MultipleSelectList,
  MultipleSelectListProps,
} from '~/components/forms/MultipleSelectList';
import { PaidInviteImage } from '~/components/images/PaidInviteImage';
import { LoadingIndicator } from '~/components/loaders/LoadingIndicator';
import { DefaultModalContainer } from '~/components/modals/DefaultModalContainer';
import { DefaultModalContent } from '~/components/modals/DefaultModalContent';
import { DefaultModalHeader } from '~/components/modals/DefaultModalHeader';
import { Modal } from '~/components/modals/Modal';
import { Warp } from '~/components/warps/Warp';
import { WarpsBalanceForPayment } from '~/components/warps/WarpsBalanceForPayment';
import { useAnalytics } from '~/contexts/AnalyticsProvider';
import { useCurrentUser } from '~/hooks/data/useCurrentUser';

type SelectChannelsState = {
  type: 'channels';
  inviteId: string;
  inviteUrl: string;
};

type ShareState = {
  type: 'share';
  inviteUrl: string;
  inviteChannelImages: (string | undefined)[] | undefined;
};

type State =
  | {
      type: 'pay';
    }
  | SelectChannelsState
  | ShareState;

type PaidInviteModalProps = {
  onClose: () => void;
};

const PaidInviteModal: FC<PaidInviteModalProps> = memo(({ onClose }) => {
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    trackEvent(SharedAmpEvent.ViewGiftInvitePage, undefined);
  }, [trackEvent]);

  const [state, setState] = useState<State>({
    type: 'pay',
  });

  const title = useMemo(() => {
    switch (state.type) {
      case 'pay':
        return 'Gift a Farcaster account';
      case 'channels':
        return 'Select channels';
      case 'share':
        return 'Share invite';
    }
  }, [state.type]);

  return (
    <Modal>
      <DefaultModalContainer
        onClose={() => {
          if (state.type === 'pay') {
            onClose();
          }
        }}
      >
        <DefaultModalContent minHeightPx={120}>
          <DefaultModalHeader
            title={title}
            onClose={onClose}
            hideDefaultCloseModalButton={state.type === 'channels'}
          />
          <Suspense
            fallback={
              <div className="flex h-24 w-full items-center justify-center">
                <LoadingIndicator />
              </div>
            }
          >
            {state.type === 'pay' ? (
              <PayForInviteContent setState={setState} />
            ) : state.type === 'channels' ? (
              <SelectChannelsContent state={state} setState={setState} />
            ) : state.type === 'share' ? (
              <ShareContent state={state} setState={setState} />
            ) : null}
          </Suspense>
        </DefaultModalContent>
      </DefaultModalContainer>
    </Modal>
  );
});
PaidInviteModal.displayName = 'PaidInviteModal';

type PayForInviteContentProps = {
  setState: (newState: State) => void;
};

const PayForInviteContent: FC<PayForInviteContentProps> = memo(
  ({ setState }) => {
    const { data: inviteData } = useInviteWithWarpsOfferingWithRefreshOnMount();
    const invalidateInviteWithWarpsOffering =
      useInvalidateInviteWithWarpsOffering();
    const currentUser = useCurrentUser();
    const { flatData: moderatedChannels } =
      useUserChannelsForCategoryWithRefreshOnMount({
        fid: currentUser.fid,
        category: 'moderate',
      });
    const createInvite = useCreateInvite();
    const { trackEvent } = useAnalytics();

    const [isCreatingInvite, setIsCreatingInvite] = React.useState(false);

    const payForInvite = useCallback(async () => {
      setIsCreatingInvite(true);
      const result = await createInvite();
      trackEvent(SharedAmpEvent.ClickCreateGiftInvite, undefined);

      if (moderatedChannels && moderatedChannels.length > 0) {
        setState({
          type: 'channels',
          inviteId: result.inviteId,
          inviteUrl: result.inviteUrl,
        });
      } else {
        setState({
          type: 'share',
          inviteUrl: result.inviteUrl,
          inviteChannelImages: undefined,
        });
      }
    }, [createInvite, moderatedChannels, setState, trackEvent]);

    return (
      <div className="w-full px-4 pb-4">
        <div className="mb-2 text-muted">
          Bring a friend to Farcaster by paying for their signup.
        </div>
        <div className="w-full text-muted">
          Warps are returned if the invite is not claimed.
        </div>
        <div className="flex justify-center py-8">
          <PaidInviteImage />
        </div>
        <div className="mt-4">
          <DefaultButton
            disabled={
              !inviteData || !inviteData?.offering.canPurchaseUsingWarps
            }
            onClick={payForInvite}
            isLoading={isCreatingInvite}
            variant="normal"
            className="flex h-[44px] w-full flex-row items-center !justify-center gap-[6px] !rounded-lg !text-[16px] !font-semibold text-default"
          >
            <span className="font-light">Pay </span>
            <Warp size={18} />
            <span>
              {inviteData.offering.amount}{' '}
              <span className="font-light">to invite</span>
            </span>
          </DefaultButton>
        </div>
        <WarpsBalanceForPayment
          balanceAmount={inviteData.offering.warpsBalance}
          requiredAmount={inviteData.offering.amount}
          refreshBalance={() => {
            invalidateInviteWithWarpsOffering();
          }}
        />
      </div>
    );
  },
);

interface SelectChannelsContentProps {
  state: SelectChannelsState;
  setState: (newState: State) => void;
}

const SelectChannelsContent: FC<SelectChannelsContentProps> = memo(
  ({ state: { inviteId, inviteUrl }, setState }) => {
    const currentUser = useCurrentUser();
    const updatePaidInvite = useUpdatePaidInvite();
    const { trackEvent } = useAnalytics();
    const { flatData, fetchNextPage, isFetchingNextPage } =
      useUserChannelsForCategory({
        fid: currentUser.fid,
        category: 'moderate',
      });

    const [selectedChannels, setSelectedChannels] = useState<ApiChannel[]>([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const multipleSelectProps: MultipleSelectListProps<ApiChannel> =
      useMemo(() => {
        return {
          options: flatData || ([] as ApiChannel[]),
          selectedOptions: selectedChannels,
          fetchNextPage,
          isFetchingNextPage,
          keyExtractor: channelKeyExtractor,
          renderOption: (channel) => {
            return (
              <div className="flex flex-row items-center py-2">
                <ChannelImage
                  channelImageUrl={channel.imageUrl}
                  size="paid-invite-channel-selector"
                />
                <div className="ml-3 font-semibold">/{channel.key}</div>
              </div>
            );
          },
          setSelectedOptions: (options: ApiChannel[]) =>
            setSelectedChannels(options),
        };
      }, [fetchNextPage, flatData, isFetchingNextPage, selectedChannels]);

    const updateAndContinue = useCallback(async () => {
      setIsSubmitting(true);

      const channelKeys = selectedChannels.map((channel) => channel.key);

      await updatePaidInvite({
        inviteId,
        inviteToChannelKeys: channelKeys,
      });
      trackEvent(SharedAmpEvent.AddChannelsToGiftInvite, {});

      setState({
        type: 'share',
        inviteUrl,
        inviteChannelImages: selectedChannels.map(
          (channel) => channel.imageUrl,
        ),
      });

      setIsSubmitting(false);
    }, [
      inviteId,
      inviteUrl,
      selectedChannels,
      setState,
      trackEvent,
      updatePaidInvite,
    ]);

    const skip = useCallback(() => {
      setState({ type: 'share', inviteUrl, inviteChannelImages: undefined });
    }, [inviteUrl, setState]);

    return (
      <div className="w-full px-4 pb-4">
        <div className="mb-2 text-muted">
          Add your friend as a member of channels you moderate.
        </div>
        <div className="mb-4">
          <MultipleSelectList {...multipleSelectProps} height={393} />
        </div>
        <DefaultButton
          disabled={selectedChannels.length === 0}
          onClick={updateAndContinue}
          isLoading={isSubmitting}
          variant="normal"
          className="flex h-[44px] w-full flex-row items-center !justify-center gap-[6px] !rounded-lg !text-[16px] !font-semibold text-default"
        >
          Continue
        </DefaultButton>
        <DefaultButton
          onClick={skip}
          variant="link"
          className="flex h-[44px] w-full flex-row items-center !justify-center gap-[6px] !rounded-lg !text-base !font-normal text-faint"
        >
          Skip
        </DefaultButton>
      </div>
    );
  },
);

interface ShareContentProps {
  state: ShareState;
  setState: (newState: State) => void;
}

const ShareContent: FC<ShareContentProps> = memo(
  ({ state: { inviteUrl, inviteChannelImages } }) => {
    const { trackEvent } = useAnalytics();
    const [linkCopied, setLinkCopied] = React.useState(false);

    const copyCode = useCallback(async () => {
      navigator.clipboard.writeText(inviteUrl);
      trackEvent(SharedAmpEvent.ClickCopyGiftInviteLink, {});
      setLinkCopied(true);
      setTimeout(() => setLinkCopied(false), 3000);
    }, [inviteUrl, trackEvent]);

    return (
      <div className="w-full px-4 pb-4">
        <div className="mb-4 text-muted">
          Have your friend scan the code below, or send them a link.
        </div>
        <div className="mb-4 text-muted">
          {inviteChannelImages && inviteChannelImages.length > 0
            ? 'They will get free access to Warpcast and the channels you chose.'
            : 'They will get free access to Warpcast.'}
        </div>
        <div className="flex flex-row items-center gap-1 text-faint">
          <HistoryIcon size={16} />
          <span>This is a single use code</span>
        </div>
        <div className="mt-5 flex justify-center">
          <QRCode
            bgColor="#ffffff"
            ecLevel="H"
            eyeRadius={0}
            fgColor="#000000"
            quietZone={10}
            size={185}
            value={inviteUrl}
          />
        </div>
        {inviteChannelImages && inviteChannelImages.length > 0 && (
          <div className="mt-6 flex flex-row items-center justify-between rounded-lg border px-4 py-2 text-sm border-default text-faint">
            <div>Channels</div>
            <div className="flex flex-row">
              {inviteChannelImages.slice(0, 10).map((channelImage, index) => (
                <div
                  className="rounded-full border-2 border-app"
                  style={{ marginLeft: index > 0 ? -10 : 0 }}
                >
                  <ChannelImage
                    channelImageUrl={channelImage}
                    size="paid-invite-share"
                  />
                </div>
              ))}
            </div>
          </div>
        )}
        <DefaultButton
          variant={linkCopied ? 'inverted' : 'normal'}
          onClick={copyCode}
          size="md"
          className="mt-4 flex h-[44px] w-full flex-row items-center !justify-center gap-[6px] !rounded-lg !text-[16px] !font-semibold text-default"
        >
          {linkCopied ? <CheckIcon size={20} /> : <CopyIcon size={20} />}
          {linkCopied ? 'Copied!' : 'Copy link'}
        </DefaultButton>
      </div>
    );
  },
);

export { PaidInviteModal };
