import { PersonAddIcon, SignInIcon } from '@primer/octicons-react';
import { useFarcasterApiClient } from 'farcaster-client-hooks';
import {
  authenticatePasskey,
  getStoredPasskeys,
  StoredPasskey,
} from 'farcaster-cryptography';
import { FC, memo, useEffect, useState } from 'react';

import { Avatar } from '~/components/avatar/Avatar';
import { DefaultButton } from '~/components/forms/buttons/DefaultButton';
import { LoginModal } from '~/components/modals/LoginModal';
import { Search } from '~/components/search/Search';
import { DownloadInstructions } from '~/components/sidebar/DownloadInstructions';
import { useAnalytics } from '~/contexts/AnalyticsProvider';
import { useAuth } from '~/contexts/AuthProvider';
import { useFeatureFlag } from '~/contexts/FeatureFlagProvider';
import { useCurrentRoute } from '~/hooks/navigation/useCurrentRoute';
import { useNavigate } from '~/hooks/navigation/useNavigate';
import { useSignAuthTokenOperation } from '~/hooks/signing/useSignAuthTokenOperation';
import { RightSideBar } from '~/layouts/RightSideBar';
import { SupportAndTermsLinks } from '~/layouts/SupportAndTermsLinks';
import { WebAmpEvent } from '~/types';
import { keyStore } from '~/utils/cryptographyUtils';

const UnauthedRightSideBar: FC = memo(() => {
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const { trackEvent } = useAnalytics();
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const { getFeatureFlagValue } = useFeatureFlag();
  const signAuthTokenOperation = useSignAuthTokenOperation();
  const { apiClient } = useFarcasterApiClient();
  const currentRoute = useCurrentRoute();
  const routeFamily = currentRoute?.routeDefinition.family;

  const [storedPasskeys, setStoredPasskeys] = useState<StoredPasskey[]>([]);

  const loginWithPasskey = (passkey: StoredPasskey) => async () => {
    const mnemonic = await authenticatePasskey({
      keyStore,
      credentialId: passkey.credentialId,
    });
    const result = await signAuthTokenOperation({ mnemonic, apiClient });
    await signIn({ authToken: result.token! });
    setTimeout(() => window.location.reload(), 250);
  };

  useEffect(() => {
    getStoredPasskeys({ keyStore }).then((passkeys) =>
      setStoredPasskeys(passkeys),
    );
  }, []);

  return (
    <RightSideBar className="pt-3">
      {routeFamily === 'search' ? <></> : <Search showClearIcon={false} />}
      {!getFeatureFlagValue('login-with-passkeys') && (
        <div className="mt-3 grid w-full grow grid-cols-2 flex-row gap-2">
          <DefaultButton
            className="min-w-0 mdlg:min-w-[110px]"
            onClick={() => {
              trackEvent(WebAmpEvent.ClickedLogin, undefined);
              setIsLoggingIn(true);
            }}
          >
            <div className="hidden mdlg:block">Login</div>
            <div className="mdlg:hidden">
              <SignInIcon />
            </div>
          </DefaultButton>
          <DefaultButton
            className="min-w-0 mdlg:min-w-[110px]"
            onClick={() => {
              trackEvent(WebAmpEvent.ClickedCreateNewAccount, undefined);
              navigate({ to: 'signup', params: {} });
            }}
          >
            <div className="hidden mdlg:block">Create account</div>
            <div className="mdlg:hidden">
              <PersonAddIcon />
            </div>
          </DefaultButton>
        </div>
      )}
      {getFeatureFlagValue('login-with-passkeys') &&
        ((storedPasskeys.length > 0 && (
          <div className="mt-3 grid w-full grow flex-row">
            <h2 className="text-faint">Choose an account</h2>
            <div>
              {storedPasskeys.map((passkey) => {
                return (
                  <div
                    key={passkey.credentialId}
                    onClick={loginWithPasskey(passkey)}
                    className="flex cursor-pointer border-b py-4 border-default last:border-none"
                  >
                    <Avatar
                      user={{
                        displayName: passkey.displayName,
                        fid: passkey.fid,
                        pfp: { url: passkey.pfpUrl, verified: false },
                        profile: { bio: { text: '', mentions: [] } },
                        followerCount: 0,
                        followingCount: 0,
                      }}
                      size="md"
                      className="mr-2 flex-col md:mr-4"
                      disabled={true}
                    />
                    <div className="flex max-w-xs flex-col justify-between truncate break-words py-[2pt] text-base text-default">
                      <div className="flex-row font-semibold">
                        {passkey.displayName}
                      </div>
                      <div className="flex-row text-sm text-faint">
                        @{passkey.username}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="w-full flex-row">
              <DefaultButton
                className="w-full"
                variant="muted"
                onClick={() => {
                  trackEvent(WebAmpEvent.ClickedLogin, undefined);
                  setIsLoggingIn(true);
                }}
              >
                <div className="hidden mdlg:block">Add account</div>
                <div className="mdlg:hidden">
                  <SignInIcon />
                </div>
              </DefaultButton>
            </div>
          </div>
        )) || (
          <>
            <div className="mt-3 w-full flex-row">
              <DefaultButton
                className="w-full"
                onClick={() => {
                  trackEvent(WebAmpEvent.ClickedLogin, undefined);
                  setIsLoggingIn(true);
                }}
              >
                <div className="hidden mdlg:block">Login to Warpcast</div>
                <div className="mdlg:hidden">
                  <SignInIcon />
                </div>
              </DefaultButton>
            </div>
            <DownloadInstructions />
          </>
        ))}
      <SupportAndTermsLinks />
      {isLoggingIn && <LoginModal onClose={() => setIsLoggingIn(false)} />}
    </RightSideBar>
  );
});

UnauthedRightSideBar.displayName = 'UnauthedRightSideBar';

export { UnauthedRightSideBar };
