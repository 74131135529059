import { LinkExternalIcon } from '@primer/octicons-react';
import { useCollection } from 'farcaster-client-hooks';
import React from 'react';

import { Avatar } from '~/components/avatar/Avatar';
import { Image } from '~/components/images/Image';
import { ExternalLink } from '~/components/links/ExternalLink';
import { xsAvatarDiameter } from '~/constants/avatar';
import { useParams } from '~/hooks/navigation/useParams';

const CollectionOwnersPageRightSideBarContent: React.FC = () => {
  const { collectionSlug } = useParams('collectionOwners');

  const { data } = useCollection({
    collectionId: collectionSlug,
  });

  const collection = React.useMemo(() => {
    return data!.result.collection;
  }, [data]);

  return (
    <div className="mt-3 hidden shrink-0 flex-row space-x-3 rounded-lg px-4 py-3 pt-1.5 bg-overlay-light mdlg:flex">
      <div className="grow">
        <div className="text-lg font-semibold text-default">
          {collection.name}
        </div>
        <div
          className="mt-0.5 line-clamp-6 text-sm break-gracefully text-muted"
          title={collection.description}
        >
          {collection.description}
        </div>
        <div className="mt-2 flex flex-row items-center text-sm text-muted">
          {collection.collectionOwners && (
            <div className="relative mr-1 flex w-14 shrink-0 flex-row items-center pt-1">
              {collection.collectionOwners
                .slice(0, 3)
                .map((collectionOwner, index) => (
                  <div
                    key={collectionOwner.fid}
                    className={`absolute`}
                    style={{
                      left: Math.round(index * xsAvatarDiameter * 0.8),
                      zIndex: 3 - index,
                    }}
                  >
                    <Avatar user={collectionOwner} size="xs" />
                  </div>
                ))}
            </div>
          )}
          {collection.farcasterOwnerCount}{' '}
          {collection.farcasterOwnerCount === 1 ? 'owner' : 'owners'} on
          Farcaster
        </div>
      </div>
      <div className="mt-1 flex shrink-0 flex-col items-center space-y-2">
        <Image
          alt={'Collection image'}
          className="h-[68px] w-[68px] rounded-lg object-cover shadow-sm"
          src={collection.imageUrl}
        />
        {collection.mintUrl && (
          <ExternalLink
            href={collection.mintUrl}
            title="Mint"
            className="flex cursor-pointer items-center text-xs text-link hover:underline"
          >
            <LinkExternalIcon size={12} className="mr-1" />
            Mint
          </ExternalLink>
        )}
      </div>
    </div>
  );
};

export { CollectionOwnersPageRightSideBarContent };
