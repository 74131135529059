import { isProd } from '~/constants/env';

const forceProdApi = true;

const useProdApi = isProd || forceProdApi;

const baseApiHost = useProdApi ? 'client.warpcast.com' : 'localhost:8080';

const baseUseHttps = useProdApi;

const baseApiUrl = baseUseHttps
  ? `https://${baseApiHost}`
  : `http://${baseApiHost}`;

const wsUrl =
  isProd || forceProdApi
    ? 'wss://ws.warpcast.com/stream'
    : `ws://${baseApiHost}/stream`;

export { baseApiHost, baseApiUrl, baseUseHttps, wsUrl };
