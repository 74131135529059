import { ApiSyncChannelMessage } from 'farcaster-client-data';
import {
  sleep,
  useFetchLatestSyncChannelMessage,
} from 'farcaster-client-hooks';
import { getKeyTransport } from 'farcaster-cryptography';
import { MutableRefObject, useCallback } from 'react';

import { dataStore, keyStore } from '~/utils/cryptographyUtils';

const defaultPollInterval = 1000;

const usePollForLatestSyncChannelMessage = () => {
  const fetchLatestSyncChannelMessage = useFetchLatestSyncChannelMessage();

  return useCallback(
    async ({
      cancelControllerRef,
      channelId,
      pollInterval = defaultPollInterval,
    }: {
      cancelControllerRef: MutableRefObject<{ cancel: boolean }>;
      channelId: string;
      pollInterval?: number;
    }): Promise<ApiSyncChannelMessage | undefined> => {
      let message: ApiSyncChannelMessage | undefined;

      const transport = await getKeyTransport({ keyStore, dataStore });

      do {
        if (cancelControllerRef.current.cancel) {
          return undefined;
        }

        await sleep(pollInterval);

        try {
          message = await fetchLatestSyncChannelMessage(
            await transport.generateSyncChannelGetParams(channelId),
          );
        } catch {} // Deploys and connectivity issues will interrupt this, we shouldn't break
      } while (!message);

      return message;
    },
    [fetchLatestSyncChannelMessage],
  );
};

export { usePollForLatestSyncChannelMessage };
