<h3 className="text-xl font-semibold">Login with mobile</h3>;

import classNames from 'classnames';
import { FC, memo } from 'react';

import { DefaultButton } from '~/components/forms/buttons/DefaultButton';
import { LinkToSignup } from '~/components/links/LinkToSignUp';
import { LoginQRCode } from '~/lazy/components';

type LoginQRCodeWithInstructionsProps = {
  channelId: string;
  onClose: () => void;
  attemptingLogin: boolean;
  type: 'mobile' | 'web';
};

const LoginQRCodeWithInstructions: FC<LoginQRCodeWithInstructionsProps> = memo(
  ({ channelId, onClose, attemptingLogin, type }) => {
    return (
      <>
        <div className="flex flex-col p-8 sm:items-start">
          <div className="flex w-full flex-row justify-around">
            <div
              className={classNames(
                'relative mt-2 flex justify-around sm:mt-0',
                attemptingLogin && 'animate-pulse',
              )}
            >
              <LoginQRCode channelId={channelId} type={type} />
            </div>
          </div>
          <h3 className="mb-4 text-3xl font-semibold">
            Log in with {type === 'mobile' ? 'Passkey' : 'mobile'}
          </h3>
          <div>
            To log in using Warpcast on your mobile device, open the Camera app{' '}
            and scan the QR code.
          </div>
          <DefaultButton
            className="mt-8 w-full"
            variant="muted"
            onClick={onClose}
          >
            Cancel
          </DefaultButton>
          <LinkToSignup
            title="Sign up"
            className="mt-2 block text-sm font-semibold sm:mt-4"
            onClick={onClose}
          >
            Don't have an account?
          </LinkToSignup>
        </div>
      </>
    );
  },
);

LoginQRCodeWithInstructions.displayName = 'LoginQRCodeWithInstructions';

export { LoginQRCodeWithInstructions };
